<template>
  <div class="m-foad-pdf">
    <a class="button" @click="open">Lire le document</a>
    <div class="wysiwyg" v-html="data.wysiwyg" v-if="data.wysiwyg"></div>
    <div class="m-foad-pdf__popin" v-if="is_open">
      <a class="m-foad-pdf__popin-close" data-icon="close" @click="close"></a>
      <object :data="data.file+'?disposition=inline'" type="application/pdf" width="100%" height="100%" ref="pdf" tabindex="0">
        <p>This browser does not support PDFs. Please download Chrome or Firefox to continue</p>
      </object>
    </div>
  </div>
</template>

<script>
export default {
  name: "foad-pdf",
  props:['data','participant'],
  data(){
    return{
      is_open : false
    }
  },
  methods:{
    open(){
      this.$emit('handleFocus', true)
      this.is_open = true
    },
    close(){
      this.$emit('handleFocus', false)
      this.is_open = false;
      this.$emit('updated', 1)
    }
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-foad-pdf{
  &__popin{
    position: fixed; left: 0; top:0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); z-index: 9999; padding: 6rem;
    &-close{ position: absolute; right: 2rem; top: 2rem; color: #fff; font-size: 2rem; cursor: pointer }
    object{ background: #fff; border-radius: 5px; box-shadow: $box-shadow; max-width: 1600px; margin: auto; display: block }
  }
}
</style>