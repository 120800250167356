<template>
  <div class="m-survey">

	  <loader static="1" v-if="loading"></loader>
	  <form @submit.prevent="submitForm" v-else id="survey">
		  <vue-form-generator tag="div" :schema="form" :model="qa"></vue-form-generator>
		  <div class="bottom-right">
			  <submit :loading="sending" text="Valider" :success="registered" text-success="Validé"></submit>
		  </div>
	  </form>
  </div>
</template>

<script>

import formationParticipantRepository from "@/repositories/formationParticipantRepository";

export default {
  name: "survey",
  props:['formationParticipant'],
  data(){
    return{
      qa:{},
      registered:false,
      loading:true,
      sending:false,
      form: {
        groups:[]
      }
    }
  },
  methods:{
    submitForm(){

      this.sending = true;
      let form = {'answers':[]};

      for (const [key, value] of Object.entries(this.qa)) {
        if( key.substr(0, 1) === 'q' )
          form['answers'].push({question:key.substr(1), answer: value});
        else
          form[key] = value;
      }

	    formationParticipantRepository.sendSurvey(this.formationParticipant.id, form).then(response=>{

        this.registered = true;
        this.sending = false;

        this.$emit('sent');

        }, response=>{

        this.sending = false;
      })
    },
    generateForm(survey){

      let model = {};
      let groups = [];

      survey.forEach(surveyGroup=>{

        groups.push({
          legend: surveyGroup.title,
          styleClasses: "form-group-title"
        });

        let group = {
          styleClasses: ["form-group-background","form-group-columns"],
          fields: []
        };

        surveyGroup.questions.forEach(surveyQuestion=>{

          model['q'+surveyQuestion.id] = false;

          let question = {
            type: "radios",
            model: 'q'+surveyQuestion.id,
            label: surveyQuestion.title,
            required: true,
            values:[],
            styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
          }

          surveyQuestion.answers.forEach(surveyAnswer=>{

            question.values.push({ name: surveyAnswer.title, value: surveyAnswer.id });
          })

          group.fields.push(question);
        })

        groups.push(group)
      })

      model['comment'] = '';

      groups.push({
        styleClasses: ["form-group-background","form-group-columns"],
        fields: [{
          type: "textArea",
          model: "comment",
          rows: 5,
          label: "Autres commentaires",
          styleClasses:["fields-label"]
        }]
      })

      this.qa = model;
      this.form.groups = groups;
    }
  },
  mounted(){

    this.$catchError(false);

	  formationParticipantRepository.getSurvey(this.formationParticipant.id).then(survey=>{

      this.loading = false;

      this.generateForm(survey)

    }, response=>{

      this.loading = false;
    })
  }
}
</script>

<style lang="scss">
@import '../../environment';

.m-survey{
  $c: &;

}
</style>