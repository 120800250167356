<template>
	<div class="m-signature-buy">

		<div class="m-signature-buy__text" v-html="config.option.signature" v-if="config.option.signature"></div>

		<loader v-if="loading===true" class="static"></loader>

		<div class="m-signature-buy__packs" v-if="packs">
			<div class="m-signature-buy__pack" v-for="(pack, index) in packs" :key="index" :class="{'m-signature-buy__pack--popular':pack.popular}">
				<div class="m-signature-buy__total subtitle">{{pack.count}} signatures</div>
				<div class="m-signature-buy__price">
					<b><span>{{formatPrice(pack.total_price)}}</span></b> TTC
				</div>
				<div class="m-signature-buy__unit">{{ formatPrice(pack.unit_price) }} TTC / signature</div>
				<img :src="pack.image" v-if="pack.image" class="m-signature-buy__visual" />
				<form @submit.prevent="buy(pack.id)" id="buy-signatures">
					<submit :loading="loading===pack.id" text="Choisir ce pack"></submit>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

	import signatureRepository from "@/repositories/signatureRepository";
	import orderRepository from "@/repositories/orderRepository";

	export default {
		name: "signature-buy",
		data(){
			return{
				loading: true,
				packs: false
			}
		},
		computed:{
			config(){ return this.$config() }
		},
		methods:{
			buy(id){

				if( this.loading )
					return;

				this.loading = id;

				orderRepository.create({type: 'signature', productId: id}).then(id=>{
					this.$router.push({ name:'order', params:{ id: id }});
				},response=>{
					this.loading = false;
				})
			}
		},
		mounted() {
			signatureRepository.getPack().then(response=>{
				this.packs = response;
				this.loading = false
			})
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-signature-buy{

		text-align: left;

		&__title + *{ margin-top: $space }

		&__text{
			h2{ font-size: $font-xxl; color: $c-primary; font-weight: bold; margin-bottom: $space }
			color: $c-primary; font-size: $font-m;
			p + p{ margin-top: $space-m }
		}

		&__packs{
			padding-top: 5rem;

			@media #{$from-small}{
				display: flex;
				& > * + *{ margin-left: $space }
			}

			@media #{$to-tablet}{
				& > * + *{ margin-top: $space-m }
				& > * + .m-signature-buy__pack--popular{ margin-top: $space-m*2}
			}
		}

		&__pack{
			background : $c-background-lighter; border: solid 5px $c-background-lighter; border-radius: $border-radius;
			padding: $space-s $space-m; position:relative; text-align: center; flex: 1;

				&--popular{
				border-color: $c-green;
				&:before{ display:block; background: $c-green; content: 'Le plus apprécié'; color: white; font-weight: bold;
					padding: 0.5rem; position:absolute; bottom: 100%; left: -5px; right: -5px; border-radius: $border-radius;
				text-transform: uppercase; font-size: $font-m }
			}
		}

		&__price{
			color: $c-primary; font-size: $font-xs; font-weight: bold; margin-top: $space-m;
			b{ font-size: $font-xxl }
			span{ font-size: 3.4rem }
		}

		&__unit{
			font-size: $font-xs; margin-top: 0.5rem;

			& + *{ margin-top: $space-s }
		}

		&__visual{
			position: relative; left: 5rem;
			& + *{ margin-top: $space-m }

			@media #{$to-tablet}{ left:auto; margin-left: auto; margin-right: auto }
		}

		.a-loader.static{ margin: $space-l 0 }
	}
</style>