<template>
  <dropdown class="a-notifications" @open="notify()" v-if="notifications.length||checkup.length">
	  <checkup v-if="checkup.length" :data="checkup" mod="notifs"></checkup>
	  <ul v-if="notifications.length">
      <li v-for="(item,index) in notifications" :key="index" class="a-notifications__item" :class="'a-notifications__item--'+item.type+' a-notifications__item--'+(hasRead(item.id)?'read':'unread')" @click="action(item.type, item.id)">
        <div class="icon">
          <img src="/img/icons/mes-documents.svg" v-if="item.type === 'document' || item.type === 'appendix'" />
          <img src="/img/icons/e-signatures.svg" v-else-if="item.type === 'signature'" />
          <img src="/img/icons/dashboard.svg" v-else-if="item.type === 'news'" />
        </div>
        <div v-if="item.type === 'document' || item.type === 'appendix'">
          {{item.title}}
          <time>{{ formatDate(item.createdAt) }}</time>
        </div>
        <div v-else-if="item.type === 'signature'">
          {{ item.type_contrat }}
          <span v-for="(signature, index) in item.signatures" :key="'sign'+index" class="a-notifications__signature-name">{{ signature.lastname }}</span><br/>
          <small>{{ item.status_lib }}</small>
          <time>{{ formatDate(item.createdAt) }}</time>
        </div>
      </li>
    </ul>
  </dropdown>
</template>

<script>
	import userRepository from "@/repositories/userRepository";

  export default {
		name: "notifications",
		props:['data'],
		data(){
			return{
        notified:false,
        notifications: [],
        read:[],
        notifications_entities:['document','appendix','signature','checkup'],
				checkup: []
			}
		},
		methods:{
      notify(){
        if( !this.notified ){

	        this.notified = true;

	        if( this.checkup.length )
		        this.$emit('updated', this.checkup.length)
	        else
		        this.$emit('updated', 0)

          userRepository.updateNotification();
        }
      },
      hasRead(id){
        return this.read.indexOf(id) !== -1
      },
      action(type,id){

        if( this.read.indexOf(id) === -1 )
          this.read.push(id);

        if(type === 'document' || type === 'appendix')
          this.$popin('preview', {type:type, id:id})
        else if(type ==='signature')
          this.$router.push({ path: '/e-signatures', hash: '#'+id })
      }
		},
    mounted() {
      userRepository.getNotifications({entity:this.notifications_entities}).then(notifications=>{

	      this.notifications = notifications.items.filter(item => item.type !== 'checkup');
	      this.checkup = notifications.items.filter(item => item.type === 'checkup')

	      this.$store.commit('checkup', this.checkup);

        this.$emit('updated', notifications.count)
      });
    }
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';
	.a-notifications{

    text-align: left; min-width: 50rem;
		@media #{$from-tablet}{
			max-width: 60rem;
		}
    >ul{ max-height: 50vh; overflow: auto; -webkit-overflow-scrolling: touch; }
    &.a-dropdown:before{ margin-right: 3rem }

    &__item{
      display: flex; align-items: center;
      .icon{ flex-shrink: 0 }
      .icon + *{ margin-left: $space-s; flex: 1; white-space: normal; }
      & + &{ margin-top: $space-s }
      &--read{ opacity: 0.7 }
    }

    time{ display: block; font-size: $font-xxs; color: $c-main }

    &__signature-name{
      & + &{ margin-left: math.div($space-s,2) }
    }
	}
</style>