export const infiniteScroll = {
	inserted (el, binding) {

		let scrollElement = document.body;
		let ios = document.documentElement.classList.contains('ios')

		let offset= window.platform === 'desktop' ? 200 : 250;
		el.scrollBottom = function (evt) {
			if(binding.modifiers.full && ((!ios && window.innerHeight + window.scrollY >= document.body.offsetHeight - offset) || (ios && scrollElement.scrollTop + scrollElement.clientHeight >= scrollElement.scrollHeight - offset)) || (!binding.modifiers.full && (el.scrollTop + el.clientHeight >= el.scrollHeight)))
				binding.value(evt)
		};

		setTimeout(function () {
			if(binding.modifiers.full && ios)
				scrollElement.addEventListener('scroll', el.scrollBottom);
			else if(binding.modifiers.full)
				window.addEventListener('scroll', el.scrollBottom);
			else
				el.addEventListener('scroll', el.scrollBottom);
		}, 500);
	},
	unbind (el, binding) {
		if(binding.modifiers.full && document.documentElement.classList.contains('ios'))
			document.body.removeEventListener('scroll', el.scrollBottom);
		else if(binding.modifiers.full)
			window.removeEventListener('scroll', el.scrollBottom);
		else
			el.removeEventListener('scroll', el.scrollBottom);
	}
};

export const clickOutside = {
	bind: function(el, binding, vNode) {
		// Provided expression must evaluate to a function.
		if (typeof binding.value !== 'function') {
			const compName = vNode.context.name
			let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
			if (compName) { warn += `Found in component '${compName}'` }

			console.warn(warn)
		}
		// Define Handler and cache it on the element
		const bubble = binding.modifiers.bubble
		const handler = (e) => {
			if (bubble || (!el.contains(e.target) && el !== e.target)) {
				binding.value(e)
			}
		}
		el.__vueClickOutside__ = handler

		// add Event Listeners
		document.addEventListener('click', handler)
	},

	unbind: function(el, binding) {
		// Remove Event Listeners
		document.removeEventListener('click', el.__vueClickOutside__)
		el.__vueClickOutside__ = null

	}
};