<template>
	<div id="app" v-if="loaded && $route.name !== 'wait'" :class="['page page--'+($route.name?$route.name:$route.params.page)+' user--'+user.type, {'accessible-version': user.dashboard && user.dashboard.isAccessible}]">

		<header-component v-if="requireUser"></header-component>
		<navigation v-if="requireUser && $route.name !== 'buy' && $route.name !=='foad'"></navigation>

		<div class="view-container">
			<transition name="fade">
				<router-view :key="$route.fullPath"></router-view>
			</transition>
		</div>

		<footer-component v-if="requireUser &&  $route.name !=='foad'"></footer-component>

		<popin v-if="alert" :content="alert" type="alert"></popin>
		<popin :content="popin" type="popin" v-if="popin" v-for="popin in popins"></popin>
		<popin v-if="error" :content="error" type="error"></popin>

		<progress-bar v-if="backgroundTask.length && $route.name!=='login'" :tasks="backgroundTask"></progress-bar>

		<tour v-if="user"></tour>
	</div>
	<div id="app" v-else>
		<maintenance v-if="$route.name === 'maintenance'" class="maintenance">Maintenance</maintenance>
		<loader v-else :text="$route.name==='wait'?'Merci de patienter':'Chargement en cours...'"></loader>
		<popin v-if="error" :content="error" type="error"></popin>
	</div>
</template>

<script>

import userRepository from '@/repositories/userRepository';
import configRepository from '@/repositories/configRepository';
import termRepository from '@/repositories/termRepository';
import appendixRepository from '@/repositories/appendixRepository';

import Vue from 'vue';
import cache from "@/services/cache";
import hash from 'object-hash';

export default {
	name: 'app',
	methods:{
		resize(){
			document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
		},
		getCookie(){
			console.log('piou')
			let name = "firstmultico=";
			let allCookieArray = document.cookie.split(';');
			for(let i=0; i<allCookieArray.length; i++)
			{
				let temp = allCookieArray[i].trim();
				if (temp.indexOf(name)===0)
					return temp.substring(name.length,temp.length);
			}
			return "";
		},
		redirect(){

			if( this.user ){

				if(this.$route.name === 'document' ){

					let id = this.$route.params.id;

					this.$router.push({ name: 'documents', params:{section:'informations-juridiques'} })
					this.$popin('preview', {type:'document', id:id})
				}

        if( this.user.changePassword ){

          this.$popin('migrate', this.user.contact, false)

        }
				else if( this.user.canCreateAccount ){

					if(this.user.type === 'company') {

						const urlParams = new URLSearchParams(window.location.search);

						let daysFromMultico = this.daysFromMultico();

						if (daysFromMultico > 60 && !urlParams.has('migrate'))
							this.$router.push({name: 'migrate'})
						else if(daysFromMultico >= 7 && daysFromMultico < 60 && this.firstLoad)
							this.$popin('alertMigrate', this.user)
						else if(daysFromMultico < 7 && daysFromMultico >= 0 && !this.getCookie()){
							let date = new Date(process.env.VUE_APP_MULTICO);
							date.setTime(date.getTime()+(7*24*60*60*1000));
							document.cookie = "firstmultico=true; expires=" + date.toGMTString();
							this.$popin('alertMigrate', this.user)
						}

					}
					else if(this.firstLoad) {

						let daysFromMultico = this.daysFromMultico();

						if(daysFromMultico >= 60  && this.legacy)
							this.$router.push({name: 'migrate'})
						else if(daysFromMultico >= 7 && daysFromMultico < 60 && this.legacy)
							this.$popin('alertMigrate', this.user)
						else if( daysFromMultico  >= 0 && daysFromMultico < 60 ){
							this.$popin('update-profile')
						}
					}
				}
			}
		}
	},
	data(){
		return {
			reload : false,
			accessible: true,
			firstLoad: true
		}
	},
	computed:{
		legacy(){
			return this.$store.getters.legacy()
		},
		popins(){
			return this.$store.getters.popin()
		},
		requireUser(){
			return ['login','register','webinar','migrate','auto-login'].indexOf(this.$route.name)===-1
		},
		error(){
			return this.$store.getters.error()
		},
		alert(){
			return this.$store.getters.alert()
		},
		user(){
			return this.$user()
		},
		backgroundTask(){
			return this.$store.getters.backgroundTask()
		},
		membership(){
			return this.$store.getters.membership()
		},
		renew(){
			return this.membership && (this.membership.snpi.total_amount || this.membership.vhs.total_amount || this.membership.asseris.total_amount || this.membership.caci.total_amount)
		},
		config(){
			return this.$config()
		},
		loaded(){
			return this.config && (!this.requireUser||this.user.roles)
		},
		preventAction(){
			return ['login','order','webinar'].indexOf(this.$route.name)>-1 || this.$route.params.page === 'assurances'
		}
	},
	watch:{
		config(oldConfig, newConfig){
			if(oldConfig && newConfig && hash(oldConfig) !== hash(newConfig) ){
				console.log('Updating...')
				this.$update();
			}
		},
		membership(data){
			if( !this.preventAction && this.renew )
				this.$popin('renew', data, true, 1);
		},
		$route(to, from){
			this.firstLoad = (to.name === 'home' && from.name === 'login')
			this.redirect()
		},
		loaded(){
			if( !this.preventAction && this.membership && this.firstLoad){

				if(this.firstLoad){

					if(this.user.pendingOrders.length > 0){
						this.$popin('pending-orders',false, true,0);
						return;
					}
				}

				if( this.renew )
					this.$popin('renew', this.membership);
				else if( this.hasRole(this.user,'company') && this.membership.rcp.enabled && !this.user.company.sales )
					this.$popin('sales', this.user.company.id, false);
			}
		}
	},
	mounted(){

		if( this.$route.name === 'wait' )
			return;

		window.addEventListener('resize', this.resize);

		this.resize();

		this.$loadGoogleApi(['places']);
		this.$update(cache.clear);

		configRepository.get().then(response=>{

			if( document.location.pathname === '/maintenance'){

				document.location.href = '/';
				return;
			}

			if( this.$route.name === 'auto-login' )
				return;

			this.$heartbeat();

			userRepository.get().then(([user,validUser])=>{

				if (Vue.rollbar)
					Vue.rollbar.configure({payload: {person: {id: user.id}}});

				window.BugHerdConfig.metadata = {user_id: user.id, type:user.type};

				if(!validUser)
					return;

				termRepository.get();

				if( this.$route.name === 'migrate' )
					return;

				appendixRepository.sync(user, true);
				this.redirect();

			}, response=>{});
		});
	}
}
</script>