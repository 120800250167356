import Vue from "vue";
import cache from "@/services/cache";
import store from "@/services/store";

export default {

	get(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('appendix/' + id).then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	download(id) {

		let popup = false;

		if( document.documentElement.classList.contains('safari') )
			popup = window.open('/wait', '_blank');

		return new Promise((resolve, reject) => {

			Vue.http.get('appendix/'+ id + '/download').then(response => {

				if( popup )
					popup.location.href = response.body.response.url;
				else
					window.open(response.body.response.url)

				resolve();

			}, response=>{

				reject(response);
			});
		});
	},

	list(params) {

		if( 'search' in params && params.search.length )
			return cache.list('appendix', params, 0);
		else
			return cache.list('appendix', params);
	},

	sync(user, onlyNew) {

		return new Promise((resolve, reject) => {

			if( user && (!user.type || user.registration) ){

				resolve()
				return;
			}

			store.commit('backgroundTask', {type:'appendix', state:true});

			Vue.http.post('appendix', {new:onlyNew}).then(response=>{

				store.commit('backgroundTask', {type:'appendix', state:false});
				resolve();

			},response=>{

				store.commit('backgroundTask', {type:'appendix', state:false});
				reject();
			})
		});
	},
};
