<template>
	<div class="o-user-preferences block">
		<h2 class="title" v-if="interface.title">{{ interface.title }}</h2>
		<div class="equal-padding">
			<div class="o-user-preferences__line">
				<div class="o-user-preferences__label primary">Recevoir les notifications sur mon espace adhérent</div>
				<div class="toggle" @click="toggleNotifications()"  :class="{'toggle--active': user.dashboard.hasNotification}">
					<div title="non" class="toggle-item"></div>
				</div>
			</div>
			<div class="o-user-preferences__line">
				<div class="o-user-preferences__label primary">Activer le mode constrasté</div>
				<div class="toggle" @click="toggleAccessibilty()"  :class="{'toggle--active': user.dashboard.isAccessible}">
					<div title="non" class="toggle-item"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import userRepository from "@/repositories/userRepository";

	export default {
		name: "user-preferences",
		props:['data','interface'],
		computed:{
			user(){
				return this.$user()
			}
		}, 
		methods:{
			toggleNotifications(){
				userRepository.update(this.user.id, {hasNotification:!this.user.dashboard.hasNotification}).then(response=>{
					console.log(response)
					this.user.dashboard = response.body.response.dashboard
				})
			},
			toggleAccessibilty(){
				userRepository.update(this.user.id, {isAccessible:!this.user.dashboard.isAccessible}).then(response=>{
					this.user.dashboard = response.body.response.dashboard
				})
			}
		}
	}
</script>

<style lang="scss">
	.o-user-preferences{
		@import '../../environment';

		&__line{
			display: flex; align-items: center;

			& + &{ margin-top: $space }
		}
		&__label{ flex: 1; padding-right: 2rem }
	}
</style>