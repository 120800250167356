<template>
	<main class="v-news">
		<component :is="item.component" :interface="item.data" v-for="(item, indexComponent) in components" :key="'component'+indexComponent" v-if="pages && components"/>
		<component :is="component.type" :data="component.data" v-for="(component, index) in content" :key="index" v-if="content"/>
		<loader v-if="loading"></loader>
		<not-found v-if="error"></not-found>
	</main>
</template>

<script>
import newsRepository from '@/repositories/newsRepository';
import Interface from '@/interface.yml';

export default {
	data() {
		return {
			pages: Interface.pages,
			config: false,
			content: false,
			loading: true,
			components: false,
			error: false
		}
	},
	computed:{
		root(){
			return Vue.http.options.root
		}
	},
	mounted(){

		newsRepository.get(this.$route.params.slug).then(response => {
				if(response.layout)
					this.content = response.layout;
				else
					this.error = true;

					this.loading = false;
				},
				response=>{
					this.error = true;
					this.loading = false;
				})
	}
}
</script>

<style lang="scss">
@import '../environment';

.v-news{
	min-height: 40rem; position: relative;

	@media #{$to-tablet}{ padding-left: $space-m; padding-right: $space-m }
}
</style>