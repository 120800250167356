<template>
	<section class="o-grid grid" :class="['o-grid--' + interface.type, 'grid--' + (interface.sidebar || interface.partial  ? 'sidebar' : 'no-sidebar'), interface.type === 'main' ? 'container' : '']" v-if="user">
		<div class="o-grid__content content" v-if="interface.content">
			<component :is="item.component" :interface="item.data" :data="data" v-for="(item, index) in interface.content" :key="index" v-if="checkRole(user, item)" class="block" />
		</div>
		<div class="o-grid__aside aside" :class="interface.style ? 'o-grid__aside--' + interface.style : ''" v-if="interface.sidebar">
			<component :is="item.component" :interface="item.data" :data="data" v-for="(item, index) in interface.sidebar" :key="index" v-if="checkRole(user, item)"/>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'grid',
		props:['interface','data'],
		computed:{
			user(){ return this.$user() }
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-grid{
		$c: &;

		& + * {
			margin-top: $space;
			@media #{$to-tablet}{ margin-top: $space-m }
		}
		
		& > * { text-align: left; }

		&__aside{
			position: relative;
		}

		&--aside-left{
			@media #{$from-small}{
				#{$c}__content{ width: 40% }
				#{$c}__aside{ width: 60% }
				& > * + *{ margin-left: $space }
			}

		}
		&--main > #{$c}__aside{
			&--home{
				padding: $space-m $space; background: $c-background-darker; margin-bottom: -$space-ll;
				@media #{$to-tablet}{ padding: $space-m; margin-bottom: 0 }
			}
		}

		.page--formations &--main, .page--home  &--main{
			@media #{$to-tablet}{
				display: flex; flex-direction: column-reverse;

			}
		}
		.page--formation &__aside{
			display: flex; flex-direction: column;
		}
		.page--formations &--main{
			#{$c}__aside{ margin-top: 0 }
		}
	}
</style>
