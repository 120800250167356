<template>
	<div class="o-wysiwyg container" :class="[{'o-wysiwyg--background':data.background}, 'o-wysiwyg--'+data.mod]">
		<div class="container">
			<h3 class="title-big" v-if="data.title">{{data.title}}</h3>
			<runtime-text :data="data.wysiwyg"></runtime-text>
		</div>
	</div>
</template>

<script>
	export default {
		name: "wysiwyg",
		props:['data']
	}
</script>

<style lang="scss">
	@import '../../environment';

	.o-wysiwyg{
		background: white; padding: $space-ll 0; color: $c-primary; border-radius: 3px;

		.v-page &:first-child, .o-submenu+&{ margin-top: $space-l }

		&--left{ text-align: left }
		&--center{ text-align: center }
		&--right{ text-align: right }

		&--background{ background: $c-background; padding: 0; margin-top: $space-xl; margin-bottom: $space-xl; }
		.container{ max-width: 96rem }
		a{ cursor: pointer }
	}

</style>