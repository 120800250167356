<template>
	<div class="o-news-list">
		
		<h2 class="o-news-list__title title">{{ interface.title }}</h2>
		<div class="o-news-list__scrollbox">
			<div class="o-news-list__pushes" v-infinite-scroll="loadMore">
				<news v-for="(item, index) in news" :key="item.id" :data="item"></news>
			</div>
		</div>
	</div>
</template>

<script>

	import newsRepository from '@/repositories/newsRepository';

	export default {
		name: 'news-list',
		props:['data', 'interface'],
		data(){
			return{
				params:{
					limit : 20,
					offset: 0
				},
				count: -1,
				news: []
			}
		},
		methods:{
			loadMore(){

				if(this.news.length >= this.count || !this.news.length)
					return false;

				this.query(this.params.offset + this.params.limit)

			},
			query(offset){

				this.params.offset = offset;

				newsRepository.find(this.params).then(response => {
					this.count = response.count;
					this.news = response.items;
				});

			}
		},
		mounted(){
			this.query(0);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-news-list{
		$c: &;

		@media #{$from-small}{
			position: absolute; left: $space; right: $space - 1rem; bottom: $space; top: $space;
			flex-direction: column; display: flex; overflow: hidden;
		}

		&__scrollbox {
			overflow: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}

		&__pushes {
			padding-right: 1rem;

			@media #{$to-tablet}{
				max-width: 100%;
				overflow-x: auto;
				-webkit-overflow-scrolling: touch;
				display: flex;
				& > * {
					flex: 1 0 calc(80% - #{$space-s});

					& + * {
						margin-left: $space-m
					}
				}
			}
		}
	}
</style>
