<template>

	<div class="m-news" :class="{'m-news--featured': data.featured}">
		<div class="m-news__featured" v-if="data.featured">A la une</div>
		<div class="m-news__thumbnail" v-if="data.thumbnail">
      <img :src="data.thumbnail+'?w=356&h=125'"/>
		</div>
		<div class="m-news__content">
			<div class="m-news__sup" v-if="data.label">{{ data.label }}</div>
			<h2 class="title title--small">{{ data.title }}</h2>
		</div>
    <a class="m-news__link" :href="data.link" target="_blank" rel="noopener" v-if="data.linkType === 'external'"></a>
    <a @click="preview(data.linkType, data.link)" class="m-news__link" v-else-if="data.linkType === 'document'"></a>
    <router-link :to="'/edito/'+data.link" class="m-news__link" v-else-if="data.linkType === 'page'"></router-link>
    <router-link :to="'/news/'+data.slug" class="m-news__link" v-else-if="data.linkType === 'article'"></router-link>
	</div>
</template>

<script>

	import documentRepository from '@/repositories/documentRepository';
	import userRepository from '@/repositories/userRepository';

	export default {
		name: 'news',
		props:['data'],
		methods:{
			preview(type, id){
        this.$popin('preview', {type:type, id:id});
			},
			getProgress(){
				return this.data.quota < this.data.completed ? 100 : (this.data.completed/this.data.quota)*100
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-news{
		$c: &;

		padding: $space-m 0; position: relative;

		@media #{$from-small}{
			border-bottom: solid 1px $c-primary-border;
		}

    &:first-of-type.m-news--featured{ margin-top: 2rem }

		&--featured{
			background: white; padding: $space-s; border-radius: $border-radius; border-bottom: none; box-shadow: $subtle-box-shadow;
			& + &{
        margin-top: 2px;
        .m-news--featured{ display: none }
      }
		}

		&__featured{ color: $c-secondary; font-size: $font-xs; font-weight: bold; margin-bottom: $space-xs; text-transform: uppercase }

		&__thumbnail{
			border-radius: $border-radius; overflow: hidden; padding-top: 35%; position: relative;
			img{ position: overlay(); object-fit: cover; height: 100%; width: 100% }
      a{ cursor: pointer }
			& + *{ margin-top: $space-s }
		}

		&__sup{
			color: $c-primary; font-size: $font-xs; font-weight: bold;
			& + *{ margin-top: $space-s }
		}

		&__link{ position: overlay(); cursor: pointer }

		&:last-of-type{
			border-bottom: 0; padding-bottom: 0 ;
		}

		&__content{ position:relative }

		&__pin{ position: absolute; top : -2px; right: 0; color: $c-primary; z-index: 1 }
	}
</style>
