import Vue from "vue";
import cache from "@/services/cache";

export default {

	getStaff() {
		return cache.list('company/contact');
	},

	get(params) {
		return new Promise((resolve, reject) => {
			Vue.http.get('company', {params:params}).then(response=>{
				resolve(response.body.response);
			}, reject)
		});
	},

	update(id, data) {

		return Vue.http.post('company/'+id, data)
	},

	create(data) {

		return Vue.http.post('company', data)
	},

	uploadFee(data) {

		return Vue.http.post('company/fee', data)
	},

	downloadFee(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('company/'+id+'/fee').then(response => {

				document.location.href = response.body.response.url+'?disposition=attachment';

				resolve();

			}, reject);
		});
	},

	associate(id, data) {

		return Vue.http.post('company/contact/'+id, data)
	},

	getRepresentatives() {
		return new Promise((resolve, reject) => {
			Vue.http.get('company/representatives').then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	getSiren(siren) {
		return new Promise((resolve, reject) => {
			Vue.http.get('company/search',{params:{key: 'siren',value: siren}}).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	// checkup() {
	// 	return new Promise((resolve, reject) => {
	// 		Vue.http.get('company/checkup').then(response => {
	// 			resolve(response.body.response)
	// 		}, reject)
	// 	});
	// }
};
