<template>
	<div class="o-trainings-list">
		<div class="block">
			<h1 class="title">{{interface.title}}</h1>
		</div>
		<div v-if="count === 0" class="o-trainings-list__empty">
			<p class="title-big">Vous n'avez aucune formation souscrite</p>
			<p>Souscrivez à des formations pour les voir apparaître dans la liste.</p>
			<router-link to="/formations" class="button button--secondary">Réserver des formations</router-link>
		</div>
		<div v-else v-infinite-scroll.full="loadMore" class="o-trainings-list__trainings o-trainings-list__trainings--my-trainings">
			<trainingItem :data="formation" type="subscribed" class="o-trainings-list__training" v-for="(formation,index) in formations" :key="formation.id"></trainingItem>
			<loader class="action" v-if="loading"></loader>
		</div>
	</div>
</template>

<script>
	import formationCourseRepository from '@/repositories/formationCourseRepository';

	export default {
		name: "my-trainings",
		props:['data','interface'],
		data(){
			return{
				loading: false,
				formations: [],
				count: -1,
				params:{
					limit : 20,
					offset: 0,
				}
			}
		},
		computed:{
			user(){
				return this.$user()
			}
		},
		methods:{
			searchFormations(){
				this.query(0);
			},
			openPopin(id){
				this.$popin('training-external',id)
			},
			loadMore(){

				if( this.formations.length >= this.count || !this.formations.length )
					return false;

				this.query(this.params.offset + this.params.limit)
			},
			query(offset){

				if( this.loading === true )
					return;

				this.params.offset = offset;

				let self = this;
				this.loading = true;

        formationCourseRepository.getSubscribed(this.params).then(response => {

					self.count = response.count;
					self.formations = self.formations.concat(response.items);
					self.loading = false;
				}) .catch(e => {
					self.loading = false;
				});
			}
		},
		mounted(){

			this.searchFormations();
		}
	}
</script>