<template>
	<div class="m-links" v-if="user">
		<div class="m-links__item m-links__item--play" data-icon="play" @click="resetTour()" v-if="tour">
			Didacticiel
		</div>
		<router-link to="/request" class="m-links__item m-links__item--contact" data-icon="contact">
			Contact
		</router-link>
		<div class="m-links__item" data-icon="notification" :class="{'m-links__item--disabled':!notifications_count}">
			Notifications
			<div class="notifications" v-if="notifications_count">{{ notifications_count }}</div>
			<notifications @updated="setNotificationsCount"></notifications>
		</div>
		<div class="m-links__item m-links__item--profile">
			<img :src="'/img/icons/profil.svg'" alt="Profil" />
			Mon compte
			<dropdown class="m-links__profil-dropdown desktop" v-slot="scope">
				<user-summary></user-summary>
			</dropdown>
		</div>

	</div>
</template>

<script>

	export default {
		name: 'links',
    data(){
      return{
        notifications_count:0,
      }
    },
    computed:{
      user(){
        return this.$user()
      },
      tour(){
        return this.$store.getters.tour()
      }
    },
    methods:{
			resetTour(){
				this.$resetTour();
			},
	    setNotificationsCount(count){
				this.notifications_count = count
			},
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-links{
		display: flex; justify-content: flex-end; align-content: center;

		&__item{
			display: block; font-size: $font-xs; position: relative; cursor: pointer; margin: 0 $space-s;
      &--disabled{ pointer-events: none; opacity: 0.5 }
			&:before{
				font-size: $space; margin-top: 0; display: block; margin-bottom: 0.5rem;
			}
			img{ height: $space; width: auto; margin-bottom: 0.5rem; margin-left: auto; margin-right: auto;
			}
		}

		&--play{
			margin-right: 1.5rem; margin-left: $space;
			&:before{ font-size: 2.5rem; margin-top: 0.25rem; margin-bottom: 0.75rem }
			&:after{ content: ''; position: absolute; top: 0; width: 3rem; height: 3rem; border: white 1px solid; border-radius: 50%; left: 50%; margin-left: -1.6rem }
		}

		&__profil-dropdown{
			@media #{$from-small}{
				width: 36rem;
			}
			&.a-dropdown:before{ margin-right: 1.5rem }
		}
	}
</style>
