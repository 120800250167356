<template>
	<div class="a-caci-company" :class="{'a-caci-company--edit': edit, 'a-caci-company--create': !edit}">
		<form @submit.prevent="validateCompany()" id="caci-company">
			<div class="a-caci-company__background" v-if="address.id && !edit">
				<h4 class="subtitle">{{ address.company.brand }}</h4>
				<p>{{ address.company.street }}</p>
				<p>{{ address.company.zip }} {{ address.company.city }}</p>
				<hr>
				<p>Votre email professionnel : <b>{{ address.email }}</b></p>
				<p v-if="address.phone">Votre portable pro : <b>{{ address.phone }}</b></p>
				<p v-if="address.issuedAt">Date de délivrance de l'attestation d'habilitation: <b>{{ formatDate(address.issuedAt) }}</b></p>
				<a data-icon="pencil" class="a-caci-company__edit" @click="edit=true"></a>
			</div>
			<div class="a-caci-company__background" v-else-if="added && !validated">
				<h4 class="subtitle">Informations relatives au professionnel avec lequel vous êtes sous contrat</h4>
				<div >
					<p>Raison sociale : <b>{{ address.company.name }}</b></p>
					<p>Enseigne : <b>{{ address.company.brand }}</b></p>
					<p>Adresse : <b>{{ address.company.street + ' - ' + address.company.zip + ' ' + address.company.city }}</b></p>
					<p v-if="address.company.legalRepresentatives">Réprésentant légal :
						<b v-for="(legalRepresentative, index) in address.company.legalRepresentatives">{{ legalRepresentative.firstname + ' ' + legalRepresentative.lastname }}</b>
					</p>
					<p v-if="address.company.businessCard">Carte professionnelle : <b>N°{{ address.company.businessCard.number }} delivrée le {{ formatDigitDate(address.company.businessCard.issuedAt) }} <span v-if="address.company.businessCard.cci">{{address.company.businessCard.cci}}</span></b></p>
				</div>

				<h4 class="subtitle">Vos coordonées professionnelles</h4>
				<div>
					<p>Email : <b>{{ address.email }}</b></p>
					<p v-if="address.phone">Portable : <b>{{ address.phone }}</b></p>
				</div>
				<div class="bottom-right-inline" :class="{'margintop': !edit}">
					<button class="button button--white" v-if="!validated" @click.prevent="added = false">Modifier</button>
					<submit :loading="loading" text="Confirmer" :success="validated" text-success="Validée" :error="error" :text-error="error"></submit>
				</div>
			</div>
			<div v-else>

				<h4 class="subtitle" v-if="edit">{{ address.company.name }}</h4>

				<vue-form-generator tag="div" :schema="form" :model="address" :options="formOptions" ref="form"></vue-form-generator>

				<div class="bottom-right" v-if="address.company">
					<button class="button button--white" v-if="edit" @click.prevent="edit=false">Annuler</button>
					<submit :loading="loading" text="Valider cette agence"></submit>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import contactRepository from "@/repositories/contactRepository";
import userRepository from "../../repositories/userRepository";
import appendixRepository from "../../repositories/appendixRepository";

export default {
	name: "caci-company",
	props:['address'],
	data(){

		let self = this;

		return{
			validated: false,
			edit: false,
			added: false,
			loading: false,
			error: false,
			formOptions: {
				validateAfterLoad: false,
				validateAfterChanged: true
			},
			form:{
				groups:[
					{
						styleClasses: ["form-group-background","form-group-columns"],
						fields:[
							{
								type: "siren-custom",
								model: "company",
								styleClasses: "fields-label",
								label: "Numéro SIREN",
								placeholder:'305442501',
								required: true,
								visible(){ return !self.edit }
							},
							{
								type: "input",
								inputType: "email",
								model: "email",
								styleClasses: ["fields-label","field--half","margintop"],
								label: "Email professionnel",
								maxlength: 60,
								placeholder:'jean@dupond.fr',
								required: true,
								visible(){ return self.address.company }
							},
							{
								type: "tel-custom",
								model: "phone",
								styleClasses: ["fields-label","field--half"],
								label: "Portable profesionnel",
								placeholder:'0600000000',
								visible(){ return self.address.company }
							},
							{
								type: "radios",
								model: "isActive",
								styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
								label: "Je travaille toujours pour cette agence",
								required: true,
								visible(){ return self.edit },
								values:[
									{ name: "Oui", value: true },
									{ name: "Non", value: false }
								]
							},
							{
								type: "calendar-custom",
								inputName: "issuedAt",
								model: "issuedAt",
								styleClasses: ["fields-label","field--half","field--bottom"],
								label: "Date de délivrance de l'attestation d'habilitation",
								min: '1920-01-01',
								max: this.now(),
								visible(){ return self.address.isActive && self.address.company },
							}
						]
					}
				]

			}
		}
	},
	computed:{
		user(){ return this.$user() }
	},
	methods:{
		validateCompany(){

			if( !this.added && !this.edit ){

				this.added = true;
			}
			else{

				this.loading=true;

				let address = JSON.parse(JSON.stringify(this.address));

				if( this.edit ){

					contactRepository.updateAddress(address.id, address).then(response => {
								userRepository.get();
								this.loading = false;
								this.edit = false;
								this.validated= true;
							},
							response => {
								this.loading = false;
							})
				}
				else{
					address.company = address.company.id;

					contactRepository.company(address).then(address => {
								userRepository.get();
								this.address.id = address.id;
								this.loading = false;
								this.validated= true;
								appendixRepository.sync(this.user, true).then(response=>{
									this.$update();
								})
							},
							response => {
								this.loading = false;
							})
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.a-caci-company{
	position: relative; margin-top: $space-m; background: $c-background; padding: $space-m; border-radius: $border-radius;
	& + &{ margin-top: $space-s }
	&__background{
		color: $c-primary;
		p + p{ margin-top: 0.5rem }
	}
	&__main{
		position: absolute; right: $space*2; top: $space; color: $c-primary;
	}
	&__edit{
		position: absolute; right: $space; top: $space; color: $c-primary; cursor: pointer;
	}
	div+h4{ margin-top: 2rem }
	.vue-form-generator .form-group-background{ padding: 0; background: none }
	&--create .radio-list-inputs:last-of-type{ margin-bottom: 4rem }
	&--edit .bottom-right{ margin-top: 0 }

	@media #{$from-small}{
		.form-group.margintop{ margin-top: $space-m }
	}
}

</style>