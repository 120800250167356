<template>
	<nav class="o-navigation" v-if="user && navigation && config && config.menu">
		<user-summary class="mobile"></user-summary>
		<div class="container">
			<ul class="o-navigation__list">
				<li v-for="(item, index) in navigation" v-if="item.type !== 'hidden' && checkRole(user, item)" :class="{'maintenance': inMaintenance(config, index) }">
					<router-link :to="{ name: item.route, params:{ section: item.taxonomy, title: item.title }}" class="o-navigation__item" :class="{'router-active-parent': active === item }" v-if="item.taxonomy">
						<span class="o-navigation__icon" v-if="item.icon">
							<img :src="'/img/icons/'+item.icon+'.svg'" :alt="item.title" />
						</span>
						<span class="title title--small">{{ item.title }}</span>
					</router-link>

					<router-link :to="{ name: item.route, params: { slug: config.menu[item.menu][0].link }}" class="o-navigation__item" :class="{'router-active-parent': active === item }" v-else-if="item.menu">
						<span class="o-navigation__icon" v-if="item.icon">
							<img :src="'/img/icons/'+item.icon+'.svg'" :alt="item.title" />
						</span>
						<span class="title title--small">{{ item.title }}</span>
					</router-link>

					<router-link :to="item.url" class="o-navigation__item" :class="{'router-active-parent': active === item }" v-else>
						<span class="o-navigation__icon" v-if="item.icon">
							<img :src="'/img/icons/'+item.icon+'.svg'" :alt="item.title" />
						</span>
						<span class="title title--small">{{ item.title }}</span>
					</router-link>
				</li>
				<li class="mobile">
					<router-link class="o-navigation__item" to="/request">
						<span class="o-navigation__icon o-navigation__contact" data-icon="contact">
						</span>
						<span class="title title--small">Contact</span>
					</router-link>
				</li>
			</ul>
		</div>
	</nav>
</template>

<script>
	export default {
		name: 'navigation',
		data(){
			return{
				showDropdown: false
			}
		},
		computed:{
			user(){ return this.$user() },
			navigation(){ return this.$store.getters.navigation() },
			active(){
				if(this.$route.path === '/')
					return this.navigation.home
				else if(this.$route.name) {
					if(this.$route.name === 'formation')
						return this.navigation['formations']
					else if(this.$route.name.substr(0, 8) === 'document')
						return this.navigation['documents']
					else if(this.$route.name.substr(0, 7) === 'experts')
						return this.navigation['experts']
				}

				return this.$route.params.subpage ? this.navigation[this.$route.params.page] : this.navigation[this.$route.path.replace('/','')]
			},
			config(){
				return this.$config()
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-navigation{
		background: white; position:relative;

		@media #{$to-tablet}{
			position:fixed; background: white; left: 0; top: 8rem; z-index: 6; transition: allow(transform 0.4s $ease-in-out-cubic); transform: translateX(100%);
			width: 100%; bottom: 0; overflow: hidden; overflow-y: scroll; -webkit-overflow-scrolling: touch;

			.o-header--banner + &{ top: #{8rem + $banner} }

			& > *{ z-index: 1 }

			.burger-is-open &{ transform: none }
		}

		.page--order &{ display: none }
		&__list{

			@media #{$to-tablet}{ padding: $space-s 0; }

			.title--small{ display: inline-block }
			& > *{
				flex: 1; cursor: pointer;
				&.maintenance{
					pointer-events: none; position: relative;
					&:after{ content: ''; position: overlay(); background: rgba(255,255,255,0.5); z-index: 1 }
				}
			}

			@media #{$from-small}{ display: flex; }
		}

		&__active{
			display: flex; align-items: center; padding: $space-m $space-s;
			&:after{ margin-left: auto }
		}

		&__icon{
			height: 5rem; display: block;

			@media #{$from-small}{ margin-bottom: $space-s }
			@media #{$to-tablet}{ height: 4.5rem; margin-right: $space-s }

			img{ height: 100%; width: auto; margin: 0 auto }
		}

		&__item{
			padding: $space-m*2 $space-s $space-m; display: block; height: 100%; transition: allow(background);

			@media #{$from-small}{
				.no-touch &:hover{ background: rgba($c-background,0.7) }
				&.router-link-exact-active,&.router-active-parent.router-link-active,&.router-active-parent{
					background: $c-background;

					.accessible-version &{
						background: $c-primary;
						.title{ color: white }
					}
				}
			}

			@media #{$to-tablet}{ padding: $space-s; display: flex; align-items: center }
		}

		.a-dropdown{
			width: 100%; margin-top: 3px; right: 0; z-index: 5;
			&:before{ display: none }
		}

		&__contact{
			color: $c-primary; display: block; text-align: left;
			&:before{ font-size: 4.5rem }
		}
	}
</style>
