<template>
	<div class="m-order-detail">

		<p v-if="data.contacts.length"><b>{{data.title}}</b></p>
		<p v-else>
			{{data.title}}
			<span v-if="data.price" class="m-order-detail__price">{{formatPrice(data.price)}} HT</span>
		</p>

		<div class="m-order-detail__icons" v-if="data.description">
			<span data-icon="calendar" class="icon-flex" v-if="data.description.startAt" v-html="formatDate(data.description.startAt)"></span>
			<span data-icon="pin" class="icon-flex" v-if="data.description.city">{{data.description.city}}</span>
			<span data-icon="timer" class="icon-flex" v-if="data.description.duration">{{data.description.duration}}h</span>
      <span data-icon="screen" v-if="data.description.format === 'e-learning' || data.description.format === 'webinar'" class="icon-flex">{{ data.description.format }}</span>
		</div>

		<div v-if="data.contacts.length" class="m-order-detail__contacts">
			<p><b>Collaborateur(s) inscrit(s)</b></p>
			<ul>
				<li v-for="(contact,index) in data.contacts" :key="contact.id" class="m-order-detail__line">
					{{contact.firstname + ' ' + contact.lastname}} <span class="m-order-detail__price" v-if="data.price">{{formatPrice(data.price)}}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>

	export default {
		props:['data'],
		name: "orderDetail"
	}
</script>



<style lang="scss">
	@import '../../environment';
	.m-order-detail{
		& > * + *{ margin-top: $space-s }
		p{
			display: flex; justify-content: space-between;
			span{ color: $c-main }
		}
		&__line{
			display: flex; justify-content: space-between;
			&+&{ margin-top: 1rem }
		}
		&__price{ text-align: right; color: $c-main  }
		&__contacts{
			margin-top: $space;
			ul{ margin-top: 1rem }
		}
		&__icons{
			> span{ margin-right: $space-m }
		}
	}
</style>