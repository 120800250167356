<template>
	<div class="m-search" :class="{'m-search--has-results':results, 'm-search--loading':loading}" v-click-outside="close" v-if="user">
		<form class="m-search__input input--search" @submit.prevent="query" id="search">
			<input type="text" placeholder="Rechercher..." v-model="params.query" minlength="3"/>
			<input type="submit" value="search" />
			<loader v-if="loading"></loader>
		</form>
		<div class="m-search__result" v-if="results">
			<div v-for="(entity,type) in results" class="m-search__entity" :class="'m-search__entity--'+type" v-if="entity.count">
				<h2 class="title">{{type}}</h2>
				<div class="m-search__items">
					<component :is="type" :data="item" v-for="(item, index) in entity.items" :key="type+item.id"></component>
				</div>
			</div>
			<div v-if="!Object.values(results).some(item => item.count > 0)">
				Aucun résultat
			</div>
		</div>
	</div>
</template>

<script>

	import searchRepository from "@/repositories/searchRepository";

	export default {
		name: 'search',
		data(){
			return{
				results : false,
				loading : false,
				params:{
					query : '',
					limit : 3
				}
			}
		},
    computed:{
      user(){
        return this.$user()
      }
    },
		methods:{
			close(){
				this.results = false;
				this.params.query = '';
			},
			query(){

				if( this.loading )
					return;

				this.loading = true;

				searchRepository.get(this.params).then(response=>{

					this.results = response
					this.loading = false

				},e =>{
					this.results = false;
					this.loading = false
				})
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-search{
		.a-loader{
			&__spinner{
				right: 5px; left: auto; transform: scale(0.7);
				div{ border-color: #fff transparent transparent transparent }
			}
		}
		.input--search{
			&:after{ color: white; padding: 11.5px }
			input[type=text]{
				background: rgba(white,0.3); color: white;
				&::placeholder{ color: white }

				.accessible-version &{ background: $c-primary-light; border-color: $c-primary-light }
			}
			input[type=submit]{
				position: absolute; right: 0; top: 0; height: 100%; opacity: 0; z-index: 1;
			}
		}
		&__result{
			position: absolute; top: 100%; width: 100%; background: #fff; padding: $space; box-shadow: 2px 0 5px rgba(0,0,0,0.15); border-radius: 0 0 4px 4px;
			text-align: left; max-height: 60vh; overflow: auto; color: $c-main;  -webkit-overflow-scrolling: touch;
			.title{ margin-bottom: $space-s }
		}
		&--has-results{
			input[type=text]{ border-radius: 4px 4px 0 0 }
		}
		&--loading{
			.input--search:after{ display: none }
		}
		&__entity{
			margin-bottom: $space;
			&--expert{
				.m-expert{
					&__inner{ background: $c-background-lighter }
					&__info{ padding: 1rem }
					&__avatar{ width: 25% }
					&__initial{ font-size: 5rem }
					&__title{ margin-bottom: $space-s }
					& + .m-expert{ margin-top: $space-m }
				}
			}
			&--formation{
				.m-training-item{
					@media #{$from-small}{
						padding: $space-s $space;
					}
					&:nth-child(odd){ background: $c-background-lighter }
          &+.m-training-item{ margin-top: $space-s }
					&__price{ font-size: $font-l }
				}
			}
			&--news{
				.m-search__items{ display: flex; justify-content: space-between }
				.m-news{ flex-basis: calc(33.3% - 1rem); border-bottom: 0; padding: 0 }
			}
		}
	}
</style>
