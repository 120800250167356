<template>
  <div class="m-migrate">
	  <div v-if="step===1">
		  <div class="title-big">Créer un nouveau mot de passe {{data.isLegalRepresentative?'représentant légal':'collaborateur'}}</div>

		  <p class="m-migrate__intro">Pour valider la création de votre compte personnel, vous devez d’abord définir un nouveau mot de passe qui vous servira lors de vos prochaines connexions.<br>
			  Pour rappel, votre adresse e-mail professionnelle devient quant à elle votre identifiant de connexion.<br><br>
			  Ces informations devront rester confidentielles. Nous vous conseillons de ne pas les divulguer.
		  </p>

		  <form @submit.prevent="createAccount">
			  <vue-form-generator tag="div" :schema="form" :model="model" :options="formOptions" @validated="validateForm"></vue-form-generator>
			  <div class="bottom-center">
				  <a class="button button--border" @click="home" v-if="data.isLegalRepresentative">Finaliser plus tard</a>
				  <submit text="Créer le mot de passe" :loading="loading" :success="success" text-success="Mot de passe crée"></submit>
			  </div>
		  </form>
	  </div>
	  <div v-else-if="step===2">
		  <div class="title-big">Félicitations !</div>
		  <div v-if="data.isLegalRepresentative">
			  <p v-if="collaborators.length">
				  Votre nouveau mot de passe a bien été pris en compte. La multiconnexion a donc été activée sur votre espace adhérent !<br>
				  Vous pouvez désormais inviter vos collaborateurs à activer leur propre compte.<br/>
				  Attention : seuls les collaborateurs qui possèdent une adresse e-mail unique pourront être invités.
			  </p>
			  <p v-else>
				  Votre nouveau mot de passe a bien été pris en compte. La multiconnexion a donc été activée sur votre espace adhérent !<br>
				  Lorsque vous ajouterez un nouveau collaborateur, vous pourrez l’inviter à créer son propre compte depuis sa fiche collaborateur.
			  </p>
			  <form @submit.prevent="InviteAll" v-if="collaborators.length">
				  <div class="bottom-center">
					  <submit :progress="progress" :text="'Inviter '+collaborators.length+' collaborateur(s)'" :loading="loading" :success="success" text-success="Invitations envoyées"></submit>
					  <a class="link" @click="redirect">Si vous ne souhaitez pas généraliser l’invitation, merci d’activer manuellement les comptes collaborateurs</a>
				  </div>
			  </form>
			  <div class="bottom-center" v-else>
				  <router-link to="/" class="button">J’ai compris</router-link>
			  </div>
		  </div>
		  <div v-else>
			  <div v-if="!profilValid">
					<p>Votre nouveau mot de passe a bien été pris en compte. Pour profiter pleinement de votre espace adhérent, merci de renseigner les informations manquantes dans votre profil.
					</p>
				  <div class="bottom-center">
					  <router-link to="/user/profil" class="button">Compléter mon profil</router-link>
				  </div>
			  </div>
			  <div v-else>
				  <p>
					  Votre nouveau mot de passe a bien été pris en compte. Vous pouvez désormais profiter pleinement de votre espace adhérent.
				  </p>
				  <div class="bottom-center">
					  <router-link to="/" class="button">J’ai compris</router-link>
				  </div>
			  </div>
		  </div>
	  </div>
  </div>
</template>

<script>

import contactRepository from '@/repositories/contactRepository';
import companyRepository from "@/repositories/companyRepository";
import userRepository from "@/repositories/userRepository";

export default {
  name: "migrate",
  props:['data'],
  data(){
    return{
      loading: false,
      error: false,
      success: false,
      collaborators: [],
	    step:1,
	    progress:0,
	    profilValid: false,
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      model:{
        email: '',
        password: '',
        confirmPassword: '',
        hasDashboard: true
      },
      form:{
        fields: [
          {
            type: "input",
            inputType: "text",
            model: "email",
            label: "Email",
            styleClasses: "fields-label",
            disabled: true
          },
          {
            type: "input",
            inputType: "password",
            inputName: "new-password",
            model: "password",
            label: "Mot de passe",
            styleClasses: "fields-label",
            required: true,
            autocomplete: 'new-password'
          },
          {
            type: "input",
            inputType: "password",
            model: "confirmPassword",
            inputName: "new-confirm-password",
            label: "Confirmer le mot de passe",
            autocomplete: 'new-password',
            required: true,
            validator:
                (value, field, model) => {
                  let pass = model.password;
                  if (pass !== value) {
                    return ["Les mots de passe ne sont pas identiques"];
                  } else {
                    return [];
                  }
                },
            styleClasses: "fields-label"
          }
        ]
      }
    }
  },
  methods:{
    validateForm(isValid, errors) {
      this.formIsValid = isValid;
    },
    close() {
      this.$emit('close');
    },
	  home() {
		  this.$router.push({name:'home'});
    },
    redirect() {
	    this.$router.push({ path:'/user/collaborateurs'});
    },
    nextStep() {
	    this.success = false;
	    this.step = 2;
    },
	  getCollaborators() {
		  companyRepository.getStaff().then(response=>{
			  this.collaborators = response.items.filter(contact=>{ return contact.isValid && !contact.hasDashboard && !contact.isLegalRepresentative && !contact.isCommercialAgent })
		  })
	  },
	  InviteAll() {

    	this.loading = true;
    	this.progress = 0;

    	let updated = 0;

		  this.collaborators.forEach(contact=>{

			  contactRepository.update(contact.id, {hasDashboard:true}).then(response=>{

			  	updated++
			  	this.progress = updated/this.collaborators.length;

				  if( this.progress === 1){

					  this.success = true;
					  this.loading = false;

					  //update contact list

					  setTimeout(this.close, 1500);
				  }
			  })
		  })
	  },
    createAccount(){

      if(!this.formIsValid)
        return;

      this.loading = true;

      contactRepository.update(this.data.id, this.model).then(response => {

        this.loading = false;
        this.success = true;

	      localStorage.removeItem('migrate')
	      if( this.data.isLegalRepresentative ){
		      userRepository.login({login: this.model.email, password: this.model.password}).then(user => {
			      this.getCollaborators();

			      this.loading = false;
			      setTimeout(this.nextStep, 1500);
		      })
	      } else {
		      userRepository.get()
		      setTimeout(this.nextStep, 1500);
	      }
      }).catch(e => {

        this.loading = false;
      })
    }
  },
	computed:{
		user(){
			return this.$user()
		}
	},
  mounted(){

	  this.model.email = this.data.email
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-migrate{
	width: 50rem;
  .title{ margin-bottom: $space }
  form{ margin-top: $space }

	&__intro{ margin-left: $space; margin-right: $space }

  .form-group + .form-group{ margin-top: $space-m }

	.button+.link{ margin-top: $space }
}
</style>