<template>
	<div class="o-trainings-list" :class="{'o-trainings-list--loading': loading}">
		<div class="block">
			<h1 class="title">{{interface.title}}</h1>
			<div v-if="google && count>=0">
				<form autocomplete="off" @submit.prevent="searchFormations" id="formations_filters">
					<div class="o-trainings-list__filters">
						<div class="o-trainings-list__search">
							<vue-form-generator tag="div" :schema="filters" :model="params" :options="formOptions"></vue-form-generator>
							<loader class="action" v-if="loading"></loader>
						</div>
						<transition name="slide">
							<div class="o-trainings-list__filters-container" :class="{'o-trainings-list__filters-container--active': showFilters }">
								<div class="title">Filtres</div>
								<vue-form-generator tag="div" class="o-trainings-list__advanced-filters" :schema="getFilters()" :model="params" :options="formOptions" @model-updated="onModelUpdated" v-if='filtersAdvanced'></vue-form-generator>
								<div data-icon="close" class="o-trainings-list__close-filters" v-if="showFilters" @click="showFilters = false"></div>
								<div class="o-trainings-list__show-filters o-trainings-list__show-filters--zindex button " v-if="showFilters" @click="showFilters = false">Voir les formations</div>
							</div>
						</transition>
					</div>
				</form>
				<transition name="fade">
					<div class="o-trainings-list__show-filters button" @click="showFilters = true" data-icon_after="filter" v-if="!filterButtonHidden">
						Filtrer
					</div>
				</transition>
			</div>
		</div>

		<div class="field-select o-trainings-list__order" v-if="count>=0" >
			<div class="field-select o-trainings-list__count">
				{{ count }} <span v-if="count < 2">résultat</span><span v-else>résultats</span>
			</div>
			<div v-if="count" class="field-select o-trainings-list__sort">
				<div class="field-wrap">
					<select v-model="sort" @change="searchFormations" v-if="params.format === 'instructor-led'">
						<option value="distance|asc">Le plus proche</option>
						<option value="startAt|asc">Le plus tôt</option>
					</select>
					<select v-model="sort" @change="searchFormations" v-else>
						<option value="startAt|asc">Trier par</option>
						<option value="duration|asc">Durée croissante</option>
						<option value="duration|desc">Durée décroissante</option>
					</select>
				</div>
			</div>
		</div>
		<div v-if="count === 0" class="o-trainings-list__empty">
			<p class="title-big">Aucune formation ne correspond à votre recherche.</p>
			<p>Modifiez les filtres de votre recherche pour trouver des formations disponibles.</p>
		</div>
		<div v-else v-infinite-scroll.full="loadMore" class="o-trainings-list__trainings">
			<trainingItem :data="formation" class="o-trainings-list__training" v-for="(formation,index) in formations" :key="formation.id"></trainingItem>
			<loader class="action" v-if="loading"></loader>
		</div>
	</div>
</template>

<script>
import formationCourseRepository from '@/repositories/formationCourseRepository';

export default {
	name: "trainings-list",
	props:['data','interface'],
	data(){

		return{
			loading: false,
			formations: false,
			count: -1,
			filterDistance: false,
			sort: 'startAt|asc',
			showFilters: false,
			formOptions: {
				validateAfterLoad: true,
				validateAfterChanged: true,
				validateAsync: true
			},
			activeFilters: false,
			filterButtonHidden: false,
			params:{},
			filters: {
				fields: [
					{
						type: "input",
						inputType: "text",
						inputName: "search",
						model: "search",
						styleClasses: ["input--search", "input--full"],
						placeholder: "Rechercher une formation"
					},
					{
						type: "input",
						inputType: "submit",
						styleClasses: ['input--submit']
					}
				]
			},
			filtersAdvanced: {}
		}
	},
	methods:{
		getFilters(){

			let self = this;

			return {
				fields: [
					{
						type: "multiselect-custom",
						model: "format",
						placeholder: "Type",
						label: "Type",
						multiple: true,
						values(){

							let values = [];
							let match = {'e-learning':'A distance', 'instructor-led':'Présentiel', 'webinar':'Webinaire'};

							for(let i=0; i< self.config.formations.filters.formats.length; i++)
							{
								let id = self.config.formations.filters.formats[i];
								values.push({ id: id, name: match[id]})
							}
							return values;
						}
					},
					{
						type: "multiselect-custom",
						model: "includedTheme",
						placeholder: "Thème obligatoire",
						label: "Thème obligatoire",
						values() {
							return  [
								{ id: 'ethics', name: 'Déontologie' },
								{ id: 'discrimination', name: 'Discriminations' }
							]
						}
					},
					{
						type: "tag-custom",
						model: "_location",
						label: 'Lieu de la formation',
						placeholder: 'Ville',
						styleClasses: 'custom-city',
						init:{
							city: '',
							distance: 5,
							location: ''
						},
						fields: [
							{
								type: "google-address",
								model: "city",
								styleClasses: ['input--googleAddress','input--search'],
								placeholder: 'Rechercher une ville',
								autocomplete: false,
								onPlaceChanged(value, place, rawPlace, model, schema){
									model.location = rawPlace.geometry.location.lat()+','+rawPlace.geometry.location.lng();
								}
							},
							{
								type: "input",
								inputType: "range",
								model: "distance",
								label: "dans un rayon de",
								unit: "km",
								min: 5,
								max: 100,
								styleClasses: ['input--range', 'input--km']
							}
						],
						visible(){ return self.params.format.includes('instructor-led') || self.params.format === '' }
					},
					{
						type: "calendar-filter-custom",
						model: "_dateRange",
						label: "Date comprise entre",
						visible(){ return self.params.format.includes('webinar') || self.params.format.includes('instructor-led') || self.params.format.length === 0 },
						min: self.config.formations.filters.startAt ? self.config.formations.filters.startAt : this.now(),
						max: self.config.formations.filters.endAt
					},
					{
						type: "multiselect-custom",
						model: "duration",
						label: "Durée",
						placeholder: "Durée",
						values(){
							return self.config.formations.filters.hours.map(function(x){ return {id:x, name:x+'h'} });
						}
					},
					{
						type: "tag-custom",
						model: "_seat",
						label: "Places",
						init:{
							minSeat: 1
						},
						placeholder: 'Places',
						fields: [
							{
								type: "input",
								model: "minSeat",
								inputType: "range",
								styleClasses: ['input--range', 'input--seat'],
								min: self.config.formations.filters.minSeat,
								max: self.config.formations.filters.maxSeat,
								unit: 'place(s)'
							}
						],
						visible(){ return self.params.format.includes('webinar') || self.params.format.includes('instructor-led') || self.params.format.length === 0 }
					},
					{
						type: "multiselect-custom",
						model: "theme",
						placeholder: "Thème",
						label: "Thème",
						values(){
							return self.config.formations.filters.themes
						}
					},
				]
			}
		},
		onModelUpdated(newVal, schema) {

			if( schema === 'search' && newVal.length < 4)
				return;

			if( schema === 'city')
				return;

			if( schema === 'format')
				this.sort = newVal === 'instructor-led'?'startAt|asc':'duration|asc';

			if( schema === 'includedTheme'){
				this.params.ethics = newVal === 'ethics' ? 1 : 0;
				this.params.discrimination = newVal === 'discrimination' ? 1 : 0;
			}


			if( schema === '_location'){

				this.params.location = newVal.location;
				this.params.distance = newVal.distance;
			}

			if( schema === '_dateRange'){

				this.params.startAt = newVal.startAt;
				this.params.endAt = newVal.endAt;
			}

			if( schema === '_seat')
				this.params.seat = newVal.minSeat;

			this.searchFormations();
		},
		reset(){

			this.params = {
				sort: 'startAt',
				seat: '',
				order: 'asc',
				limit : 20,
				offset: 0,
				search: '',
				format: [],
				location: '',
				distance: '',
				startAt: '',
				endAt: '',
				duration: '',
				includedTheme: '',
				discrimination: 0,
				ethics: 0
			};

			this.sort = 'startAt|asc';

			this.searchFormations();
		},
		searchFormations(){

			if( this.sort.length ){
				let sort = this.sort.split('|');
				this.params.sort = sort[0]
				this.params.order = sort[1]
			}

			this.query(0);
		},
		loadMore(){

			if( this.formations.length >= this.count || !this.formations.length )
				return;

			this.query(this.params.offset + this.params.limit)
		},
		query(offset){

			if( this.loading === true )
				return;

			this.params.offset = offset;

			this.loading = true;

			formationCourseRepository.list(this.params).then(response => {

				if(this.filterDistance){
					response.items = response.items.filter( formation => formation.distance <= this.filterDistance);
					response.count = response.items.length < this.count ? response.items.length : this.count
				}

				this.params.offset = response.offset;
				this.count = response.count;
				this.formations = response.items;
				this.loading = false;

				this.$store.commit('form', {type:'formations', params: this.params});

			}, e => {
				this.loading = false;
			});
		}
	},
	mounted(){

		let params = this.$store.getters.form('formations');

		if( params ){
			if(params.discrimination === 1)
				params.includedTheme = 'discrimination'
			else if(params.ethics === 1)
				params.includedTheme = 'ethics'
			this.params = params;
			this.searchFormations();
		}
		else{
			this.reset();
		}

		let self = this;
		if(window.platform === 'mobile'){
			function handleIntersection(entries) {
				entries.map((entry) => {
					self.filterButtonHidden = entry.isIntersecting
				});
			}

			this.observer = new IntersectionObserver(handleIntersection);

			this.observer.observe(document.querySelector('.o-footer'));
		}
	},
	computed:{
		google(){
			return this.$store.getters.google()
		},
		config(){
			return this.$config()
		},
		user(){
			return this.$user()
		}
	}
}
</script>

<style lang="scss">

@use "sass:math";
@import '../../environment';

.o-trainings-list{

	position: relative;

	&--loading &__search{
		.input--search:after{ display: none }
	}

	&--loading &__trainings{
		margin-bottom: 15rem;
	}

	&__search{
		position: relative;
		@media #{$from-tablet}{ margin-bottom: 1rem }
		.a-loader{ margin: 0!important; right: 4px; left: auto; width: 3rem; transform: scale(0.5) }
	}

	&__filters{
		font-size: $font-m;

		input,select{ font-size: $font-m }

		.vue-form-generator{
			@media #{$from-small}{ position: relative }

			.input--submit{
				position: absolute; z-index: 1; right: 0; top: 0; height: 100%; width: 3.5rem; opacity: 0; margin-top: 0;
				*{ width: 100%; height: 100%; font-size: 0 }
				input{ cursor: pointer }
			}

			.input--search input::placeholder{ color: $c-main }

			& > .form-group{
				margin-top: 0;
				@media #{$to-tablet}{
					margin-left: 0; margin-top: $space-s;

				}
				&.field-multiselect-custom{
					@media #{$to-tablet}{ width: 50% }
					@media #{$to-phone}{ width: 100% }
				}
			}
			& > .form-group + .form-group{
				margin-left: $space-s;
				@media #{$to-tablet}{ margin-left: 0; margin-top: $space-l }
			}

			& > .input--full{
				margin-bottom: $space-s;
				&  + .form-group{ margin-left: 0 }
			}
		}
		.button{
			text-transform: initial;
			&:before{ margin-right: 0.2rem }
		}

		.input--googleAddress{
			min-width: 20rem;
			@media #{$to-tablet}{ width: 100% }
		}
		.date-fields{
			flex-wrap: wrap; max-width: 31rem;
			legend{ width: 100% }
			input{ width: 14rem }
		}
	}

	&__filters-container{
		.title{
			display: none;
			@media #{$to-tablet}{
				display: block;
				& + *{ margin-top: $space }
			}
		}
		@media #{$to-tablet}{
			transform: translateX(100%); position:fixed; top: 0; left: 0; right: 0; box-shadow: $box-shadow; background: white; z-index: 5; padding: ($header-h-mobile + $space) $space $space; overflow-y: auto; -webkit-overflow-scrolling: touch; height: var(--app-height);
			&--active{ transform: none }
		}
	}

	&__close-filters{ position: absolute; top: $header-h-mobile; margin-top: $space-m; z-index: 5; right: $space-m; color: $c-primary; font-size: 2rem }

	&__advanced-filters{
		& > div{
			@media #{$from-small}{
				display: flex; align-items: flex-end; flex-wrap: wrap; width: 100%; margin-left: math.div($space-s,2); margin-right: math.div($space-s,2);

				.form-group{ margin: math.div($space-s,2);}

				.form-group + .form-group{ margin-top: math.div($space-s,2) }
			}
		}

		.field-multiselect-custom, .field-tag-custom,.field-calendar-filter-custom{
			label{ display: none }
			.multiselect__caret{ display: none }
			@media #{$to-tablet}{
				label{
					font-weight: bold; color: $c-primary; font-size: $font-l; display: block;
					& + .field-wrap {margin-top: $space-m }
				}
			}
		}
	}

	&__show-filters{
		border-radius: 20px; position: fixed; bottom: $space; z-index: 4; left: 50%; transform: translateX(-50%); font-size: $font-l;
		@media #{$from-small}{ display: none }

		&--zindex{ z-index: 100 }
	}

	select{
		width: 100%
	}

	&__button{
		margin-left: auto; align-self: flex-end;
		@media #{$to-tablet}{ margin-top: $space-m }
	}

	&__filters-reset{
		text-align: right; margin-top: $space-m;
		&:hover{ text-decoration: none; }
	}

	&__order{
		margin-top: $space; text-align: right; margin-bottom: $space-s; display: flex;
		select{ width: auto; text-align-last: right }
	}

	&__sort{ margin-left: auto }

	&__trainings{
		position: relative; min-height: 10rem;
		.a-loader{ margin-top: $space; top: auto; bottom: -3rem; }
	}

	&__training{
		& + &{ margin-top: $space-s }
	}

	&__count{
		color: $c-primary; margin-top: 5px;

		@media #{$from-small}{
			margin-left: 3rem;
		}
	}

	&__empty{
		background: $c-background-darker; text-align: center; padding: 5rem $space; border-radius: $border-radius; color: $c-primary;
		* + *{ margin-top: $space-m }
		.button{ margin-top: 5rem }
	}


}

</style>