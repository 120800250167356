<template>
  <div class="m-signature no-padding" :class="{'m-signature--loading':loading}">

    <loader v-if="loading" blur="1"></loader>

    <div class="m-signature__infos">
      <div class="m-signature__heading">
        <div class="m-signature__location">{{ data.mode_contrat_lib }}</div>
        <div class="m-signature__dropdown dots" data-icon="dots" :class="{'dots--loading':(downloading||refreshing||recreating||sending)}">
	        <loader v-if="downloading||refreshing||recreating||sending"></loader>
          <dropdown>
            <div class="dots__item" v-if="data.archive !== '1'">
              <a @click="download">Télécharger le dossier</a>
            </div>
            <div class="dots__item" v-if="data.status === 'ready'">
              <a @click="refresh">Vérifier le statut</a>
            </div>
            <div class="dots__item" v-if="data.status === 'failed' || data.status === 'canceled'">
              <a @click="recreate">Recréer la signature</a>
            </div>
            <div class="dots__item" v-if="data.can_remind">
              <a @clik="sendLink">Renvoyer le lien</a>
            </div>
            <div class="dots__item secondary" v-if="data.status === 'ready'">
              <a @click="cancel">Annuler</a>
            </div>
          </dropdown>
        </div>
      </div>

      <h2 class="m-signature__title title-big">
        <span v-if="data.type_contrat">{{ data.type_contrat }}</span>
        <span v-else>Autre document</span>
      </h2>

      <div class="m-signature__details">
        <div>Créée le {{formatDigitDate(data.created_at)}}</div>
        <div class="m-signature__status" :class="{'m-signature__status--signed': data.status === 'completed'}">{{data.status_lib}} <span v-if="data.status === 'failed'">. Les signatures vous ont été recréditées.</span></div>
      </div>
    </div>

    <div class="m-signature__signataires">
      <div class="m-signature__signataires-heading">
        <h3 class="m-signature__subtitle subtitle">
          {{data.signatures.length }} signataires <span v-if="waiting">- {{  waiting.length }} en attente</span>
        </h3>
        <a @click.prevent="details = !details" class="link"><span v-if="!details">Voir plus de détails</span><span v-else>Voir moins de détails</span></a>
      </div>

      <div class="m-signature__list">
        <swiper ref="mySwiper" :options="swiperOptions" class="m-signature__slider">
          <swiper-slide v-for="(signataire, index) in data.signatures" :key="index"  class="m-signature__card">
            <div class="m-signature__people">
							<span v-if="details" class="m-signature__label">
								<span v-if="signataire.status === 'signed'" class="signature-checked">Validée</span>
								<span v-else-if="signataire.status === 'failed'" class="signature-failed">Echouée</span>
								<span  v-else-if="data.status === 'canceled'" class="signature-failed">Annulée</span>
								<span v-else>En attente</span>
							</span>

              <div class="m-signature__name">
                <b>{{signataire.firstname}} {{signataire.lastname}}</b>
                <div v-if="!details">
                  <span v-if="signataire.status === 'signed'"  data-icon="check" class="m-signature__icon signature-checked"></span>
                  <span data-icon="close" v-else-if="signataire.status === 'failed' || data.status === 'canceled'" class="m-signature__icon signature-failed"></span>
                  <span class="m-signature__icon" v-else data-icon="timer"></span>
                </div>
              </div>

              <div class="m-signature__people" v-if="details">
                <div v-if="signataire.birthdate" class="m-signature__people-birthdate">Né(e) le {{formatDigitDate(signataire.birthdate)}}</div>
                <div class="m-signature__people-other">
                  <div v-if="signataire.email"><a :href="'mailto:'+signataire.email">{{signataire.email}}</a></div>
                  <div v-if="signataire.phone">{{signataire.phone}}</div>
                </div>
              </div>
            </div>

          </swiper-slide>
          <div class="swiper-button-prev slider-nav" slot="button-prev" @click="prev()" ></div>
          <div class="swiper-button-next slider-nav" slot="button-next" @click="next()"></div>
        </swiper>
      </div>
    </div>

  </div>
</template>

<script>
import signatureRepository from '@/repositories/signatureRepository';
import Loader from "@/components/atom/loader";

export default {
  name: "signature",
	components: {Loader},
	props:['data'],
  data(){
    return{
      swiperOptions: {
        slidesPerView: 1.3,
        spaceBetween: 10,
	      watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints:{
          768:{
            slidesPerView: 3
          }
        }
      },
      loading: false,
      downloading: false,
      refreshing: false,
      sending: false,
      recreating: false,
      done: false,
      details: false,
      statusNotValid:['canceled','expired','failed']
    }
  },
  computed:{
    waiting(){
      return this.data.signatures.filter(signataire => {
        return signataire.status === 'ready' || signataire.status === 'waiting'
      })
    },
	  activeIndex(){
    	return this.$refs.mySwiper.$swiper.activeIndex
	  }
  },
  methods:{
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    recreate(){
      this.recreating = true;

      signatureRepository.download(this.data.ref_contrat).then(response=>{
        this.$popin('signature-add',this.data, true, true, 1)
        this.recreating = false;
      });

    },
    cancel(){

      if( confirm('Voulez vous vraiment annuler cette collecte de signatures ?') ){

        this.loading = true;

        signatureRepository.cancel(this.data.ref_contrat).then(response=>{
          this.$update();
        },response=>{
          this.loading = false;
        });
      }
    },
    refresh(){

      this.refreshing = true;

      signatureRepository.refresh(this.data.ref_contrat).then(response=>{
        this.$update();
      },response=>{
        this.refreshing = false;
      });

    },
    download(){

      this.downloading = true;

      signatureRepository.download(this.data.ref_contrat).then(response=>{
        this.downloading = false;
      }, response=>{
        this.downloading = false;
      });
    },
    sendLink(){
      this.sending = true;
      signatureRepository.sendLink(this.data.ref_contrat).then(response=>{
        this.sending = false;
      });

    }
  },
  mounted(){
    this.done = this.data.signatures.filter(item => item.status === 'signed').length
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-signature{
  $c: &;

  .signature-checked{ color: $c-green }
  .signature-failed{ color: $c-secondary }

  position: relative; box-shadow: $subtle-box-shadow;

  &__heading{
    display: flex; align-items: center;
    & + *{ margin-top: $space-s }
  }
  &__dropdown{ margin-left: auto }

  &__title{
    @media #{$to-tablet}{
      b{ line-height: 2 }
    }
    & + div{ margin-top: $space-s }
  }
  &__action{
    display: flex; align-items: center; margin-top: $space-m;
    & > * + *{ margin-left: $space-s }
  }

  &__download, &__refresh{
    margin-left: 0.5rem;
  }
  &__location{ color: $c-primary; opacity: 0.8 }

  &__details{ display: flex; justify-content: space-between }

  &__infos{ padding: $space-m $space }
  &__status{
    margin-left: auto; background: $c-background; border-radius: $border-radius; padding: $space-s; color: $c-primary; font-size: $font-xs;
    &--signed{ background: $c-green; color: white }
  }
  &__cancel{ cursor: pointer; font-size: $font-s }

  &__signataires{
    background: $c-background-lighter; padding: $space; border-top: solid 1px $c-border; overflow: hidden;

    &-heading{
      display: flex; justify-content: space-between; align-items: center;
      .link{ font-weight: normal; white-space: nowrap }
      & + *{ margin-top: $space-s }
    }
  }

  &__subtitle{
    width: 100%; margin-bottom: 0;
    span{ color: $c-main }
  }

  &__list{
    color: $c-primary; position: relative;

    .swiper-container{ overflow: initial }
    .swiper-slide{ height: auto }
  }

  &__card{ background: white; padding: 1rem ; border: 1px solid $c-background-darker; border-radius: 4px }

  &__name{
    display: flex; align-items: center; justify-content: space-between; font-size: $font-s;
  }

  &__label{
    span{ color: $c-primary; background: $c-background-darker; border-radius: $border-radius; padding: 3px 8px; font-size:$font-s }
    & + *{ margin-top: $space-m }
  }

  &__icon{
    color: $c-primary; background: $c-background-darker; border-radius: $border-radius; padding: 4px; font-size: 16px; justify-content: center;
    display:flex; align-items: center;
  }

  &__people{
    font-size: $font-l;
    &-birthdate{
      font-size: $font-s; color: $c-main;
    }
    &-other{ margin-top: $space-m; font-size: $font-m }
  }

  & + &{ margin-top: $space }
}

.slider-nav{
  background: white; height: 100%; top: 0; color: $c-primary; margin-top: 0; width: 26px; border: 1px solid $c-background-darker;

  &:after{ font-size: 16px }
  &.swiper-button-next{ right: -$space;border-top-left-radius: 4px; border-bottom-left-radius: 4px; border-right: 0  }
  &.swiper-button-prev{ left: -$space; border-top-right-radius: 4px; border-bottom-right-radius: 4px; border-left: 0 }
  &.swiper-button-disabled{ opacity: 0 }

  @media #{$to-phone}{ display: none }
}

</style>