<template>
	<div class="m-training-participants" v-if="data.length">
		<h2 class="title">Participants</h2>
			<ul>
				<li class="m-training-participants__file" v-for="(participant, index) in data" :key="index">
					<div class="m-training-participants__file-name">
						{{ participant.firstname }} {{ participant.lastname }}
					</div>
					<div class="m-training-participants__details m-training-participants__status">
						<span v-if="participant.appendix">Attestation délivrée le {{formatDigitDate(participant.appendix.createdAt)}}</span>
						<span v-else>Aucune attestation</span>
					</div>
					<div class="m-training-participants__dropdown dots" data-icon="dots" v-if="participant.appendix">
						<dropdown>
							<div class="dots__item" @click="downloadAsset(participant.appendix)"  v-if="participant.appendix">
								Télécharger l'attestation
							</div>
						</dropdown>
					</div>
				</li>
			</ul>
	</div>
</template>

<script>
	import appendixRepository from "@/repositories/appendixRepository";
	export default {
		name: "training-participants",
		props:['data','type'],
		data(){
			return{
				downloading:false
			}
		},
		methods:{
			downloadAsset(appendix) {
				this.downloading = appendix.id;
				appendixRepository.download(appendix.id).then(response=>{
					this.downloading = false;
				},response=>{
					this.downloading = false;
				});
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-training-participants{
		text-align: left;

		.title + *{ margin-top: $space-m }

		&__details{ font-size: $font-xs; color: $c-main }

		&__file{
			font-size: $font-xs; display: flex; align-items: center;

			& > * + *{ margin-left: $space-m }

			& + &{ margin-top: $space-m }

			&-name{
				font-size: $font-s; color: $c-primary;
			}

			&-button{
				display: flex; align-items: center; justify-content: flex-end;
			}

		}

		&__status{ margin-left: auto }
	}

</style>