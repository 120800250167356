<template>
	<div class="o-elearning-password block" v-if="user && user.contact">
		<h2 class="title">E-learning</h2>
		<div v-if="user.contact.elearning">
			<p class="subtitle">Email de connexion</p>
			<p>{{ user.contact.elearning.email }}</p>
			<div class="bottom-right">
				<a @click.prevent="reinitElearning" class="link">Réinitialiser le mot de passe</a>
			</div>
		</div>
		<div v-else>
			<p class="subtitle">Compte</p>
			<p>Vous n'avez pas encore de compte</p>
		</div>
	</div>
</template>

<script>
import contactRepository from "@/repositories/contactRepository";

export default {
	name: "elearning-password",
	computed:{
		user(){ return this.$user() }
	},
	methods: {
		reinitElearning() {
			contactRepository.requestElearningPassword(this.user.contact.id).then(response => {
				this.$popin('change-password', {type: 'elearning', email: response.body.response.email, login: this.user.contact.id});
			});
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';

.o-elearning-password{
	color: $c-primary
}
</style>