<template>
	<div class="m-foad-text">
		<div class="wysiwyg" v-html="data.wysiwyg"></div>
	</div>
</template>

<script>
export default {
	name: "foad-text",
	props:['data', 'participant'],
  mounted() {
    this.$emit('updated', 1)
  }
}
</script>

<style lang="scss">
@import '../../environment';
	.m-foad-text{

	}
</style>