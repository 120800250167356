<template>
	<div class="a-invoice">
		<div class="a-invoice__details">
			<div class="a-invoice__line">
				<span>Sous total</span>
				<span>{{ formatPrice(data.amount_et) }}</span>
			</div>
			<div class="a-invoice__line">
				<span>TVA (20%)</span>
				<span>{{ formatPrice(data.vat) }}</span>
			</div>
			<div class="a-invoice__line a-invoice__line--total">
				<span>TOTAL TTC</span>
				<span>{{ formatPrice(data.amount_ati) }}</span>
			</div>

			<form @submit.prevent="download" v-if="data.appendix" id="invoice_download">
				<submit class="button--small button--secondary" text="Télécharger la facture" :loading="downloading"></submit>
			</form>
		</div>
	</div>
</template>

<script>

	import appendixRepository from "@/repositories/appendixRepository";

	export default {
		name: "invoice",
		props:['data'],
		data(){
			return {
				downloading : false
			}
		},
		methods:{
			download(){
				this.downloading = true;
				appendixRepository.download(this.data.appendix).then(
					response=>{
						this.downloading = false;
					},
					response=>{
						this.downloading = false;
					});
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';
	.a-invoice{

		&__details{
			margin-top: $space-m;
			form{ text-align: right; margin-top: 1rem }
		}


		&__line{
			display: flex; justify-content: space-between; text-transform: uppercase;

			& + * { margin-top: 0.5rem }

			&--total{ font-size: $font-xl; color: $c-primary; font-weight: bold; margin-top: $space-m }
		}
	}
</style>