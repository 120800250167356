<template>
	<div class="v-login" :class="{'v-login--caci': type === 'caci'}">
		<div class="v-login__container">
			<div class="v-login__center">
				<div class="v-login__content">
					<a class="v-login__back icon-flex" v-if="step2" data-icon="chevron-left" @click="step2 = false">Retour</a>
					<a class="v-login__back icon-flex" v-else-if="type === 'caci'" data-icon="chevron-left" :href="snpi_url">Retour</a>
					<h1 class="v-login__uptitle title">
						Mon espace pro
						<span class="title--small" v-if="!step2 && type !== 'caci'"><br/>Accès adhérent</span>
					</h1>

					<div v-if="type === 'caci' || step2">
						<div class="v-login__logo">
							<img src="/img/logo-caci.svg" v-if="type === 'caci'" />
							<img src="/img/logo-snpi.svg" v-else />
						</div>
						<div class="v-login__baseline" v-if="type !== 'caci'">
							Premier syndicat français de l'immobilier depuis 1963
						</div>

						<h1 class="v-login__title">
							<span v-if="type === 'caci'">Bienvenue dans l'espace adhérent des agents commerciaux</span>
							<span v-else>Bienvenue dans l'espace adhérent SNPI</span>
						</h1>
						<form @submit.prevent="login" class="v-login__form">

							<div :class="{'disabled':loading||connected}">

								<vue-form-generator tag="div" :schema="formLogin" :model="params" :options="formOptions"></vue-form-generator>

								<div class="v-login__actions">
									<vue-form-generator tag="div" :schema="rememberLogin" :model="params" :options="formOptions"></vue-form-generator>
									<div @click="forgotten()" class="v-login__forgotten" v-if="!companies.length">Mot de passe oublié ?</div>
								</div>
							</div>

							<div class="bottom-right">
								<button v-if="type === 'caci' && !loading && !connected && config.registration.enabled && !companies.length" class="button button--white" @click.prevent="caciRegister" type="button">Inscription</button>
								<span class="v-login__sync" v-if="wait">Veuillez patienter...</span>
								<span class="v-login__sync" v-if="syncing">Synchronisation en cours...</span>
								<submit :loading="loading" text="Connexion" :success="connected" text-success="Connecté" :error="error" :text-error="error"></submit>
							</div>
						</form>
					</div>
					<div class="v-login__choice" v-else>
						<div>
							<div @click="step2 = true" class="v-login__box">
								<img src="/img/logo-snpi.svg" class="v-login__snpi" />
								Espace Adhérent SNPI
							</div>
						</div>
						<div>
							<a :href="caci_url" class="v-login__box">
								<img src="/img/logo-caci.svg" class="v-login__caci" />
								Espace Adhérent CACI
							</a>
						</div>
					</div>
				</div>
			</div>
			<footer class="v-login__footer">
				<div class="v-login__footer-container">
					Pour toutes informations, contactez notre équipe
					<div class="v-login__footer-infos">
						<a data-icon="telephone" href="tel:01 53 64 91 91">01 53 64 91 91</a>
						<a data-icon="mail" href="mailto:contact@snpi.fr">contact@snpi.fr</a>
					</div>
				</div>
			</footer>
		</div>

		<div class="v-login__visual">
			<div class="v-login__logos">
				<div>
					<a href="https://www.snpi.fr" target="_blank"><img src="/img/logo-snpi.svg" /></a>
				</div>
				<div>
					<a href="https://www.asseris.fr" target="_blank"><img src="/img/logo-asseris.png" /></a>
				</div>
				<div>
					<a :href="caci_url" target="_blank"><img src="/img/logo-caci.svg" /></a>
				</div>
				<div>
					<a href="https://www.vhs.fr" target="_blank"><img src="/img/logo-vhs.svg" /></a>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	import userRepository from '@/repositories/userRepository';
	import appendixRepository from '@/repositories/appendixRepository';
	import configRepository from '@/repositories/configRepository';
	import termRepository from '@/repositories/termRepository';

	import Vue from 'vue';

	export default {
		name: "Login",
		data(){
			return{
				loading: false,
				connected: false,
				syncing: false,
				wait: false,
				error: false,
				type: 'snpi',
				caci_url: process.env.VUE_APP_CACI,
				snpi_url: process.env.VUE_APP_SNPI,
				step2: false,
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
				params:{
					login:'',
					password:'',
					company:'',
					remember: false
				},
				formLogin: {
				},
				rememberLogin: {
					fields: [
						{
							type: "checkbox-custom",
							inputName: "remember",
							model: "remember",
							label: "Se souvenir de moi",
						}
					]
				},
			}
		},
		methods:{
			login(){

				this.step2 = true;

				let self = this;
				this.loading = true;

				let waitTimer = setTimeout(()=>{ self.wait = true }, 2000);

				userRepository.login(this.params).then(user=>{

					if (Vue.rollbar)
						Vue.rollbar.configure({payload: {person: {id: user.id}}});

					window.BugHerdConfig.metadata = {user_id: user.id, type:user.type};

					clearTimeout(waitTimer);

					let syncTimer = setTimeout(()=>{ self.wait = false; self.syncing = true }, 2000);

					appendixRepository.sync(user, false).then(response=>{

							clearTimeout(syncTimer);

							this.loading = false;
							this.connected = true;
							this.syncing = false;
							this.wait = false;

							setTimeout(function () {

								let from = localStorage.getItem('from');

								if( !user.type || user.registration )
									self.$router.push({ name: 'register'});
								else if( from && from !== '/register' && from !== '/user/login' )
									self.$router.push({ path: from});
								else
									self.$router.push({ name: 'home'});

							}, 500);
						},
						response=>{

							clearTimeout(syncTimer);
							this.wait = false;
							this.loading = false;
						});

					termRepository.get();

				}, response=>{

					clearTimeout(waitTimer);

					if( this.companies.length && !this.params.company )
						this.params.company = this.companies[0].id;

					this.wait = false;
					this.loading = false;
				})
			},
			forgotten(){
				this.$popin('forgotten-password');
			},
			caciRegister(){
				this.$popin('user-register');
			}
		},
		computed:{
			config(){ return this.$config() },
			companies(){ return this.$store.getters.companies() }
		},
		mounted() {

			let self = this;

			this.type = this.$store.getters.type();

			this.formLogin={
				fields: [
					{
						type: "input",
						inputType: "text",
						model: "login",
						required: true,
						autocomplete:'username',
						attributes: {'autofocus':true},
						label: "Numéro d'adhérent ou email",
						placeholder: 'jean@dupond.fr'
					},
					{
						type: "password-custom",
						model: "password",
						required: true,
						autocomplete:'current-password',
						label: "Mot de passe",
					},
					{
						type: "select",
						model: "company",
						styleClasses: "fields-label",
						required: true,
						label: "Société",
						selectOptions:{
							noneSelectedText: "---"
						},
						values(){
							return self.companies
						},
						visible(){ return self.companies.length }
					}
				]
			}

			if( localStorage.getItem('login') ){

				this.params.login = localStorage.getItem('login');
				this.params.remember = true;
			}

			if( this.$route.params.token ){

				this.params.token = this.$route.params.token;
				this.login();
			}
		}
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../environment';
	.v-login{
		$c: &;

		display: flex; justify-content: center; min-height: 100vh; color: $c-primary; background: var(--c-background);

		.ios &{
			height: var(--app-height);
		}

		&__uptitle{
			font-size: $font-xxl;
			& +*{ margin-top: $space-l }
		}

		&--caci{
			.v-login__type{ color: $c-primary }
		}
		& > *{
			width: 50%;
			@media #{$to-tablet}{ width: 90% }
		}

		&__center{ display: flex; justify-content: center; align-items: center; flex-direction: column; flex: 1 }
		&__sync{ display: inline-block; margin-right: 1rem }

		&__container{ display: flex; flex-direction: column }

		&__content{
			text-align: left; max-width: 37rem; width: 100%; position:relative; margin: $space-m 0;
		}

		&__forgotten{ font-size: $font-s }

		&__title{
			font-weight: bold; font-size: $font-xxl; margin-top: $space;
		}

		&__visual{
			background: url('/img/login.svg') no-repeat center;
			background-size: cover; position: relative;

			@media #{$to-tablet}{ display: none }
		}

		&__logo{ width: 7rem }
		&__type{ position: absolute; top: 2rem; color: $c-secondary; text-decoration: underline }

		&__form{
			margin-top: $space-m;
			label{ font-weight: bold; font-size: $font-s; text-transform: uppercase }
      .custom-password__show{ background: none }
      .field-wrap{ margin-top: 0.5rem }
			input{
        background: #fff;
				&::placeholder{ color: $c-main }
			}

			.form-group + .form-group{ margin-top: $space-m }
		}

		&__baseline{ margin-top: 1rem }

		&__actions{
			display: flex; justify-content: space-between; margin-top: $space-m; align-items: center;

			& > *{ cursor: pointer }
			label{ text-transform: none; font-weight: normal }
		}

		&__asseris{ width: 60px; position: absolute; bottom: 2rem; }

		&__choice{
			display: flex; align-items: center; color: $c-primary-light;

			& > *{
				flex: 1; background: #fff; padding-top: calc(50% - #{math.div($space-s,2)} ); border-radius: $border-radius; position: relative; transition: allow(box-shadow);
				cursor: pointer; box-shadow: $subtle-box-shadow;

				.no-touch &:hover{ box-shadow: $selected-box-shadow }

				& + *{ margin-left: $space-s }

				& > *{ padding: $space $space-m; display: block; position: overlay(); }
			}
		}
		&__box{ display:flex; flex-direction: column; justify-content: space-between; align-items: center; text-transform: uppercase; font-size: $font-xs }

		&__footer{
			width: 100%; padding: $space-m; border-top: 1px solid rgba(0,0,0,0.04);
			@media #{$to-phone}{ margin-bottom: $space-m }
			&-container{ max-width: 36rem; margin: auto }
			&-infos{
				background: $c-primary; color: white; border-radius: $border-radius; font-weight: bold; margin-top: $space-s; padding: math.div($space-s,2);
				& > *{
					&:before{ font-size: $font-xxl; margin-right: math.div($space-s,2) }
					& + *{ margin-left: $space-l }
				}
			}
		}

		&__snpi{ max-width: 9rem }
		&__caci{ margin-top: -$space-s}

		&__back{
      position: absolute; top: -2rem; font-size: $font-xxs; text-transform: uppercase; letter-spacing: 0.05em; cursor: pointer;
      &:before{ margin-top: 0 }
    }

		&__logos{
			position: absolute; bottom: $space; left: 50%; transform: translateX(-50%); padding: $space-s $space-m; background: #fff; border-radius: $border-radius;
			display: flex;
			& > *{
				&	+ *{ margin-left: $space-m }
				img{ max-height: $space; width: auto }
			}
		}
	}

</style>