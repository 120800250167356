import Vue from "vue";
import store from "@/services/store";
import cache from "@/services/cache";
import router from '@/services/router'

export default {

	get() {
		return new Promise((resolve, reject) => {
			Vue.http.get('user').then(response => {

				let data = response.body.response;
				let validUser = data.user && data.user.type && !data.user.registration;

				if(!validUser && router.app.$route.name !== 'register')
					router.push({ name: 'register'});

				if(router.app.$route.name === 'login')
					router.push({ name: 'home'});

				store.commit('user', data.user);
				store.commit('membership', data.membership);

				store.commit('legacy', data.legacy)

				if( resolve )
					resolve([data.user,validUser]);

			}).catch(reject);
		})
	},

	getNotifications(params) {

		return new Promise((resolve, reject) => {

			Vue.http.get('user/notification',{params: params}).then(response => {

				resolve(response.body.response);

			}).catch(reject);
		})
	},

	updateNotification() {

		return Vue.http.post('user/notification')
	},

	update(id, form) {

		return Vue.http.post('user/'+id, form)
	},

	create(form) {

		return Vue.http.post('user', form)
	},

	requestPassword(login) {

		return Vue.http.get('user/password/'+login)
	},

	resetPassword(login, form) {

		return Vue.http.post('user/password/'+login, form)
	},

	refresh(refresh_token) {

		return new Promise((resolve, reject) => {

			Vue.http.post('user/refresh', {refresh_token: refresh_token}).then(response => {

				let token = response.headers.get('X-Auth-Token');
				let refresh_token = response.headers.get('X-Refresh-Token');

				if( token )
					localStorage.setItem('token', token);

				if( refresh_token )
					localStorage.setItem('refresh_token', refresh_token);

				resolve();

			}).catch(reject)
		})
	},

	getMetadata(type, state, id) {

		let metadata = store.getters.metadata();
		return metadata[state][type].indexOf(id) > -1;
	},

	updateMetadata(type, state, entityId) {

		return new Promise((resolve, reject) => {

			let data = {state: state, entityId: entityId, type: type};

			Vue.http.post('user/metadata', data).then(response => {

				store.commit('metadata', data);

				cache.clear('document');
				cache.clear('resource');
				cache.clear('appendix');

				resolve();

			}).catch(reject)
		});
	},

	login(form) {

		return new Promise((resolve, reject) => {

			let from = localStorage.getItem('from');
			localStorage.clear();

			Vue.http.post('user/login', form).then(response => {

				let data = response.body.response;

				if(form.remember)
					localStorage.setItem('login', form.login);

				let token = response.headers.get('X-Auth-Token');
				let refresh_token = response.headers.get('X-Refresh-Token');

				if( token )
					localStorage.setItem('token', token);

				if( refresh_token )
					localStorage.setItem('refresh_token', refresh_token);

				store.commit('legacy', data.legacy)

				if( 'user' in data ){

					if( from )
						localStorage.setItem('from', from);

					store.commit('user', data.user);
					store.commit('membership', data.membership);

					resolve(data.user);
				}
				else if('companies' in data){

					store.commit('companies', data.companies);
					reject(response.body);
				}

			}).catch(response => {
				reject(response.body);
			})
		})
	},

	logout(){

		return new Promise((resolve, reject) => {

			Vue.http.post('user/logout').then(response => {

				let login = localStorage.getItem('login');
				localStorage.clear();

				if( login )
					localStorage.setItem('login', login);

				let prop = router.resolve({name: 'login'});

				window.location.href = prop.href;

				resolve();

			}).catch(response => {

				let login = localStorage.getItem('login');
				localStorage.clear();

				if( login )
					localStorage.setItem('login', login);

				let prop = router.resolve({name: 'login'});

				window.location.href = prop.href;

				reject(response.body);
			})
		})
	}
};
