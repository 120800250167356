<template>
	<div class="o-agencies">
		<h2 class="o-agencies__title title" v-if="interface.title">{{ interface.title }}</h2>
		<caci-company v-for="(address,index) in companiesAddresses" :key="index" :address="address" :class="{'inactive': !address.isActive}"></caci-company>
		<div class="bottom-right-inline">
			<button class="button" @click.prevent="addCompany">Ajouter une autre agence</button>
		</div>
	</div>
</template>

<script>
	import CaciCompany from "../molecule/caci-company";

	export default {
		name: "agencies",
		props:['data','interface'],
		components: {CaciCompany},
		data(){
			return{
				error: false
			}
		},
		methods: {
			addCompany() {
				this.user.contact.addresses.push({
					id: false,
					company:'',
					email:'',
					phone:'',
					isMain:'',
					isActive:true
				});
			}
		},
		computed:{
			user(){ return this.$user() },
			companiesAddresses(){
				return this.user.contact.addresses.filter(address => {
					return !address.isHome
				}).sort((a, b) => b.isActive - a.isActive)
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-agencies{
		&__company, .a-caci-company{
			background: white; padding: $space!important; border-radius: 4px; position: relative;
			&+&{ margin-top: $space-m }

			&.inactive{ opacity: 0.6 }
		}
		.a-caci-company__background, .vue-form-generator .form-group-background{ padding: 0; background: none }
	}

</style>