import BrowserDetect from '@metabolism/framework/browser'
BrowserDetect();

import Vue from 'vue'
import 'swiper/css/swiper.css';

import './app.scss'
import './components'

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

import VueHead from 'vue-head'
Vue.use(VueHead, {
    separator: '-',
    complement: 'Sitename'
});

import FunctionalCalendar from 'vue-functional-calendar';
Vue.use(FunctionalCalendar, {
    dayNames: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    shortMonthNames: ["Jan", "Fév", "Mars", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"]
});

import Rollbar from 'vue-rollbar'

if( process.env.VUE_APP_ROLLBAR ){

    Vue.use(Rollbar, {
        accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: false,
        enabled: parseInt(process.env.VUE_APP_ROLLBAR_ENABLED),
        environment: process.env.VUE_APP_ROLLBAR_ENV,
        payload: {
            client: {
                javascript: {
                    code_version: '1.0'
                }
            }
        }
    });
}

import VueResource from 'vue-resource'
Vue.use(VueResource);

import Swiper from 'vue-awesome-swiper'
Vue.use(Swiper)

import VueCollapse from 'vue2-collapse'
Vue.use(VueCollapse)

Vue.http.options.root = process.env.VUE_APP_API_ENDPOINT;

import VueFormGenerator from 'vue-form-generator'
Vue.use(VueFormGenerator);

import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.use(VueTelInput);

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

import App from './App.vue'
import router from './services/router'
import store from './services/store'

import {infiniteScroll, clickOutside} from './services/directives'
Vue.directive('infiniteScroll', infiniteScroll);
Vue.directive('clickOutside', clickOutside);

import {Mixins} from './services/mixins'
Vue.mixin(Mixins);

import Plugins from './services/plugins'
Vue.use(Plugins);

import VueGtm from '@gtm-support/vue2-gtm';
import userRepository from "@/repositories/userRepository";

if( process.env.VUE_APP_GTM) {

    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: router
    });
}

Vue.config.productionTip = Vue.config.devtools = parseInt(process.env.VUE_APP_DEVTOOLS)

document.title += ' - ' + store.getters.appVersion();

new Vue({router, store, render: h => h(App), created(){

        if(window.location.protocol+'//'+window.location.host === process.env.VUE_APP_CACI)
            this.$store.commit('type','caci');

        let self = this;
        let refreshTokenPromise = null;

        Vue.http.interceptors.push((request, next) => {

            request.credentials = true;

            let token = localStorage.getItem('token');

            if( token )
                request.headers.set('Authorization', 'Bearer '+token );

            next()
        });

        Vue.http.interceptors.push(function(request) {

            return function(response) {

                if(response.status === 509){

                    self.$error( response.body.status_text)
                    return;
                }

                if(response.status === 401 && localStorage.getItem('refresh_token') ){

                    if ( !refreshTokenPromise ) {

                        refreshTokenPromise = userRepository.refresh(localStorage.getItem('refresh_token')).then(result => {

                            refreshTokenPromise = null

                        }).catch(() => {

                            let login = localStorage.getItem('login');
                            localStorage.clear();

                            if( login )
                                localStorage.setItem('login', login);

                            let prop = router.resolve({name: 'login'});

                            window.location.href = prop.href;
                        })
                    }

                    return refreshTokenPromise.then(token => {
                        return Vue.http(request)
                    })
                }

                if( !self.$mustCatchError() ){

                    self.$catchError(true);
                    return
                }

                if(response.status === 401 ){

                    if( ['login','webinar'].indexOf(self.$route.name)===-1 )
                        self.$router.push({ name: 'login'});
                }
                else if(response.status === 503 ){

                    store.commit('config', {maintenance: { enabled: true, status : response.body.status_text } });

                    if( document.location.pathname !== '/maintenance')
                        document.location.href = '/maintenance';
                }
                else if( response.status >= 400 ){

                    if(response.body instanceof ArrayBuffer )
                        response.body = JSON.parse(new TextDecoder().decode(response.body));

                    self.$error(response.body);
                }
            }
        });

    }
}).$mount('#app');



