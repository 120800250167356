<template>

	<main class="v-page v-page--cms">
		<component :is="item.component" :interface="item.data" v-for="(item, indexComponent) in components" :key="'component'+indexComponent" v-if="pages && components"/>
		<component :is="component.type" :data="component.data" v-for="(component, index) in content" :key="index" v-if="content"/>
		<loader v-if="loading"></loader>
		<not-found v-if="error"></not-found>
	</main>

</template>

<style lang="scss">

@import '../environment';

	.v-page--cms{
		min-height: 40rem; position: relative;
		@media #{$to-tablet}{ padding-left: $space-m; padding-right: $space-m }
	}

</style>

<script>
	import pageRepository from '@/repositories/pageRepository';
	import Interface from '@/interface.yml';

	export default {
		data() {
			return {
				pages: Interface.pages,
				config: false,
				content: false,
				loading: true,
				components: false,
				error: false
			}
		},
		computed:{
			root(){
				return Vue.http.options.root
			}
		},
		mounted(){
			if(this.pages[this.$route.name])
				this.components = this.pages[this.$route.name].components;

			pageRepository.get(this.$route.params.slug).then(response => {
				this.content = response.layout;
				this.loading = false;
			},
			response=>{
				this.error = true;
				this.loading = false;
			})
		}
	}
</script>
