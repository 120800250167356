<template>
	<div class="a-change-password">
		<div class="title-big">Modifier le mot de passe</div>

		<p>Un email a été envoyé à {{ data.display_email }}</p>

		<form @submit.prevent="changePassword"  id="change-password">
			<vue-form-generator tag="div" :schema="form" :model="model" :options="formOptions" @validated="validateForm"></vue-form-generator>
			<div class="bottom-right">
				<submit text="Mettre à jour le mot de passe" :loading="loading" :success="success" text-success="Mot de passe modifié"></submit>
			</div>
		</form>
	</div>
</template>

<script>
import userRepository from '@/repositories/userRepository';
import contactRepository from '@/repositories/contactRepository';
import VueFormGenerator from 'vue-form-generator'

export default {
	name: "change-password",
	props:['data'],
	data(){
		return{
			loading: false,
			error: false,
			success: false,
			formOptions: {
				validateAfterLoad: true,
				validateAfterChanged: true,
				validateAsync: true
			},
			model:{
				token: '',
				password: '',
				confirmPassword: '',
				login: false
			},
			form:{
				fields: [
					{
						type: "input",
						inputType: "text",
						model: "token",
						label: "Code de confirmation",
						required: true,
						placeholder: "Insérez ici le code reçue par email",
						autocomplete: 'new-token',
						styleClasses: "fields-label"
					},
					{
						type: "input",
						inputType: "password",
						inputName: "new-password",
						model: "password",
						label: "Nouveau mot de passe",
						styleClasses: "fields-label",
						required: true,
						autocomplete: 'new-password'
					},
					{
						type: "input",
						inputType: "password",
						model: "confirmPassword",
						inputName: "new-confirm-password",
						label: "Confirmer le mot de passe",
						autocomplete: 'new-password',
						required: true,
						validator:
								(value, field, model) => {
									let pass = model.password;
									if (pass !== value) {
										return ["Les mots de passe ne sont pas identiques"];
									} else {
										return [];
									}
								},
						styleClasses: "fields-label"
					}
				]
			}
		}
	},
	methods:{
		validateForm(isValid, errors) {
			this.formIsValid = isValid;
		},
		close() {
      this.$emit('close')
		},
		changePassword(){
			if(!this.formIsValid)
				return;

			let self = this;
			let request = false;
			this.loading = true;

			if( this.data.type === 'user' )
				request = userRepository.resetPassword(this.data.email, this.model)
			else
				request = contactRepository.resetElearningPassword(this.data.contact.id, this.model);

			request.then(response => {

				this.loading = false;
				this.success = true;

				setTimeout(this.close, 1500);

			}).catch(e => {
				this.loading = false;
			})
		}
	},
	computed:{
		company(){
			return this.$store.state.company
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.a-change-password{
	.title{ margin-bottom: $space }
	form{ margin-top: $space }

	.form-group + .form-group{ margin-top: $space-m }
}
</style>