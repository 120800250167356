<template>
	<div :class="schema.styleClasses">
		<div v-if="platform==='mobile' || platform==='tablet'">
			<select v-model="selected" :multiple="schema.multiple" v-if="options" @change="updateSelected()">
				<option v-for="(option, index) in options" :value="option.id ? option.id : option">{{ option.name ? option.name : option }}<span v-if="schema.suffix">{{ schema.suffix }}</span></option>
			</select>
		</div>
		<div v-else-if="options.some(item => item.name)">
			<multiselect v-if="options" v-model="selected" :options="options" :multiple="schema.multiple" :placeholder="schema.placeholder" :close-on-select="false" :clear-on-select="false" :preserve-search="true" label="name" track-by="name" :searchable="false" :showLabels="false" @input="updateSelected">
				<template slot="selection" slot-scope="{ values, search, isOpen }">
					<div class="multiselect__single" v-if="values.length">{{ values[0].name ? formatString(values[0].name) : values[0] }}<span v-if="schema.suffix">{{ schema.suffix }}</span> <span v-if="values.length > 1">+{{ values.length-1 }}</span></div>
				</template>
				<template slot="option" slot-scope="props">
					{{ props.option.name ? props.option.name : props.option }}<span v-if="schema.suffix">{{ schema.suffix }}</span>
					<span class="multiselect__check" data-icon="check"></span>
				</template>
			</multiselect>
		</div>
		<div v-else>
			<multiselect v-if="options" v-model="value" :options="options" :placeholder="schema.placeholder" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :searchable="false" :showLabels="false">
				<template slot="option" slot-scope="props">
					{{ props.option.name ? props.option.name : props.option }}<span v-if="schema.suffix">{{ schema.suffix }}</span>
					<span class="multiselect__check" data-icon="check"></span>
				</template>

				<template slot="singleLabel">
					{{ value }}<span v-if="schema.suffix">{{ schema.suffix }}</span>
					</template>
				<template slot="caret">
					<span class="multiselect__caret" data-icon="chevron-bottom"></span>
				</template>
			</multiselect>
		</div>
	</div>

</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
	name: "field-multiselect-custom",
	mixins: [ abstractField ],
	data () {
		return {
			selected: [],
			platform: window.platform
		}
	},
	methods:{
		updateSelected(value) {
				if(this.platform !== 'desktop'){
					this.value= this.selected
				}
				else{
					if(Array.isArray(value))
						this.value = value.map(item => item.id)
					else
						this.value = value ? value.id : ''
				}

		},
		formatString(value){

			if(value.length > 15)
				value = value.substr(0,15) + '...'

			return value
		}
	},
	computed: {
		options() {
			let values = this.schema.values;
			if (typeof values == "function") {
				return values.apply(this, [this.model, this.schema]);
			} else {
				return values;
			}
		}
	},
	mounted() {
		let options = {};
		for( let i in this.options){
			let option = this.options[i]
			options[option.id] = option.name
		}

		if(Array.isArray(this.value)){
			for(let i in this.value)
				this.selected.push({id:this.value[i], name:options[this.value[i]]})
		} else {
			this.selected = this.options.filter(item => item.id === this.value)
		}

	}
};
</script>


<style lang="scss">
@import '../../../environment';
	.multiselect{
		$c: &;
		color: $c-primary; min-height: auto;

		&__tags{
			font-size: $font-m; border: solid 1px $c-primary; border-radius: #{$border-radius*2}; display: flex; align-items: center; min-height: auto;
			cursor: pointer; padding: 0;

			#{$c}--active &{ border-radius: #{$border-radius*2} !important;  }
		}
		&__select{ display: none }
		&__single, &__placeholder{ margin: 0; padding: 0.5rem 1.6rem; height: 100%; line-height: 1; min-height: auto; font-size: $font-xs  }
		&__single{ color: white; background: $c-primary; max-width: 20rem; white-space: nowrap; text-overflow: ellipsis; overflow: hidden }
		&__placeholder{
			color: $c-primary;
			#{$c}--active &{ display: block }
		}

		&__content-wrapper{ width: auto; box-shadow: $large-box-shadow; margin-top: $space-s; border-radius: $border-radius!important; border: 0 !important;}
		&__option{
			padding-right: $space-l; font-size: $font-xs;
			&--highlight, &--selected#{$c}__option--highlight{ background: $c-background; color: $c-primary }
			#{$c}__check{ visibility: hidden; position: absolute; right: $space-s }
			&--selected{
				background: none; color: $c-primary; font-weight: normal;
				#{$c}__check{ visibility: visible }
			}
		}
		&--above .multiselect__content-wrapper{ margin-bottom: 1rem }
		&__caret{ position: absolute; right: 8px; top: 4px; font-size: 14px }
	}

.select-document{
	.multiselect{
		width:9rem;
		&__single{ color: $c-primary; background: none }
		&__tags{ background: #fff; padding-right: $space }
		&__single,&__placeholder{  padding: 0.9rem 1rem 0.9rem 1.3rem }
		//&__placeholder{ background: $c-background-darker }
		&__tags, &__content-wrapper{ border-color: $c-background-darker }
		&__caret{ top: 8px }
	}

}
	.select-classic{
		.multiselect{
			&__single{ color: $c-primary; background: $c-background-darker }
			&__tags{ background: $c-background-darker; padding-right: $space; border-radius: $border-radius }
			&__placeholder,&__single{ padding: $space-s 1.5rem;  }
			&__tags, &__content-wrapper{ border-color: $c-background-darker }
		}

}
</style>