<template>
	<div class="o-footer">
		<nav class="o-footer__nav" v-if="config && $route.name !== 'login' && $route.name !== 'order' && config.menu.footer">
			<ul class="container">
				<li v-for="(item,index) in config.menu.footer" :key="index">
					<a :href="item.link" target="_blank" rel="noopener" v-if="item.type === 'custom'">{{ item.title }}</a>
					<a @click="preview(item.type, item.link)" v-else-if="item.type === 'document'">{{ item.title }}</a>
					<router-link :to="'/edito/'+getPage(item.link)" v-else-if="item.type === 'edito'">{{ item.title }}</router-link>
				</li>
				<li>
					<router-link to="/request">Contact</router-link>
				</li>
			</ul>
		</nav>
		<div class="o-footer__infos">
			<div class="o-footer__logo">
				<img src="/img/logo-snpi.svg" alt="SNPI" />
				<div class="title">
					<span>Syndicat national</span>
					<span>des professionnels immobiliers</span>
				</div>
			</div>
			<div class="o-footer__socials" v-if="config">
				<a :href="config.option.facebook" data-icon="facebook" title="Facebook" target="_blank" rel="noopener" v-if="config.option.facebook"></a>
				<a :href="config.option.twitter" data-icon="twitter" title="Twitter" target="_blank" rel="noopener" v-if="config.option.twitter"></a>
				<a :href="config.option.linkedin" data-icon="linkedin" title="LinkedIn" target="_blank" rel="noopener" v-if="config.option.linkedin"></a>
				<a :href="config.option.youtube" data-icon="youtube" title="Youtube" target="_blank" rel="noopener" v-if="config.option.youtube"></a>
			</div>
		</div>
	</div>
</template>

<script>

  export default {
		name: "footer-component",
		computed:{
			config(){ return this.$config() }
		},
    methods:{
      preview(type, id){
        this.$popin('preview', {type:type, id:id});
      }
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-footer{
		margin-top: auto;
		&__nav{
			background: white; font-family: 'Optima', Helvetica, serif; color: $c-primary; text-transform: uppercase;
			font-size: $font-m; padding: $space;
			ul{
				@media #{$from-small}{
					display: flex; align-items: center; justify-content: center;
					& > * + *{ margin-left: 7vw }
				}
				@media #{$to-tablet}{
					& > * + *{ margin-top: $space-s }
				}
			}
		}
		.title{
			color: white; font-size: $font-l; text-align: left;
			span{ display: block }
		}
		&__infos{
			padding: $space $space-m; background: $c-primary; color: white;
			@media #{$from-small}{display: flex;  align-items: center; }
		 }
		&__socials{
			display: flex; align-items: center; margin-left: auto; font-size: 3rem;
			& > * +*{ margin-left: $space-m }
			@media #{$to-tablet}{
				margin-top: $space-m; justify-content: center;
			}
		}
		&__logo{
			display: flex; align-items: center;
			img{ width: 6.5rem; margin-right: $half-space }
		}
	}
</style>