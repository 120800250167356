<template>
	<div class="o-next-sessions block" v-if="sessions && sessions.length > 0">
		<h2 class="title">{{ interface.title }}</h2>

		<div class="o-next-sessions__container">
			<div class="o-next-sessions__block">
				<div class="o-next-sessions__list" v-infinite-scroll="loadMore">
					<div v-for="(session, index) in sessions" :key="index" class="o-next-sessions__item">
						<div class="o-next-sessions__infos">
							<p class="o-next-sessions__date">
								<strong>{{ formatShortDateWithDay(session.startAt) }} </strong>
							</p>
							<p v-if="session.schedule">{{ session.schedule }} (heure de Paris)</p>
							<div data-icon="pin" class="icon-flex" v-if="session.city">{{ session.city }}</div>
						</div>
						<router-link :to="{ name: 'formation', params: { id: session.id }}" class="button button--white" >Consulter</router-link>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";

export default {
	name: "next-sessions",
	props:['data', 'interface'],
	data(){
		return{
			params:{
				limit : 5,
				offset: 0,
				formation: false
			},
			count: -1,
			sessions: false,
			formationCourse: false
		}
	},
	methods:{
		loadMore(){

			if(this.sessions.length >= this.count || !this.news.length)
				return false;

			this.query(this.params.offset + this.params.limit)

		},
		query(offset){

			this.params.offset = offset;

			formationCourseRepository.list(this.params).then(response => {
				this.count = response.count;
				this.sessions = response.items.filter(session => session.id !== parseInt(this.$route.params.id) );
			});

		}
	},
	watch:{
		data(){
			this.params.formation = this.data.formation.id;
			this.query(0);
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';

.o-next-sessions{
	display: flex;
	flex-direction: column;
	flex: 1;

	.block &__container {
		position: relative; padding: 0; background: none;
		@media #{$from-small}{
			overflow: auto; -webkit-overflow-scrolling: touch;
			min-height: 35rem;
			flex: 1;
		}
	}

	&__block{

		@media #{$from-small}{
			position: overlay();
			flex-direction: column; display: flex; overflow: hidden;
		}
	}

	&__list{ overflow: hidden; overflow-y: auto; -webkit-overflow-scrolling: touch; background: white; padding: $space-m $space $space; border-radius: $border-radius }

	&__item{
		display: flex; align-items: center; color: $c-primary; padding: $space-s 0;
		& + &{ border-top: solid 1px $c-border }
	}
	.button{ margin-left: auto }
	&__infos{
		& > * + *{ margin-top: $space-xs }
	}
	&__date{ font-size: $font-l }

}

</style>