<template>
	<div class="m-caci-register">

		<div class="m-caci-register__logout" data-icon_after="log-out" @click="logOut" title="Déconnexion" v-if="data.registration||!data.type"></div>

		<h2 class="title-big" v-if="data.registration||!data.type">Finaliser mon inscription</h2>
		<h2 class="title-big" v-else>Souscrire à une autre assurance</h2>

		<div class="m-caci-register__steps" :class="'m-caci-register__steps--'+(activeStep-1)" v-if="(data.registration||!data.type)&&!loading">
			<div class="m-caci-register__step" v-for="(step,index) in steps" :class="{'m-caci-register__step--full': index+1 < activeStep }">
				<div class="m-caci-register__number">
					<span class="step" v-if="activeStep === index+1">{{activeStep}}</span>
					<radial :current="activeSubStep" :total="total[activeStep]" mod="nolabel" v-if="index+1 === activeStep"></radial>
				</div>
				<div>{{ step }}</div>
			</div>
		</div>

		<loader static="1" v-if="loading"></loader>
		<div v-else>
			<div v-if="activeStep === 5" class="m-caci-register__end">
				<div class="title-big">FÉLICITATIONS !</div>
				<p>
					<b>Votre adhésion a été validée avec succès.
					Vous allez être redirigé vers votre compte dans quelques instants...</b>
				</p>
				<div class="center">
					<loader static="1"></loader>
				</div>
			</div>
			<caci-register-agencies @nextStep="nextStep" @updateSubStep="updateSubStep" v-else-if="activeStep === 2" :subStep="activeSubStep"></caci-register-agencies>
			<caci-register-payment @nextStep="nextStep" @updateSubStep="updateSubStep" v-else-if="activeStep === 3" :subStep="activeSubStep"></caci-register-payment>
			<caci-register-sign @nextStep="nextStep" @updateSubStep="updateSubStep" v-else-if="activeStep === 4" :subStep="activeSubStep"></caci-register-sign>
			<caci-register-infos @nextStep="nextStep" @updateSubStep="updateSubStep" v-else :subStep="activeSubStep"></caci-register-infos>
		</div>
	</div>
</template>

<script>

	import contactRepository from "@/repositories/contactRepository";
	import appendixRepository from "@/repositories/appendixRepository";
  import router from "@/services/router";
	import userRepository from "@/repositories/userRepository";

	export default {
		name: "caci-register",
		props:['data'],
		methods:{
			logOut(){
				userRepository.logout();
			},
			nextStep(){
				this.activeSubStep = 1;
				this.activeStep++
				this.$scrollTop();

				if( this.activeStep === 5 )
					this.refresh()
			},
			updateSubStep(subStep){
				this.activeSubStep = subStep;
				this.$scrollTop();
			},
			refresh(){
				contactRepository.refresh().then(response=>{
					appendixRepository.sync(false, true).then(response=>{
            let prop = this.$router.resolve({name: 'home'});
            window.location.href = prop.href;
					})
				})
			}
		},
		data(){
			return{
				steps: ['Informations','Agences','Assurances','Bulletin éléctronique','Finalisation'],
				loading: false,
				activeStep: 1,
				activeSubStep: 1,
				total:{
					1: 4,
					2: 1,
					3: 4,
					4: 4,
					5: 1
				}
			}
		},
		mounted(){

			if( this.data.registration ){

				let registration = this.data.registration;

				if( registration.validAsseris ){
					this.activeStep = 4
					this.activeSubStep = 3
				}
				else if( registration.validPayment ){
					this.activeStep = 4
				}
				else if( registration.payment ){
					this.activeStep = 3
					this.activeSubStep =  window.location.search === '?continue' ? 4 : 3
				}
				else if( registration.agencies )
					this.activeStep = 3
				else if( registration.information )
					this.activeStep = 2
			}
			else if( this.data.isCommercialAgent){

				this.activeStep = 3
				this.activeSubStep = window.location.search === '?continue' ? 4 : 1
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';

	.m-caci-register{
		$c: &;

		max-width: 100%; width: 75vw; text-align: left;
    @media #{$to-phone}{
      width: 100%;
    }

		.field-hint-text{ margin-bottom: 0 }

		&__steps{
			display: flex; justify-content: space-between; position:relative; font-size: $font-xs; color: $c-primary;
			&:before,&:after{ height: 4px; content: ''; position: absolute; left: 0;  top: $half-space; margin-top: -2px }
			&:before{ right: 0; background: $c-background-lighter }
			&:after{ background: $c-primary; width: 0; transition: allow(width) }

			& + *{ margin-top: $space }

			&--1:after{ width: 27% }
			&--2:after{ width: 45% }
			&--3:after{ width: 70% }
			&--4:after{ width: 99% }
		}

		&__step{
			&:first-of-type{
				#{$c}__number{ margin-left: 0 }
			}
			&:last-of-type{
				#{$c}__number{ margin-right: 0 }
			}

			&--half{
				#{$c}__number{ border-left: solid 1px $c-primary }
			}
			&--full{
				#{$c}__number{ background: $c-primary }
			}
		}

		&__number{
			width: $space; height: $space; border-radius: 50%; background: $c-background; display: flex; transition: allow(background);
			align-items: center; justify-content: center; font-size: $font-xs; position:relative; z-index: 1; margin: 0 auto $space-s;
      &+div{
        @media #{$to-phone}{ display: none }
      }
			.a-radial{
				position:overlay(); transform: rotate(-97deg);
				.shadow{ border-color: $c-background }
				.pie .half-circle{ border-width: 2px }
			}
		}

		.field-declaration{ font-weight: bold; color: $c-primary; font-size: inherit }

		&__summary {
			color: $c-primary;
			& > * + * {margin-top: $space-m }
		}

		&__background{
			background: $c-background; padding: $space-m; border-radius: $border-radius;
			h3+&{ margin-top:$space-m }
			& > * + *{ margin-top: $space-m }
		}

		&__modify{ float: right }

		&__price{
			display: flex;
			& > *:nth-of-type(2){ margin-left: auto; text-align: right }
			& > *{
				& > * + *{ margin-top: 0.5rem }
			}
		}

		&__end{
			text-align: center; padding: $space; color: $c-primary
		}

		&__countdown{ margin-top: 1rem }

		&__logout{
			position:absolute; right: $space; top: $space-m; margin: 0; font-size: $font-xl; cursor: pointer; color: $c-primary;
			@media #{$to-tablet}{ right: 1rem; top: 1rem }
		}
	}
</style>