<template>

  <div :class="'m-trainings-excerpt '+'m-trainings-excerpt--'+formation.format">

    <div class="m-trainings-excerpt__icon m-trainings-excerpt__icon--small" v-if="formation.format === 'e-learning'">
      <img src="/img/icons/training-elearning.svg"/>
    </div>
    <div class="m-trainings-excerpt__icon m-trainings-excerpt__icon--small" v-else-if="formation.format === 'webinar'">
      <img src="/img/icons/training-webinar.svg"/>
      <time class="m-trainings-excerpt__date-short" v-html="formatShortDate(formation.startAt)"></time>
    </div>
    <div class="m-trainings-excerpt__icon" v-else>
      <img src="/img/icons/training-instructor.svg"/>
      <time class="m-trainings-excerpt__date-short" v-html="formatShortDate(formation.startAt)"></time>
    </div>

    <div>
      <div class="m-trainings-excerpt__duration">
        <span class="m-trainings-excerpt__city">
          <span v-if="formation.location">{{ formation.city }}</span>
          <span v-else-if="formation.format == 'webinar'">Webinaire</span>
          <span v-else>En ligne</span>
        </span>,
        formation de {{ formation.formation.duration.hours }}h
        <span v-if="formation.formation.duration.hoursEthics"> dont {{ formation.formation.duration.hoursEthics }}h de déontologie</span>
      </div>
      <router-link :to="{ name: 'formation', params: { id: formation.id }}" tag="h2" class="m-trainings-excerpt__title" :title="formation.formation.title">
        {{ formation.formation.title.substr(0,120) }}<span v-if="formation.formation.title.length > 120">...</span>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'trainings-excerpt',
  props:['formation']
}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';
.m-trainings-excerpt{

  display: flex; align-items: center; margin-top: $half-space; padding-right: math.div($space-s,2);

  &__icon{
    position:relative; color: $c-secondary; font-weight: bold; text-align: center; margin-right: $space-s;
    line-height: 1; width: 3.5rem;
    &:before{ color: $c-primary; font-size: 4.5rem }
    .month{ text-transform: uppercase; font-size: 8px; display: block }
    & + *{ flex: 1 }
  }

  &__date-short{
    position: overlay(); display: flex; flex-direction: column; align-items: center; justify-content: center;
    margin-top: .8rem; font-size: $font-s; line-height: 0.9;
  }

  &--webinar &{
    &__date-short{ margin-top: -0.4rem }
  }

  &__duration{ font-size: $font-xs }
  &__title{ color: $c-primary; line-height: 1; cursor: pointer; margin-right: $space-m; font-size: $font-s }
  &__city{ font-size: $font-xs; margin-left: auto }
}
</style>
