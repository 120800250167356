<template>
	<div class="m-signature-add">
		<h2 class="title-big">Signer un nouveau document</h2>

		<div class="error" v-if="error">Une erreur est survenue. Veuillez réessayer ultérieument</div>

		<form @submit.prevent="create" ref="form" id="create-signature">

			<vue-form-generator tag="div" :schema="form" :options="formOptions" :model="signature" class="m-signature-add__form" @validated="onValidated" v-if="load"></vue-form-generator>

			<div class="additional-signataires" v-for="(signataire, key, index) in additional_signataires" :key="key">
				<div class="additional-signataires__container">
          <div class="additional-signataires__title">Signataire n°{{ index+1 }}</div>
					<vue-form-generator tag="div" class="additional-signataire" :schema="additionalSignataire(key)" :model="signataire" :options="formOptions"></vue-form-generator>
					<a class="link" @click="remove(key)" v-if="Object.keys(additional_signataires).length>1">Supprimer le signataire</a>
				</div>
			</div>

			<div class="bottom-right" v-if="Object.keys(additional_signataires).length<16 && Object.keys(additional_signataires).length<left-1">
				<button type="button" class="button button--small" @click="add" data-icon_after="plus">Ajouter un signataire</button>
			</div>
      <div class="bottom-right" v-else>Vous ne pouvez pas ajouter plus de signataires. <span v-if="Object.keys(additional_signataires).length>=left-1">Veuillez vérifier le nombre de signatures restantes</span></div>

			<div class="error bottom-center" v-if="error && error.length > 2">{{ error }}</div>

			<div class="warning">
				<b class="secondary">Attention : </b><span class="primary"><b>Une fois la signature validée, plus aucune modification ne pourra être faite.</b><br/> Nous vous invitons à toujours bien vérifier l’exactitude des coordonnées des signataires renseignés avant de valider la collecte et envoyer les invitations à signer.<br/>Si l’erreur est découverte, veuillez nous solliciter avant toute autre action.</span>
			</div>

      <div class="vue-form-generator">
        <div class="form-group valid primary field-checkbox-custom field-checkbox--cgu required ">
          <label for="field_faculte_cgu"><span>Je reconnais avoir pris connaissance des <a href="https://help.universign.com/hc/fr/articles/360000553249-Les-principes-de-validation-des-identit%C3%A9s-dans-Universign" target="_blank">principes de validité d'Universign</a></span></label>
          <div class="field-wrap">
            <div><input type="checkbox" id="field_faculte_cgu" name="field_faculte_cgu" class="form-control" required><span data-icon="check" class="checkbox-checkmark"></span></div>
          </div>
        </div>
      </div>

      <div class="bottom-right">

        <submit class="button button--secondary" text="Valider la signature" :loading="loading" :success="sent" text-success="Validé" :disabled="(!validAnnexe || !validContract)"></submit>
			</div>
		</form>
	</div>
</template>

<script>

	import signatureRepository from '@/repositories/signatureRepository';

	export default {
		name: "signature-add",
		props:['data'],
		data(){

			let self = this;

			return{
				loading: false,
        left: 0,
				load: false,
				sent: false,
				error: false,
				index: 1,
				isValid: false,
				additional_signataires: {},
				contractLoading: false,
				validContract: true,
				annexeLoading: false,
				validAnnexe: true,
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
        form:{
          groups: [
            {
              fields: [
                {
                  type: "input",
	                inputType: "hidden",
                  inputName: "field_univ_type_sign",
                  model: "field_univ_type_sign",
                  value: "email"
                }
              ]
            },
            {
              legend: "Document à signer / faire signer",
              styleClasses: "form-group-title"
            },
            {
              legend: "Pensez a bien compresser vos documents pour réduire leur poids",
              styleClasses: "form-group-tips"
            },
            {
              styleClasses: ["form-group-background","form-group-columns"],
              fields: [
                {
                  type: "select",
                  inputName: "field_type_doc",
                  model: "field_type_doc",
                  label: "Type de document",
                  styleClasses: "fields-label",
                  required: true,
                  values: [
                    { id: "mandat-exclu-vente", name:"Mandat exclusif de vente", group: "Vente"},
                    { id: "mandat-simple-vente", name:"Mandat simple de vente (sans exclusivité)", group: "Vente"},
                    { id: "mandat-recherche-negociation", name:"Mandat de recherche et de négociation", group: "Vente"},
                    { id: "offre-achat", name:"Offre d'achat", group: "Vente"},
                    { id: "compromis-vente", name:"Compromis de vente", group: "Vente"},
                    { id: "mandat-location", name:"Mandat de location", group: "Location"},
                    { id: "mandat-exclusif-locatif", name:"Mandat exclusif de location", group: "Location"},
                    { id: "mandat-gestion-locative", name:"Mandat de gestion locative", group: "Location"},
                    { id: "contrat-location-vide", name:"Contrat de location (locaux vacants non meublés)", group: "Location"},
                    { id: "contrat-location-meuble", name:"Contrat de location (locaux meublés)", group: "Location"},
                    { id: "contrat-location-vide-sans-bailleur", name:"Contrat de location par l'agence (locaux vacants non meublés)", group: "Location"},
                    { id: "contrat-location-meuble-sans-bailleur", name:"Contrat de location par l'agence (locaux meublés)", group: "Location"},
                    { id: "contrat-syndic-copropriete", name:"Contrat de syndic de copropriété", group: "Syndic de copropriété"},
                    { id: "bon-visite", name:"Bon de visite", group: "Divers"},
                    { id: "avenant-mandat", name:"Avenant au mandat", group: "Divers"},
                    { id: "autre", name:"Je choisis un autre type de document", group: "Autres"}
                  ]
                },
                {
                  type: "checkbox-custom",
                  inputName: "field_faculte_retractation",
                  model: "field_faculte_retractation",
                  label: "Avec faculté de rétractation",
                  visible(){
                    return ['mandat-exclu-vente','mandat-simple-vente','mandat-recherche-negociation','mandat-location','mandat-gestion-locative'].indexOf(self.signature.field_type_doc)  !== -1
                  },
                  styleClasses: "primary",
                },
                {
                  type: "upload",
                  required: true,
                  inputName: "field_univ_file_upload",
                  model: "field_univ_file_upload",
                  styleClasses: ["fields-label","field--half"],
                  label: "Contrat (1 fichier PDF < 4Mb)",
                  accept:'application/pdf',
                  onChanged(model, schema, event, instance){
                    self.checkType(event.target, schema.accept)
                    self.checkSize(event.target, 4)
                    self.checkValidity(event.target, 'contrat')
                  }
                },
                {
                  type: "upload",
                  inputName: "field_univ_annexe_upload",
                  model: "field_univ_annexe_upload",
                  styleClasses: ["fields-label","field--half"],
                  label: "Annexe (1 fichier PDF < 6Mb)",
                  accept:'application/pdf',
                  onChanged(model, schema, event, instance){
                    self.checkType(event.target, schema.accept)
                    self.checkSize(event.target, 6)
                    self.checkValidity(event.target, 'annexe')
                  }
                }
              ]
            },
            {
              legend: "Professionnel de l'immobilier",
              styleClasses: "form-group-title"
            },
            {
              styleClasses:  ["form-group-background","form-group-columns","form-group-columns-no-margin"],
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  inputName: "field_univ_nom[0]",
	                maxlength: 60,
                  model: "field_univ_nom",
                  styleClasses: ["fields-label","field--half"],
                  label: "Nom",
                  required: true
                },
                {
                  type: "input",
                  inputType: "text",
                  inputName: "field_univ_prenom[0]",
	                maxlength: 60,
                  model: "field_univ_prenom",
                  styleClasses: ["fields-label","field--half"],
                  label: "Prénom",
                  required: true
                },
                {
                  type: "tel-custom",
                  inputName: "field_univ_tel[0]",
                  model: "field_univ_tel",
                  styleClasses: ["fields-label","field--half"],
                  label: "Téléphone portable",
                  required: true
                },
                {
	                type: "calendar-custom",
                  inputName: "field_univ_date_naiss[0]",
                  model: "field_univ_date_naiss",
                  styleClasses: ["fields-label","field--half"],
                  label: "Date de naissance",
                  required: true,
                  min: '1920-01-01',
                  max: this.getDateFromYear(18, true),
                  default : this.getDateFromYear(18, false)
                },
	              {
		              type: "input",
		              inputType: "email",
		              inputName: "field_univ_email[0]",
		              maxlength: 60,
		              model: "field_univ_email",
		              styleClasses: ["fields-label"],
		              label: "Email",
		              required: true
	              },
                {
                  type: "input",
                  inputType: "hidden",
                  inputName: "field_pi[0]",
                  value: 'none'
                }
              ]
            }
          ]
        },
				signature:{
					field_univ_type_sign: 'email',
					field_type_doc: 'mandat-exclu-vente',
					field_univ_nom: '',
					field_univ_prenom: '',
					field_univ_tel: '',
					field_univ_email: '',
					field_univ_date_naiss: '',
					field_cgu: ''
				}
			}
		},
		methods: {
			checkValidity(field,type){
				if(field.value !== ''){
					let message = "Votre document " + type + " n'est pas conforme, veuillez télécharger la dernière version dans la rubrique des <a class='link' href='/documents/imprimes-a-telecharger' target='_blank'>imprimés à télécharger</a>";

					signatureRepository.checkFile(field.files[0]).then(response => {
						if(type === 'contrat')
							this.loadingContract = true
						else
							this.loadingAnnexe = true

						if(response.body.response !== 'ok'){
							this.$error(message)
							field.value = '';
						}

						if(type === 'contrat')
							this.loadingContract = false
						else
							this.loadingAnnexe = false
					}, e => {
						this.$error(message)
						field.value = ''
						if(type === 'contrat')
							this.loadingContract = false
						else
							this.loadingAnnexe = false
					})
				}
			},
			checkSize(field, size){

				if (field.files.length > 0) {

					for (let i = 0; i <= field.files.length - 1; i++) {

						const fsize = field.files.item(i).size;
						const file = Math.round((fsize / 1000));
						// The size of the file.
						if (file >= size*1000) {
							alert("Le fichier est trop volumineux, merci de choisir un fichier de moins de "+size+"Mb");
							field.value = ''
						}
					}
				}
			},
			checkType(field, accept){

				if (field.files.length > 0) {

					for (let i = 0; i <= field.files.length - 1; i++) {

						const ftype = field.files.item(i).type;
						accept = accept.split(',');
						// The size of the file.
						if ( accept.indexOf(ftype) === -1 && ftype !== '' ) {
							alert("Le fichier n'est pas correct, merci de choisir un fichier de type "+accept.join(', '));
							field.value = ''
						}
					}
				}
			},
			add() {

				this.additional_signataires[this.index] = {
					index:this.index,
					field_pi:'none',
					field_univ_tel:'',
					field_univ_nom: '',
					field_univ_prenom: '',
					field_univ_date_naiss:''
				};

				this.index++;

				this.$forceUpdate();
			},
			additionalSignataire(index) {

				let self = this;

				return {
					groups:[
						{
							styleClasses: ["form-group-background","form-group-columns","form-group-columns-no-margin"],
							fields: [
								{
									type: "input",
									inputType: "text",
									inputName: "field_univ_nom["+index+"]",
									maxlength: 60,
									model: "field_univ_nom",
									styleClasses: ["fields-label","hint-small","field--half"],
									label: "Nom",
									required: true,
									hint: "Nous vous conseillons de renseigner le nom de jeune fille plutôt que le nom marital"
								},
								{
									type: "input",
									inputType: "text",
									inputName: "field_univ_prenom["+index+"]",
									maxlength: 60,
									model: "field_univ_prenom",
									styleClasses: ["fields-label","field--half"],
									label: "Prénom",
									required: true
								},
								{
									type: "tel-custom",
									inputName: "field_univ_tel["+index+"]",
									model: "field_univ_tel",
									styleClasses: ["fields-label","hint-small","field--half"],
									label: "Téléphone portable",
									required: true,
									hint: "Chaque signataire doit disposer de son propre numéro de téléphone portable, à défaut de quoi la signature sera juridiquement invalide."
								},
								{
									type: "calendar-custom",
									inputName: "field_univ_date_naiss["+index+"]",
									model: "field_univ_date_naiss",
									styleClasses: ["fields-label","field--half"],
									label: "Date de naissance",
                  min: '1920-01-01',
                  max: this.getDateFromYear(18, true),
                  default : this.getDateFromYear(18, false)
								},
								{
									type: "input",
									inputType: "email",
									inputName: "field_univ_email["+index+"]",
									maxlength: 60,
									model: "field_univ_email",
									styleClasses: ["fields-label","hint-small"],
									label: "Email",
									required: true,
									hint: "Chaque signataire doit disposer de son propre email, à défaut de quoi la signature sera juridiquement invalide."
								}
							]
						}
					]
				}
			},
			remove(key) {
				delete this.additional_signataires[key];
				this.$forceUpdate();
			},
			close(){
				this.$update()
				this.$emit('close')
			},
			checkDuplicates(){
				function hasDuplicates(arr)
				{
					return new Set(arr).size !== arr.length;
				}

				let phones = Object.values(this.additional_signataires).map(item => item.field_univ_tel);
				phones.push(this.signature.field_univ_tel);

				let emails = Object.values(this.additional_signataires).map(item => item.field_univ_email);
				emails.push(this.signature.field_univ_email);
				return hasDuplicates(phones) || hasDuplicates(emails)
			},
			create() {

				if(!this.isValid)
					return

					if(this.checkDuplicates()){
						this.error = 'Veuillez vérifier que les adresses emails et les numéros de téléphone des signataires soient tous différents'
						return;
					} else {
						this.error = false
					}

				this.loading = true;

				let formData = new FormData( this.$refs.form );

				signatureRepository.create(formData).then(response => {

					this.loading = false;
					this.sent = true;

					setTimeout(this.close, 1500);

				}, e => {
					this.loading = false;
				})
			},
			onValidated(isValid, errors) {
				this.isValid = isValid
			},
      getStock(){

        signatureRepository.getStock().then(count=>{
          this.left = count;
          this.loading = false
        })
      },
			assignModel(){
				this.signature.field_univ_nom = this.data.signatures[0].lastname;
				this.signature.field_univ_prenom = this.data.signatures[0].firstname;
				this.signature.field_univ_date_naiss = this.data.signatures[0].birthdate;
				this.signature.field_univ_email = this.data.signatures[0].email;
				this.signature.field_univ_tel = this.data.signatures[0].phone;
				this.signature.field_univ_type_sign = this.data.mode_contrat;
				this.signature.field_type_doc = this.data.type_contrat_id;

				for(let i=1;i<this.data.signatures.length; i++){
					this.add();
					this.additional_signataires[i].field_univ_nom = this.data.signatures[i].lastname;
					this.additional_signataires[i].field_univ_prenom = this.data.signatures[i].firstname;
					this.additional_signataires[i].field_univ_date_naiss = this.data.signatures[i].birthdate;
					this.additional_signataires[i].field_univ_email = this.data.signatures[i].email;
					this.additional_signataires[i].field_univ_tel = this.data.signatures[i].phone;
				}
				this.load = true;
			}
		},
		computed:{
			user(){ return this.$user() }
		},
		mounted(){
      this.$update(this.getStock);
      this.getStock();

			if(this.data){
				this.assignModel();
			} else {
				this.add();
				this.load = true;
				this.signature.field_univ_nom = this.user.contact.lastname;
				this.signature.field_univ_prenom = this.user.contact.firstname;
				this.signature.field_univ_date_naiss = this.user.contact.birthday;
				this.signature.field_univ_email = this.user.contact.email;
				this.signature.field_univ_tel = this.user.contact.phone;
			}
		}
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';

  .field-checkbox--cgu{
    margin-top: 2rem; font-size: inherit!important;
    a{ text-decoration: underline }
  }

	.m-signature-add{
		text-align: left;

		.title-big + *, &__form > * + *, .additional-signataires{ margin-top: $space }
    .warning{ margin-top: $space-m; border-top: 1px solid $c-background-darker; padding-top: $space-m }

			.radio-list-buttons .radio-list{
				display: flex; font-size: $font-xs;

				@media #{$to-tablet}{ flex-direction: column }

				input{ position: absolute; opacity: 0; appearance: radio }
				label {
					display: flex; justify-content: center; align-items: center; text-transform: uppercase;flex: 1;
					font-weight: bold; color: $c-primary; transition: allow(color background); cursor: pointer; text-align: center;
					span{ border: solid 1px $c-primary; padding: math.div($space-m,2); border-radius: $border-radius; display: inline-block; width: 100% }
					& + *{
						margin-left: $space;
						@media #{$to-tablet}{ margin-left: 0; margin-top: $space-m }
					}

					input:checked+span{ background: $c-primary; color: white }
				}
			}

		input[type=date],input[type=tel]{ width: 100% }

		.hint-before{
			display: flex; flex-direction: column;
			.hint{
				background: white; padding: $space-m; border-radius: $border-radius; color: $c-primary; margin-bottom: $space-s;
				font-size: $font-xs; line-height: 1.3;
				p + p{ margin-top: $space-m }
			}
			.field-wrap{ order: 1 }
		}
		.hint-small .hint{ font-size: $font-xs; margin-top: 0.5rem; }
		.additional-signataires__title{
      font-weight: bold; color: $c-primary; margin-bottom: 1rem;
    }
		.additional-signataires__container{
			position: relative;
			.form-group-background{ padding-bottom: $space-l }
			.link{ position: absolute; right: 2rem; bottom: 2rem }
		}
	}

</style>