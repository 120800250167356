<template>
	<router-link tag="div" :to="{ name: 'formation', params: { id: data.id }}" :class="'m-training-item'+(type === 'subscribed'?' m-training-item--subscribed':'')+' m-training-item--'+data.format">
		<div class="m-training-item__col">
      <div class="m-training-item__date m-training-item__date--small" v-if="data.format === 'e-learning'">
        <img src="/img/icons/training-elearning.svg"/>
      </div>
      <div class="m-training-item__date m-training-item__date--small" v-else-if="data.format === 'webinar'">
        <img src="/img/icons/training-webinar.svg"/>
        <time class="m-training-item__date-short" v-html="formatShortDate(data.startAt)"></time>
      </div>
      <div class="m-training-item__date" v-else>
        <img src="/img/icons/training-instructor.svg"/>
        <time class="m-training-item__date-short" v-html="formatShortDate(data.startAt)"></time>
      </div>
		</div>
		<div class="m-training-item__infos">
			<h3 class="m-training-item__title">
				<b>{{data.formation.title}}</b>
			</h3>
			<div class="m-training-item__content">
				<div class="m-training-item__details">
					<span data-icon="timer" class="icon-flex">
            {{data.formation.duration.hours}}h {{ data.startAt !== data.endAt && data.formation.duration.days > 1 ? 'sur '+data.formation.duration.days+' jours': ''}}
          </span>
          <span data-icon="pin" v-if="data.city && data.format === 'instructor-led'" class="icon-flex">{{data.city}}</span>
          <span data-icon="people" v-if="(data.format === 'instructor-led' || data.format === 'webinar') && upcoming" class="icon-flex">
						<span class="desktop right-space">Il reste</span>
						<span class="right-space">{{Math.max(0, data.remainingPlaces)}}</span>
						<span v-if="data.remainingPlaces>1">places</span><span v-else>place</span>
					</span>
					<span data-icon="screen" v-if="data.format === 'e-learning' || data.format === 'webinar'" class="icon-flex">
						<span v-if="data.format === 'webinar'">Webinaire</span>
						<span v-else class="capitalize">{{ data.format }}</span>
					</span>

				</div>
				<div class="m-training-item__participants"  v-if="type === 'subscribed' && data.participants && user.isLegalRepresentative">
					<div class="m-training-item__participants-label">Collaborateurs inscrits</div>
					<span v-for="(participant,index) in data.participants.slice(0,5)" :key="index" :class="{'is-present':participant.present}">
						{{ participant.firstname }} {{ participant.lastname }}<span v-if="index+1 < data.participants.length && index<4">, </span>
					</span>
					<span v-if="data.participants.length>5"> et {{data.participants.length-5}} autre{{data.participants.length-5>1?'s':''}}.</span>
					<transition name="fade">
						<div class="m-training-item__participants-hover" v-if="showParticipants && data.participants.length>5">
							<span v-for="(participant,index) in data.participants" :key="index" :class="{'is-present':participant.present}">
								{{ participant.firstname }} {{ participant.lastname }}<span v-if="index+1 < data.participants.length">, </span>
							</span>
						</div>
					</transition>
				</div>
			</div>
			<div class="m-training-item__receipt" v-if="type === 'subscribed' && data.participants && hasRole(user,'company')">
				<div class="link" @click.prevent="openPopin">Voir le récapitulatif d'achat</div>
			</div>

		</div>
		<div class="m-training-item__training-col m-training-item__numbers primary">
			<!--<div class="m-training-item__dropdown dots" data-icon="dots" v-if="type === 'subscribed'">
				<dropdown>
					<a class="dots__item">
						Télécharger la facture
					</a>
					<a class="dots__item">
						Télécharger l'avoir
					</a>
					<a class="dots__item secondary" v-if="upcoming" @click="cancel">
						Annuler la formation
					</a>
				</dropdown>
			</div>-->
			<div class="m-training-item__distance">
				<div v-if="data.format === 'instructor-led' && data.distance">à
					<span v-if="data.distance > 1">{{data.distance}}km</span>
					<span v-else>{{data.distance*1000}}m</span>
				</div>
			</div>
			<div v-if="type === 'subscribed'">
				<span v-if="data.format === 'e-learning'">
          <span v-if="data.completed">Terminée</span>
          <span v-else-if="user.isLegalRepresentative">En cours</span>
          <span v-else-if="elearning_enabled">
            <router-link :to="{ name: 'foad', params: { id: data.id }}" class="button button--small" :class="{'button--secondary':data.participants[0].progress}">
              <span v-if="data.participants[0].progress">Reprendre la formation</span>
              <span v-else>Commencer la formation</span>
            </router-link>
          </span>
				</span>
				<span v-else>
					<span v-if="upcoming">A venir</span>
					<span v-else>Terminée</span>
				</span>
			</div>
			<div class="m-training-item__price" v-else-if="type !== 'subscribed'">
				<span v-if="data.formation.price">{{formatPrice(data.formation.price)}} HT pp.</span>
				<span v-else>Gratuit</span>
			</div>
		</div>
	</router-link>
</template>

<script>
	import formationCourseRepository from "@/repositories/formationCourseRepository";

	export default {
		name: "training-item",
		props:['data','type'],
		data(){
			return{
				showParticipants: false,
				elearning_enabled: parseInt(process.env.VUE_APP_ELEARNING)
			}
		},
		methods:{
			openPopin(){
				this.$popin('training-receipt', this.data.id)
			},
			cancel(){
				formationCourseRepository.cancel(this.data.id).then(response=> {

				})
			}
		},
    computed:{
      user(){ return this.$user() },
      current_time(){ return this.$currentTime() },
      upcoming(){ return this.current_time < this.data.startAt },
    }
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';
	.m-training-item{
		width: 100%; background: white; border-radius: $border-radius; padding: $space-m; position: relative; cursor: pointer; box-shadow: $subtle-box-shadow;
		transition: allow(box-shadow);
		&:hover{ box-shadow: $selected-box-shadow; z-index: 2 }

		@media #{$from-small}{
			display: flex; padding: $space;
			& > * + *{ margin-left: $space-m }
		}

		@media #{$to-tablet}{
			& > * + *{ margin-top: $space-s }
		}

		&__infos{
			flex: 1; text-align: left; color: $c-primary;
			b{ display: block }
		}

		&__dropdown{ margin-left: auto }

		&__content{
			@media #{$from-small}{ display: flex; }
		}

		&__price{ font-size:$font-xl; font-weight: bold; color: $c-primary }

		&__training-col{ display: flex; justify-content: space-between; flex-direction: column }
		&__numbers{ text-align: right }
		&__type{ color: $c-primary }

		&__details{
			background: $c-background; border-radius: $border-radius; color: $c-primary;
			margin-top: $space-s; font-size: $font-xs; white-space: nowrap; display: flex; padding: 0 0.5rem;
			& > *{
				padding: math.div($space-s,2) $space-s;
			}

			.accessible-version &{ border: solid 1px $c-border }

			@media #{$to-tablet}{ display: inline-block }
		}

		&__distance{
			color: $c-main;
			@media #{$to-tablet}{ position: absolute; top: $space-m; right: $space-m }
		}

		&__participants{
			font-size: $font-xs;  margin-top: $space-s; position:relative;

			@media #{$from-small}{
				display: inline-block; margin-left: $space-s;
			}
			&-label{ color: $c-main }
			&-hover{
				background: white; padding: $space-s; position: absolute; box-shadow: $box-shadow; right: $space-m; left: 0; top: 100%; margin-top: $space-s
			}
		}

    &__date{
      position:relative; color: $c-secondary; font-weight: bold; text-align: center; margin-right: $space-s;
      line-height: 1; width: 3.5rem;
      &:before{ color: $c-primary; font-size: 4.5rem }
      .month{ text-transform: uppercase; font-size: 8px; display: block }
      & + *{ flex: 1 }
    }

    &__date-short{
      position: overlay(); display: flex; flex-direction: column; align-items: center; justify-content: center;
      margin-top: .8rem; font-size: $font-s; line-height: 0.9;
    }

    &--webinar &{
      &__date-short{ margin-top: -0.4rem }
    }

		&__receipt{ margin-top: $space-m}

		&__icon{ font-size: $font-xxl; color: $c-primary; padding: 0 8.5px }

		.right-space{ margin-right: math.div($space-s,2)}

		@media #{$to-tablet}{
			&__date{
				& > *{
					display: inline-block;
					& + *{
						margin-left: math.div($space-s,2);
						&:before{ content: '-'; margin-right: math.div($space-s,2) }
					}
				}
			}
		}
	}

</style>