<template>
	<div class="o-documents-dashboard" :class="{'o-documents-dashboard--loading':loading}">
		<h2 class="title">{{ interface.title }}</h2>

		<div class="maintenance" v-if="inMaintenance(config, 'documents')">Zone en maintenance</div>

		<div v-else>
			<div class="side-padding o-documents-dashboard__container">
				<tabs @changeTab="changeTab">
					<tab id="new" name="Nouveaux documents" icon="new-file" :unread="unread" :selected="true" ></tab>
					<tab id="favorite" name="Mes favoris" icon="favorite" v-if="!user.isLegalRepresentative"></tab>
					<tab id="popular" name="Les plus consultés" icon="trophy"></tab>
					<tab id="filter" name="Filtrer" icon="filter"></tab>
				</tabs>
				<documents-filters class="o-documents-dashboard__filters" mod="dashboard" @changeFilters="updateTerms" v-if="showFilters"  @resetSearch="params.search = ''"/>

				<div v-if="!resources.length && !loading" class="o-documents-list__empty">
					<div v-if="this.params.filter === 'favorite'">
						<p class="title-big">Aucun document favori.</p>
						<p><b class="primary">Rechercher :</b></p>
						<ul>
							<li v-for="(item, index) in terms">
								<router-link :to="{ name: 'documents', params:{ section: item.slug, title: item.title }}" class="link">{{item.title}}</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'documents', params:{ section: 'documents-administratifs' }}" class="link">Documents administratifs</router-link>
							</li>
						</ul>
					</div>
					<p class="title-big" v-else>Aucun document</p>
				</div>

				<div class="o-documents-dashboard__overflow" :class="showFilters?'o-documents-dashboard__overflow--alt':''" v-infinite-scroll="loadMore" ref="container" v-else>
					<component :is="resource.type" class="o-documents-dashboard__item" v-for="(resource, index) in resources" :key="resource.type+resource.id" :data="resource" />
				</div>
				<loader class="action" v-if="loading"></loader>

				<div class="o-documents-dashboard__search">
					<form @submit.prevent="searchDocuments" id="documents-dashboard_search">
						<button type="submit" class="button--search"></button>
						<input type="text" placeholder="Rechercher" name="search" v-model="params.search" minlength="3"/>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import documentRepository from '@/repositories/documentRepository';
	import resourceRepository from "@/repositories/resourceRepository";
	import appendixRepository from "@/repositories/appendixRepository";

	export default {
		name: 'documents-dashboard',
		props:['data','interface'],
		data(){
			return{
				showFilters: false,
				params:{
					limit : 20,
					offset: 0,
					filter: '',
					sort: 'createdAt',
					order: 'desc',
				},
				resources: [],
				count: -1,
				loading: false,
				unread: false,
				type: 'resource'
			}
		},
		methods:{
			updateTerms(category, form){

				if(category){
					this.type = form.type === 'documents-administratifs' ? 'appendix' : 'document';
					this.params.category = category;

				} else{

					this.type = 'resource';
					this.params.category = [];
					this.params.search = '';
				}

				this.searchDocuments();
			},
			searchDocuments(){

				if( this.$refs.container )
					this.$refs.container.scrollTop = 0;

				this.query(0);
			},
			success(response){
				this.params.offset = response.offset;
				this.count = response.count;
				this.resources = response.items;
				this.loading = false;
			},
			error(response){
				this.loading = false;
			},
			query(offset){

				if( this.loading === true )
					return;

				this.params.offset = offset;
				this.loading = true;

				if( this.type === 'document')
					documentRepository.list(this.params).then(this.success, this.error);
				else if( this.type === 'appendix')
					appendixRepository.list(this.params).then(this.success, this.error);
				else
					resourceRepository.list(this.params).then(this.success, this.error);
			},
			changeTab(tab){

				this.params.filter = '';
				this.params.sort = '';
				this.params.category = [];

				this.showFilters = false;

				if(tab === 'new')
					this.params.sort = 'createdAt';
				else if(tab === 'favorite')
					this.params.filter = 'favorite';
				else if(tab === 'popular')
					this.params.sort = 'popular';
				else if(tab === 'filter'){
					this.params.sort = 'createdAt';
					this.showFilters = true;
				}

				if( this.params.sort === 'popular')
					this.type = 'document'
				else
					this.type = 'resource'

				this.searchDocuments();
			},
			loadMore(){

				if(this.resources.length >= this.count || !this.resources.length )
					return false;

				this.query(this.params.offset + this.params.limit);
			}
		},
		computed:{
			config(){ return this.$config() },
			user(){ return this.$user() },
			terms(){
				return this.$store.getters.terms();
			}
		},
		mounted(){

			this.searchDocuments();
			this.$update(this.searchDocuments);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-documents-dashboard{
		$c: &;

    .m-documents-filters__columns{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem 2rem;
      .m-documents-filters__filters-input{ margin-top: 0 }
    }

		& > *{ position: relative }

		.o-tabs{ z-index: 5 }

		&__container{
			@media #{$to-tablet}{
				display: flex; flex-direction: column;
			}
		}

		&__search{
			@media #{$from-small}{ position: absolute; top: $space; right: $space; width: 16rem; z-index: 5; }
			@media #{$to-tablet}{ position: relative; margin: 0 0 $space-s }
		}

		&__overflow{
			height: 28rem; overflow-y: auto; position: relative; -webkit-overflow-scrolling: touch;
			@media #{$to-tablet}{ order: 1; }

			.accessible-version &{ border: solid 1px $c-border }

			&--alt{
				.m-document:nth-child(odd){ background: #fff }
				.m-document:nth-child(even){ background: $c-background-lighter }
			}
		}

		.a-loader.action{
			background: rgba(#fff,0.4); border-radius: 4px; height: 28rem;
			top: auto; bottom: $space;
		}
	}
</style>
