<template>
	<form id="caci-register_companies">
		<h3 class="primary"><b>Agences par lesquelles vous êtes mandaté</b></h3>
		<caci-company v-for="(address,index) in companiesAddresses" :key="index" :address="address"></caci-company>
		<div class="bottom-right-inline">
			<button class="button button--white" @click.prevent="addCompany">Ajouter une autre agence</button>
			<button class="button button--secondary" :class="{'button--disabled':!hasWorkAddress}" @click.prevent="nextStep">Suivant</button>
		</div>
	</form>
</template>

<script>

	export default {
		name: "caci-register-agencies",
		data(){
			return{
				error: false
			}
		},
		methods: {
			addCompany() {
				this.user.contact.addresses.push({
					id: false,
					company: '',
					email: '',
					phone: '',
					isMain: false,
					isHome: false,
					isActive: true
				});
			},
			nextStep() {
				this.$emit('nextStep');
			}
		},
		computed:{
			user(){ return this.$user() },
			hasWorkAddress() {
				return this.user.contact.addresses.filter(address=>{
					return address.id && address.isActive && !address.isHome
				}).length
			},
			companiesAddresses(){
				return this.user.contact.addresses.filter(address => {
					return !address.isHome
				})
			}
		},
		mounted() {
			 if( !this.hasWorkAddress ){
				 this.addCompany();
				 this.$forceUpdate();
			 }
		}
	}
</script>