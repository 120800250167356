<template>
	<div class="o-user-infos">
		<h2 class="o-user-infos__title title" v-if="interface.title">{{ interface.title }}</h2>
		<div v-if="contact">
			<form @submit.prevent="updateProfile" ref="form" id="user_update-profil">
				<div class="o-user-infos__heading">
					<div class="o-user-infos__thumbnail">
						<img v-if="contact.avatar" :src="contact.avatar"/>
						<span class="o-user-infos__thumbnail-placeholder" v-else>{{ contact.firstname.substr(0,1) }}{{ contact.lastname.substr(0,1) }}</span>
						<div class="o-user-infos__thumbnail-update" @click="changeAvatar">
							<span class="desktop">Mettre à jour la photo</span><span class="mobile" data-icon="pencil"></span>
							<input id="avatar" type="file" name="avatar" class="input--hidden" @change="updateAvatar">
						</div>
					</div>
					<div class="o-user-infos__name">
						<div class="title-big">{{ contact.firstname }} {{ contact.lastname }}</div>
					</div>
				</div>

				<vue-form-generator tag="div" :schema="getForm()" :model="contact" :options="formOptions" class="o-user-infos__form" v-if="contact" @model-updated="changesUpdated"></vue-form-generator>

				<div class="bottom-right">
					<submit text="Enregistrer" secondary="1" text-success="Enregistré" :success="saved" :loading="loading"></submit>
				</div>
			</form>
			<div class="title-big o-user-infos__password">
				Changer mon mot de passe
			</div>
			<form @submit.prevent="newPassword" id="user_new-password">
				<submit text="Changer mon mot de passe" :loading="passwordLoading"></submit>
			</form>
		</div>
	</div>
</template>

<script>

	import userRepository from "@/repositories/userRepository";
	import companyRepository from "@/repositories/companyRepository";
	import contactRepository from "@/repositories/contactRepository";

	export default {
		name: "user-infos",
		props:['data','interface'],
		data(){
			let self = this;
			return{
				contact:false,
				loading: false,
				passwordLoading: false,
				saved: false,
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
				formAvatar:{
					fields: [{
						type: "upload",
						inputName: "avatar",
						model: "avatar",
						styleClasses: "input--hidden",
						accept:'image/*'
					}]
				},
				allowEvent: false
			}
		},
		methods:{
			updateProfile(){

				let formData = new FormData(this.$refs.form);
				formData.append('isHome', true);

				this.loading = true;

				let self = this;
				contactRepository.update(this.contact.id, formData).then(response=>{

					self.saved = true;
					self.loading = false;
					this.$store.commit('changesSaved', true);

					setTimeout(function () { self.saved = false }, 1000);

				}, response=>{

					self.loading = false;
				});
			},
			getForm(){

				let self = this;

				return {
					groups:[{
						legend: "Informations personnelles",
						fields: [
							{
								type: "input",
								inputType: "text",
								inputName: "addresses["+this.contact.address.index+"][street]",
								model: "address.street",
								maxlength: 60,
								styleClasses: "fields-label",
								placeholder: "Adresse",
								label: "N° / voie"
							},
							{
								type: "input",
								inputType: "hidden",
								inputName: "addresses["+this.contact.address.index+"][isActive]",
								model: "address.isActive"
							},
							{
								type: "input",
								inputType: "text",
								inputName: "addresses["+this.contact.address.index+"][zip]",
								model: "address.zip",
								styleClasses: "input--one-third fields-label",
								label: "Code Postal",
								pattern: "[0-9]{5,6}",
								maxlength:5
							},
							{
								type: "input",
								inputType: "text",
								inputName: "addresses["+this.contact.address.index+"][city]",
								model: "address.city",
								maxlength: 50,
								styleClasses: "input--two-third fields-label",
								label: "Ville"
							},
							{
								type: "tel-custom",
								inputName: "addresses["+this.contact.address.index+"][phone]",
								model: "address.phone",
								styleClasses: "input--one-third fields-label",
								label: "Téléphone portable",
								required: self.hasRole(self.user,'contact')
							},
							{
								type: "input",
								inputType: "email",
								inputName: "addresses["+this.contact.address.index+"][email]",
								model: "address.email",
								maxlength: 60,
								styleClasses: "input--two-third fields-label",
								label: "Adresse email",
								required: true
							},
							{
								type: "input",
								inputType: "date",
								inputName: "birthday",
								model: "birthday",
								styleClasses: "input--one-third fields-label",
								label: "Date de naissance",
								readonly: true,
								disabled: true
							}
						]
					}]
				}
			},
			changeAvatar(){

				document.getElementById('avatar').click()
			},
			updateAvatar(e){

				let self = this;

				if (e.target.files && e.target.files[0]) {

					let reader = new FileReader();

					reader.onload = function(e) {
						self.contact.avatar = e.target.result;
					}

					reader.readAsDataURL(e.target.files[0]);
				}
			},
			newPassword(){

				this.passwordLoading = true;
				userRepository.requestPassword(this.user.contact.email).then(response => {

					this.$popin('change-password', { type:'user', display_email: response.body.response.email, email: this.user.contact.email});
					this.passwordLoading = false;
				});
			},
			changesUpdated(){
				if(this.allowEvent)
					this.$store.commit('changesSaved', false);
			}
		},
		computed:{
			user(){ return this.$user() },
			changesSaved(){ return this.$store.getters.changesSaved() }
		},
		mounted() {
			contactRepository.get().then(contact=>{
				this.contact = contact
				setTimeout(()=>{this.allowEvent = true},1000)
			});
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-user-infos{

		&__heading{


			&+ *{ margin-top: $space }

			@media #{$from-tablet}{ display: flex; align-items: center; }
			@media #{$to-phone}{
				text-align: center;
				& > * + *{ margin-top: $space-m }
			}
		}

		&__thumbnail{
			width: 20%; padding-top: 20%; border: solid 1px $c-border; border-radius: 50%; overflow: hidden; margin-right: $space;
			background: $c-background;
			position: relative;
			img{ position: overlay(); object-fit: cover; height: 100%; width: 100%; max-width: none }

			@media #{$to-phone}{
				width: 40%; padding-top: 40%; margin-left: auto; margin-right: auto; font-size: $font-xl;
			}

			&-update{
				color: white; display: flex; align-items: center; justify-content: center;
				position:absolute; bottom: 0; top: 50%; left: 0; right: 0; cursor: pointer; padding-left: $space-m; padding-right: $space-m;
				text-align: center;
				>*{ position: relative; z-index: 1 }
				&:after{
					position: overlay(); content: ''; background: $c-primary; opacity: 0.3;
				}
			}

			&-placeholder{ position: overlay(); background: $c-background; color: rgba($c-primary,0.15);
				display: flex; align-items: center; justify-content: center; font-size: 8rem; text-transform: uppercase }
		}

		.input--hidden{ display: none }

		&__form{
			& > *{ display: flex; flex-wrap: wrap; margin-top: $space }

			legend{ font-size: $font-xxl; font-weight: bold; color: $c-primary }

			.form-group{
				width: 100%; margin-top: $space;

				input{ width: 100% }
			}
			@media #{$from-small}{
				.input--one-third{

					width: calc(33% - #{$half-space});
					& + .input--two-third{ margin-left: $space }
				}
			}
			.input--two-third{ width: calc(66% - #{$half-space})}

			.readonly input{ color: $c-main; font-style: italic }
		}

		&__password{
			@media #{$to-tablet}{ margin-top: $space }
			& + form{ margin-top: $space-m }
		}
	}

</style>