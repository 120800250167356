<template>
	<div class="o-signatures-dashboard">

		<h2 class="title">{{ interface.title }}</h2>

    <div class="maintenance" v-if="inMaintenance(config, 'e-signatures')">Zone en maintenance</div>

    <div class="o-signatures-dashboard__container" v-else>
			<loader v-if="loading" :static="true"></loader>
      <div class="o-signatures-dashboard__error" v-else-if="!collections.length">Vous n'avez aucune signature .</div>
			<div class="o-signatures-dashboard__signature" v-for="(collection,index) in collections">
				<h2 class="o-signatures-dashboard__signature-title">
					<span v-if="collection.type_contrat">{{ collection.type_contrat }}</span>
					<span v-else>Autre document</span>
				</h2>
				<div class="o-signatures-dashboard__deadline" v-if="collection.status !== 'completed'">{{ status[collection.status] }}</div>
				<div class="o-signatures-dashboard__left" v-if="statusNotValid.indexOf(collection.status) === -1">
					<radial :current="signed(collection.signatures)" :total="collection.signatures.length"></radial>
				</div>
				<div class="o-signatures-dashboard__signataires">
					<span class="primary">Signataires</span>
					<span v-for="(signataire, index) in collection.signatures.slice(0,3)" :key="index">
						{{signataire.lastname}}
					</span>
					<span v-if="collection.signatures.length>3"> et {{collection.signatures.length-3}} autre{{collection.signatures.length-3>1?'s':''}}.</span>
				</div>
			</div>
			<div class="bottom-right">
				<button class="button button--icon" @click="newSignature" data-icon_after="plus" :class="{'button--disabled':(left<=1)}">Créer une e-signature</button>
			</div>
		</div>
	</div>
</template>

<script>

	import signatureRepository from '@/repositories/signatureRepository';

	export default {
		name: 'signatures-dashboard',
		props:['data', 'interface'],
		data(){
			return{
				loading: false,
				collections: false,
				left: 0,
				status:{
					ready : 'en cours',
					completed : 'terminée',
					failed : 'échouée',
					canceled : 'annulée',
					expired : 'expirée',
					waiting : 'en attente'
				},
				statusNotValid:['canceled','expired','failed'],
				params:{
					filter: ''
				}
			}
		},
		computed:{
		  config(){ return this.$config() }
    },
		methods:{
			signed(signatures){
				return signatures.filter(signature => signature.status === 'signed').length
			},
			query(){

				this.loading = true;
				this.collections = false

				signatureRepository.get(this.params).then(response=>{
					this.loading = false;
					this.collections = response.items.splice(0,6);
				}, response=>{
					this.loading = false;
				});
			},
			getStock(){

				signatureRepository.getStock().then(count=>{
					this.left = count
				})
			},
			newSignature(){
				this.$popin('signature-add')
			}
		},
		mounted(){
			this.query();
			this.getStock();
			this.$update(this.query);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-signatures-dashboard{
		&__type,&__deadline{ font-size: $font-xs }
		&__signature-title{ color: $c-primary; flex: 1; margin-bottom: 0.5rem; }
		&__container{
			position: relative; min-height: 1rem;
		}
    &__error{
			margin: $space-l 0;
		}
		&__signature{
			display: flex; align-items: center; padding: $half-space 0; flex-wrap: wrap;
			@media #{$to-tablet}{ &:first-of-type{ padding-top: 0 } }
			& > * + *{ margin-left: $space-s }
			& + &{ border-top: solid 1px $c-background-darker }
		}
		&__signature__left{
			width: 3rem; height: 3rem;
			color: $c-primary;
		}
		&__signataires{
			width: 100%; font-size: $font-xs; margin-left: 0;
			span+span{ margin-left: 0.5rem }
		}
	}
</style>
