<template>
	<transition name="fade">
		<div class="a-dropdown" v-show="isVisible">
			<slot></slot>
		</div>
	</transition>
</template>

<script>
	export default {
		name: "dropdown",
		data(){
			return{
				isOver: false,
				isVisible: false,
				timeout: false
			}
		},
		methods:{
			closeDropdown(e) {

				let cursor = e ? window.getComputedStyle(e.target, null).getPropertyValue('cursor'): false;

				if(!e || !this.isOver || e.target.tagName === 'A' || e.target.tagName === 'BUTTON' || cursor === 'pointer'){
					clearTimeout(this.timeout);
					this.isVisible = false;
					this.$emit('close');
				}
			},
			openDropdown(){
				this.isVisible = true;
				this.$emit('open');

				if(document.documentElement.classList.contains('touch'))
					this.startTimeout()

			},
			toggleDropdown(e){

				if( this.isVisible ){
					e.preventDefault()
					this.closeDropdown(e)
				}
				else{
					e.stopImmediatePropagation();
					this.openDropdown()
				}
			},
			mouseOver(){

				this.isOver = true;

				if(this.isVisible)
					clearTimeout(this.timeout);
			},
			mouseOut(){

				this.isOver = false;

				if(this.isVisible){

					clearTimeout(this.timeout);
					this.timeout = setTimeout( this.closeDropdown, 2000);
				}
			}
		},
		mounted(){
			document.addEventListener('click', this.closeDropdown);
			this.$el.parentElement.addEventListener('click', this.toggleDropdown)
			this.$el.parentElement.addEventListener('mouseenter', this.mouseOver)
			this.$el.parentElement.addEventListener('mouseleave', this.mouseOut)
		},
		destroyed() {
			document.removeEventListener('click', this.closeDropdown);
			this.$el.parentElement.removeEventListener('click', this.toggleDropdown)
			this.$el.parentElement.removeEventListener('mouseenter', this.mouseOver)
			this.$el.parentElement.removeEventListener('mouseleave', this.mouseOut)
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.a-dropdown{
		position: absolute; top: 100%; background: white; box-shadow: $selected-box-shadow; font-size: $font-s; padding: $space-l; right: -$space-s;
		z-index: 1; white-space: nowrap; border-radius: $border-radius; margin-top: $space-m; color: $c-primary;
		&:before {
			bottom: 100%; right: 0; border: solid transparent; content: " ";
			height: 0; width: 0; position: absolute; pointer-events: none; border-bottom-color: white;
			border-width: $space-s; margin-right: $space-s;
		}
	}
</style>