<template>
  <v-tour name="tutorial" :steps="tourSteps" class="custom-tour" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
</template>

<script>

import userRepository from "@/repositories/userRepository";

export default {
  name: 'tour',
  data(){
    return{
      typeTour: false,
      tourCallbacks:{
        onSkip: this.saveTour,
        onFinish: this.saveTour
      },
      tourOptions: {
        labels: {
          buttonSkip: 'Passer le tutoriel',
          buttonPrevious: 'Précédent',
          buttonNext: 'Suivant',
          buttonStop: 'Terminer'
        }
      },
      tourSteps: [],
      allSteps:{
        home: [
          {
            target: '.o-header__logo img',
            content: 'Bienvenue sur le nouvel <strong>espace adhérent SNPI</strong>, vous disposez désormais d’une nouvelle interface plus intuitive.',
            offset: -145
          },
          {
            target: '.o-header__search',
            content: 'Recherchez tous vos documents, et toutes les formations sans difficulté !',
            offset: -145
          },
          {
            target: '.m-links__item--contact',
            content: 'Une demande particulière ? Envoyez-nous un message',
            offset: -145
          },
          {
            target: '.m-links__item--profile',
            content: 'Accédez à vos données personnelles, professionnelles, ainsi qu’à la liste de vos collaborateurs en cliquant ici.',
            offset: -145
          },
          {
            target: '.o-navigation__list li:first-of-type',
            content: 'Retrouvez un récapitulatif de l’ensemble de vos informations, pour avoir une vision rapide sur toutes vos données.',
            offset: -145
          },
          {
            target: '.o-navigation__list li:nth-of-type(6)',
            content: 'Paramétrer l’envoi de vos annonces et renseigner votre barème d’honoraires',
            offset: -145
          },
          {
            target: '.o-navigation__list li:nth-of-type(7)',
            content: 'Retrouvez ici le trombinoscope des Experts Immobiliers SNPI',
            offset: -145
          },
          {
            target: '.o-documents-dashboard .o-tabs',
            content: 'Les derniers documents publiés, vos documents favoris et les documents les plus consultés de l’espace sont disponibles en un clic dans cette section.',
            offset: -145
          },
          {
            target: '.o-signatures-dashboard__container',
            content: 'Les signatures électroniques en cours de validation sont accessibles via cet onglet.',
            offset: -145
          },
          {
            target: '.o-trainings-dashboard',
            content: 'Suivez en temps réel le quota de formations nécessaire à vos collaborateurs, ainsi que vos dernières formations souscrites.',
            offset: -145
          }
        ],
        formations: [
          {
            target: '.o-submenu__list div:first-of-type',
            content: 'Découvrez l’ensemble des formations accessibles pour vos collaborateurs, depuis une interface où tous les types de formations sont confondues !',
            offset: -145
          },
          {
            target: '.o-submenu__list div:nth-of-type(2)',
            content: 'Retrouvez l’historique d’achat de vos formations et les collaborateurs inscrits.',
            offset: -145
          },
          {
            target: '.o-submenu__list div:nth-of-type(3)',
            content: 'Consulter le quota de formations à réaliser par vos collaborateurs.',
            offset: -145
          },
          {
            target: '.m-training-item:first-of-type',
            content: 'Souscrivez en cliquant sur la formation souhaitée.',
            offset: -145
          }
        ],
        documents: [
          {
            target: '.o-submenu__list [href="/documents/informations-juridiques"]',
            content: 'Accédez aux Flashs Infos, Bulletins d’information, Dossiers…',
            offset: -145
          },
          {
            target: '.o-submenu__list [href="/documents/imprimes-a-telecharger"]',
            content: 'Retrouvez les modèles de mandats et contrats de travail',
            offset: -145
          },
          {
            target: '.o-submenu__list [href="/documents/documents-administratifs"]',
            content: 'Retrouvez vos factures, avoirs, attestations,...',
            offset: -145
          },
          {
            target: '.o-submenu__list [href="/documents/favoris"]',
            content: 'Retrouvez vos documents enregistrés.',
            offset: -145
          },
          {
            target: '.m-document:first-of-type .m-document__actions [data-icon=favorite]',
            content: 'Enregistrez un document en tant que document favori pour pouvoir le retrouver rapidement!',
            offset: -145
          },
          {
            target: '.m-document:first-of-type .m-document__actions [data-icon=see]',
            content: 'Consultez les informations détaillées de votre document',
            offset: -145
          },
          {
            target: '.m-document:first-of-type .m-document__actions [data-icon=download]',
            content: 'Téléchargez votre document ( plusieurs formats sont parfois disponibles ! )',
            offset: -145
          }
        ],
        signatures: [
          {
            target: '.o-infos__item:first-of-type',
            content: 'Informations à lire avant de vous lancer',
            offset: -145
          },
          {
            target: '.o-signatures-new__create',
            content: 'Créer une nouvelle collecte de signatures et acheter un pack',
            offset: -145
          }
        ]
      }
    }
  },
  computed:{
    user(){ return this.$user() }
  },
  methods:{
    resetTour(){
      userRepository.updateMetadata('tour', 'read', '').then(response=>{
        this.showTour(this.$route);
      })
    },
    saveTour(){
      userRepository.updateMetadata('tour', 'read', this.typeTour)
    },
    load(){
      if( typeof this.$tours['tutorial'] != "undefined" ){
        if(this.tourSteps.length )
          this.$tours['tutorial'].start()
        else
          this.$tours['tutorial'].stop()
      }
    },
    showTour(route){

      if(route.name === 'home' && this.user.metadata.read.tour.indexOf('home') === -1)
        this.typeTour = 'home';
      else if ( (route.params.page === 'documents' || route.name === 'documents') && this.user.metadata.read.tour.indexOf('documents') === -1 )
        this.typeTour = 'documents';
      else if(route.params.page === 'formations' && !route.params.subpage && this.user.metadata.read.tour.indexOf('formations') === -1 )
        this.typeTour = 'formations';
      else if(route.params.page === 'e-signatures' && this.user.metadata.read.tour.indexOf('signatures') === -1 )
        this.typeTour = 'signatures';
      else
        this.typeTour = false

      this.$store.commit('tour', this.typeTour);

      if( this.typeTour ){

        let availableSteps = [];
        let tourSteps = this.allSteps[this.typeTour];

        tourSteps.forEach((item, index) => {
          if( document.querySelector(item.target) )availableSteps.push(item);
        })

        this.tourSteps = availableSteps;
      }
      else{

        this.tourSteps = [];
      }

      this.$nextTick(this.load)
    }
  },
  watch:{
    $route(to,from){

      if(parseInt(process.env.VUE_APP_TOUR)) {

        if( typeof this.$tours['tutorial'] != "undefined" )
          this.$tours['tutorial'].stop();

        let self = this;
        setTimeout(()=>{ self.showTour(to) },500 );
      }
    }
  },
  mounted(){

    this.$resetTour(this.resetTour);

    if(parseInt(process.env.VUE_APP_TOUR)){
      let self = this;
      setTimeout(function() {
        self.showTour(self.$route)
      },500 );
    }
  }
}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';
.custom-tour.v-tour{
  .v-step{
    background: #fff; color: $c-primary; border-radius: $border-radius; filter: drop-shadow($selected-box-shadow); padding: 2rem;

    &__header{
      background: $c-secondary; color: white; text-transform: uppercase; font-weight: bold; padding-top: $space-s; padding-bottom: $space-s;
      border-top-left-radius: $border-radius; border-top-right-radius: $border-radius;
    }

    &__arrow{ border-bottom-color: #fff!important; }
    &[x-placement^=top] .v-step__arrow{ border-top-color: #fff!important; }
    &__arrow.v-step__arrow--dark{ border-bottom-color: $c-secondary !important; }

    &__button{
      border: solid 1px $c-primary; border-radius: $border-radius; font-family: 'Open Sans', Arial, sans-serif;
      padding: math.div($space-s,2) $space-s; font-size: $font-s; text-transform: uppercase; line-height: 1; height: auto;
      &-next,&-stop{
        background: $c-primary;
        &:hover{ color: #fff!important }
      }
      &-skip{ color: $c-primary; background: white }
      &-previous{ display: none }
      &:hover{ color: $c-primary }
    }

    &__content{ padding: $space-s }
  }
}
</style>