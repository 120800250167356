<template>
  <FunctionalCalendar ref="Calendar" :is-layout-expandable="false" :date-format="'dd/mm/yyyy'" v-model="calendar" :new-current-date="schema.default" :is-modal="platform === 'desktop'" :isAutoCloseable="false" :limits="limits" :changeMonthFunction='true' :changeYearFunction='true' :is-date-range="true" :is-multiple="true" :calendars-count="platform === 'mobile' ? 1 : 2" :canClearRange="platform === 'desktop'">
    <template v-slot:dateRangeInputs="props">
	    <input
			    type="text"
			    title="Start Date"
			    v-model="calendar.dateRange.start"
			    placeholder="jj/mm/aaaa"
			    v-if="calendar.dateRange.start !== '' || calendar.dateRange.end !== ''"
	    />
	    <input
			    type="text"
			    title="End Date"
			    v-model="calendar.dateRange.end"
			    placeholder="jj/mm/aaaa"
			    v-if="calendar.dateRange.start !== '' || calendar.dateRange.end !== ''"
	    />
	    <div class="tag" v-if="calendar.dateRange.end === '' && calendar.dateRange.start === ''" @click="$refs.Calendar.showCalendar = true">Date</div>
	    <div class="separator" v-else></div>
    </template>
  </FunctionalCalendar>
</template>

<script>
	import { abstractField, fieldComponents } from "vue-form-generator";

	export default {
		name: "field-calendar-filter-custom",
    components: fieldComponents,
		mixins: [ abstractField ],
		data(){
			return{
				calendar:{
          selectedDate: false,
          dateRange : {
            start: '',
            end: ''
          }
        },
				disabledDates: this.schema.disabledDates,
				limits: {min: this.convertDate(this.schema.min,'-', '/'), max: this.convertDate(this.schema.max,'-', '/')},
				loaded: false,
				platform: window.platform
			}
		},
		watch:{
			'calendar.dateRange.start'(newValue){
				this.updateModelValue({startAt:this.convertDate(newValue, '/', '-', true),endAt:this.convertDate(this.calendar.dateRange.end, '/', '-', true)}, '');
			},
			'calendar.dateRange.end'(newValue){
				this.updateModelValue({startAt: this.convertDate(this.calendar.dateRange.start, '/', '-', true),endAt: this.convertDate(newValue, '/', '-', true)}, '');
			}
		},
    computed:{
      formattedDate: {
        get() {
          if( this.calendar.selectedDate ){
            let date = this.calendar.selectedDate.split('/');
            if( date.length === 3 )
              return this.formatDateInput(date[1]+'/'+date[0]+'/'+date[2]);
          }
          return ''
        },
        set(newValue) {
          let date = newValue.split('/');
          if( date.length === 3 && date[0].length && date[1].length && date[2].length===4 ){

            this.setDate(date[1]+'/'+date[0]+'/'+date[2]);
            this.updateModel({date:newValue});
          }
          return newValue;
        }
      }
    },
    methods:{
      convertDate(date, split, sep, leadingZero){

        if( typeof date != 'string')
          return '';

        date = date.split(split);

        if( date.length === 3 ){

          if( leadingZero )
            return (parseInt(date[2])<10?'0':'')+date[2]+sep+(parseInt(date[1])<10?'0':'')+date[1]+sep+(parseInt(date[0])<10?'0':'')+date[0]
          else
            return date[2]+sep+date[1]+sep+date[0]
        }

        return '';
      },
		  getSchema(){

		    let schema = this.schema;
        schema.type = 'input';
        schema.inputType = 'date';
        schema.format = "YYYY-MM-DD";

		    return schema;
      },
      setDate(value){
        let date = new Date(value);
        if( date.getTime() === date.getTime() ){
          this.calendar.selectedDate = parseInt(date.getDate())+'/'+parseInt(date.getMonth()+1)+'/'+date.getFullYear();
          this.$refs.Calendar.ChooseDate(this.calendar.selectedDate);
        }
      }
    },
		mounted(){

      if(this.value && this.platform==='desktop')
        this.setDate(this.value);

			this.loaded = true;

			let element = this.$el.querySelector('.footerCon .rangeCleaner span');
			if(element)
				element.innerHTML= 'Réinitialiser'
		}
	};
</script>

<style lang="scss">
	@import '../../../environment';
	.field-calendar-filter-custom{
		.vfc-calendars{ flex-wrap: nowrap; color: $c-primary }
		.vfc-week .vfc-day{
			.vfc-base-end, .vfc-base-end{ background-color: $c-background}
		}
		.vfc-week .vfc-day span.vfc-span-day.vfc-marked{
			background-color: transparent; color: $c-primary;
			&:after{ background: transparent; }
			&:before{ background-color: $c-background }
			&.vfc-end-marked,&.vfc-start-marked, {
				color: white; border-radius: 5px; overflow: hidden;
				&:before{  border-radius: 5px; overflow: hidden; background-color: $c-primary }
			}

		}
		.rangeCleaner span.active{ background-color: white; color: $c-primary; border: solid 1px $c-primary }

		.advanced-filters{
			@media #{$to-tablet}{
				display: none
			}
		}

		.vfc-multiple-input {
			.separator{ width:0.5rem; position: absolute; left: 50%; margin-left: -0.5rem; background:white; height: 1px; top: 50% }
		}

		label{
			@media #{$from-small}{ display: none }
		}
	}
</style>

