<template>
	<div class="a-answer" :class="{'a-answer--selected': isSelected && type === 'multiple','a-answer--correct': isSelected && isCorrect,'a-answer--rightone': !isSelected && data.correct,'a-answer--wrong': isSelected&&!isCorrect}">
		<div v-if="type === 'single'">
			<label class="input--radio">
				<input type="radio" :name="'answers['+name+']'" :value="data.answer" @change="change">
				<span class="a-answer__label icon-flex"><span data-icon="chevron-right" class="icon-flex"></span>{{data.answer}}</span>
			</label>
		</div>
		<div v-else>
			<label class="input--checkbox">
				<input type="checkbox" v-model="isSelected" @change="change" :name="'answers['+name+']'" :value="data.answer">
				<span class="checkmark" data-icon="check"></span>
				<span class="a-answer__label icon-flex"><span data-icon="chevron-right" class="icon-flex"></span>{{ data.answer }}</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: "answer",
	props:['data','type','number','subnumber', 'radioChecked', 'name'],
	data(){
		return{
			isCorrect: false,
			isSelected: false
		}
	},
	methods:{
		change(e){
			let oldCorrect = this.isCorrect
			if( this.type === 'single')
				this.isSelected = e.target.checked

			let selected= this.type === 'single' ?  e.target.checked : Boolean(this.isSelected);

			this.isCorrect= this.data.correct === selected

			if(selected)
				this.$emit('selected-answer',this.isCorrect, this.type === 'single'  ? this.subnumber : false)
			else
				this.$emit('deselected-answer',oldCorrect)
		}
	},
	watch:{
		radioChecked(){
			this.isSelected = this.type === 'single' ? this.radioChecked === this.subnumber : this.radioChecked
			let oldCorrect = this.isCorrect
			this.isCorrect= this.data.correct === this.isSelected

			if(!this.isSelected)
				this.$emit('deselected-answer',oldCorrect)
		},
		isSelected(){

		}
	}
}
</script>

<style lang="scss">
@import '../../environment';

.a-answer{
	$c: &;
	border: solid 1px  $c-background-darker; transition: allow(background); padding: $space-m; border-radius: $border-radius;

	& + &{ margin-top: $space-s }
	&--selected{ background: $c-background-darker }


	input{ flex-shrink: 0 }

	label{    display: flex; align-items: center }
	&__label{
		display: inline-flex; font-weight: bold; margin-left: $space-s;
		&:before{ counter-increment: answer; content: counter(answer, upper-alpha); margin-right: 3px; font-size: $font-l }
	}
	.a-question--result &--wrong,.a-question--result &--correct,.a-question--result &--rightone{ pointer-events: none; }
	.a-question--result &--wrong{
		background: $c-red; color: white;
		.input--radio:before{ border-color: $c-red;}
		.input--radio input[type=radio]:checked,.input--checkbox input[type=checkbox]:checked{ background: $c-red; }
	}

	.a-question--result &--correct,.a-question--result &--rightone{
		background: white; border-color: $c-green; color: $c-green;
		.input--radio:before{ border-color: $c-green;}
		.input--radio input[type=radio]:checked,.input--checkbox input[type=checkbox]:checked{ background: $c-green; }
	}
}

</style>