<template>
	<div class="o-user-contacts container">
		<h2 class="o-user-infos__title title" v-if="interface.title">{{ interface.title }}</h2>
		<div class="o-user-contacts__filter">
			<checkup mod="details" type="persons" :data="checkup" v-if="checkup"></checkup>
			<form id="contacts_search">
				<button type="submit" class="button--search"></button>
				<input type="text" placeholder="Rechercher par nom/prénom" name="search" v-model="query" @keyup="search" />
			</form>
		</div>
		<loader static="1" v-if="loading"></loader>
		<div class="o-user-contacts__grid" v-if="contacts">
			<div class="o-user-contacts__item o-user-contacts__item--action">
				<div class="title-big">Actuellement {{ contacts.length }} collaborateurs</div>
				<div class="field-select" v-if="staff.length - active > 0 && !query.length">
					<div class="field-wrap">
						<select v-model="filter" @change="search">
							<option value="actives">{{ active }} <span v-if="active > 1">actifs</span><span v-else>actif</span></option>
							<option value="inactives" >{{ staff.length - active}} <span v-if="staff.length - active > 1">inactifs</span><span v-else>inactif</span></option>
						</select>
					</div>
				</div>
					<br/>
				<button class="button button--secondary" @click="addContact" v-if="hasRole(user,'company')">Ajouter un collaborateur</button>
			</div>
			<contact class="o-user-contacts__item" v-for="(contact, index) in contacts" :key="contact.id" :data="contact"/>
		</div>
	</div>
</template>

<script>
	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "user-contacts",
		props: ['data','interface'],
		data(){
			return{
				contacts: false,
				staff: false,
				active: 0,
				loading: true,
				query: '',
				filter: 'actives'
			}
		},
		computed:{
			user(){
				return this.$user()
			},
			checkup(){
				return this.$store.getters.checkup()
			}
		},
		methods:{
			addContact(){
				this.$popin('contact-add');
			},
			load(){
				companyRepository.getStaff().then(response=>{
					this.loading = false;
					this.staff = response.items;
					this.active = this.staff.filter(contact => contact.isActive === true).length
					this.search();
				})
			},
			search(){
				if(this.query === ''){
					this.contacts = this.staff.filter(contact => contact.isActive === (this.filter === 'actives') )
				} else {
					let keywords = this.query.toLowerCase().split(' ');
					this.contacts = this.staff.filter(contact => {
						let keywordResult = keywords.filter(keyword => {
							return contact.firstname.toLowerCase().indexOf(keyword) !== -1 || contact.lastname.toLowerCase().indexOf(keyword) !== -1
						})
						return keywordResult.length > 0
					});
				}
			}
		},
		mounted() {

			this.load();
			this.$update(this.load);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-user-contacts{
		text-align: left;

		& > .title{
			margin: $space $space $space-m;
			@media #{$to-tablet}{ margin-left: 0; margin-right: 0 }
		}

		&__filter{
			background: #fff; border-radius: 4px; padding: $space; margin-bottom: $space-m; margin-top: $space-l; box-shadow: $subtle-box-shadow;
			form { position: relative }
		}

		&__grid{
      display: grid;
      grid-gap: 3rem;
      @media #{$only-tablet}{ grid-template-columns: repeat(2, 1fr) }
      @media #{$from-small}{ grid-template-columns: repeat(3, 1fr) }
		}

		&__item{
			 background: white; border-radius: $border-radius; overflow: hidden; width: 100%; box-shadow: $subtle-box-shadow;
			&--action{
				display: flex; align-items: center; justify-content: center; flex-direction: column; padding: $space; background: white;
				text-align: center;
				& > * + *{ margin-top: $space-m }
			}
		}
	}

</style>