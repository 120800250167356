<template>
	<div class="m-poll">

		<loader static="1" v-if="loading"></loader>
		<form @submit.prevent="submitForm" id="poll" v-else>
			<vue-form-generator tag="div" :schema="form" :model="poll"></vue-form-generator>
			<div class="bottom-right">
				<submit :loading="sending" text="Valider" :success="registered" text-success="Validé"></submit>
			</div>
		</form>
	</div>
</template>

<script>

import formationParticipantRepository from "@/repositories/formationParticipantRepository";

export default {
	name: "poll",
	props:['formationParticipant'],
	data(){
		return{
			poll:{},
			registered:false,
			loading:true,
			sending:false,
			form: {
				groups:[]
			}
		}
	},
	methods:{
		submitForm(){

			this.sending = true;

			formationParticipantRepository.sendPoll(this.formationParticipant.id, {answers:this.poll}).then(response=>{

				this.registered = true;
        this.$emit('sent');

			}, response=>{

				this.sending = false;
			})
		},
		generateForm(poll){

			let model = {};

			let form = {
				styleClasses: ["form-group-background","form-group-columns"],
				fields: []
			};

			poll.forEach(pollQuestion=>{

				model[pollQuestion.name] = false;

				let question = {
					type: pollQuestion.type === 'single' ? "radios" : 'checklist',
					model: pollQuestion.name,
					label: pollQuestion.name,
					listBox: true,
					required: true,
					values:[],
					styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
				}

				pollQuestion.answers.forEach(pollAnswer=>{

					question.values.push(pollAnswer);
				})

				form.fields.push(question);
			})

			this.poll = model;
			this.form = form;
		}
	},
	mounted(){

		formationParticipantRepository.getPoll(this.formationParticipant.id).then(poll=>{

			this.loading = false;

			this.generateForm(poll)

		}, response=>{

			this.loading = false;
		})
	}
}
</script>

<style lang="scss">
@import '../../environment';

.m-poll{
	$c: &;

	flex: 1;

	.radio-list.field-checklist{

	}

}
</style>