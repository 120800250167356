<template>
	<div class="o-passerelle">
		<h2 class="title">{{interface.title}}</h2>
		<div class="equal-padding" v-if="company">
			<div v-if="!company.software" class="o-passerelle__empty">
				<p class="title-big icon-flex" data-icon="warning">Vous n'utilisez aucun logiciel de transaction.</p>
				<p>Pour gérer vos annonces immobilières, vous pouvez utiliser gratuitement SNPI ACCESS (logiciel inclus avec votre cotisation syndicale).</p>
				<div class="bottom-center">
					<router-link to="activate" class="button button--secondary" v-if="hasRole(user,'company')">Activer mon logiciel de transaction</router-link>
				</div>
			</div>

			<div v-else class="primary">

					<div class="o-passerelle__actions">
						<a :href="company.realEstate.url_apimo" target="_blank" v-if="company.software === 'apimo' && company.realEstate.url_apimo && company.realEstate.url_apimo.length > 8" class="button button--secondary">Gérer mes annonces</a>
						<a :href="company.realEstate.url" target="_blank" v-if="company.realEstate.url && company.realEstate.url.length > 8" class="button">Voir mes annonces</a>
					</div>
					<h3 class="title-big o-passerelle__title">Passerelle utilisée</h3>
					<p>Vous gérez vos annonces depuis le logiciel &laquo; {{company.software}} &raquo;</p>
					<p class="secondary o-passerelle__info">Si les informations ci-dessus sont incorrectes, merci de contacter le service technique du SNPI.</p>


				<h3 class="title-big o-passerelle__title">Mes annonces immobilières</h3>
				<p v-if="company.realEstate.enabled > 0">
					Vous avez {{company.realEstate.enabled}} <span v-if="company.realEstate.enabled === 1">annonce</span><span v-else>annonces</span> en ligne. Vous pouvez consulter la liste de vos annonces via snpi.immo.
				</p>
				<p v-else>Vous n'avez aucune annonce en ligne. Pour commencer, vous devez activer la passerelle via votre éditeur de logiciel de transaction.</p>
			</div>
		</div>
		<loader :static="true" v-if="loading"></loader>
	</div>
</template>

<script>

	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "passerelle",
		props:['data','interface'],
		data(){
			return{
				company:false,
				loading: true
			}
		},
		computed:{
			user(){
				return this.$user()
			}
		},
		mounted() {
			companyRepository.get({realEstate:true}).then(company=>{
				this.company = company
				this.loading = false
			});
		}
	}
</script>

<style lang="scss">
	@import '../../environment';

	.o-passerelle{

		.a-loader{ min-height: 21rem }
    .equal-padding{ min-height: 23rem}

		&__empty{
			text-align: center;
			.bottom-center { margin-top: 4rem }
			[data-icon]:before{ color: $c-secondary }
		}

		&__title{
			& + p{ margin-top: $space-s }
		}

		&__info{ font-size: $font-xs; margin-top: $space-s }

		p + &__title{ margin-top: $space }

		&__actions{
			display: flex; flex-direction: column;
			@media #{$from-tablet}{ float: right }

			.button + .button{ margin-left: 0; margin-top: $space-s}

			@media #{$to-phone}{ margin-bottom: $space-m }
		}
	}

</style>