export const Mixins = {
	methods: {
		getDays(timestamp1, timestamp2){

			let diff = Math.abs(timestamp1 - timestamp2);
			return Math.round(diff / (1000 * 3600 * 24))
		},
		formatDate(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const options = { year: 'numeric', month: 'long', day: '2-digit',timeZone: 'Europe/Paris' };

			return date.toLocaleDateString('fr-FR', options)
		},
		formatDateTime(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Paris' };

			return date.toLocaleDateString('fr-FR', options)
		},
		formatShortDateWithDay(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const options = { month: 'short', day: '2-digit', weekday: 'short' };

			return date.toLocaleDateString('fr-FR', options)
		},
		formatDateInput(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const options = { year: 'numeric', month: '2-digit', day: '2-digit',timeZone: 'Europe/Paris' };

			return date.toLocaleDateString('fr-FR', options)
		},
		formatBirthday(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const options = {month: 'long', day: '2-digit',timeZone: 'Europe/Paris' };

			return date.toLocaleDateString('fr-FR', options)
		},
		getYear(){

			return new Date().getFullYear()
		},
		formatTitle(title, user){

			if( typeof title == 'string' )
				return title;
			else if( user )
				return title[user.type];
			return '';
		},
		formatYear(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const options = { year: 'numeric',timeZone: 'Europe/Paris' };

			return date.toLocaleDateString('fr-FR', options)
		},
		formatShortDate(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const day = date.toLocaleDateString('fr-FR', { day: '2-digit',timeZone: 'Europe/Paris'});
			const month = date.toLocaleDateString('fr-FR', { month: 'short',timeZone: 'Europe/Paris'});

			return day + ' <span class="month">' + month + "</span>"
		},
		formatDigitDate(timestamp){

			if( timestamp === false || typeof timestamp == 'undefined')
				return ' - ';

			const date = new Date(timestamp);
			const options = { year: 'numeric', month: '2-digit', day: '2-digit',timeZone: 'Europe/Paris' };

			return date.toLocaleDateString('fr-FR', options)
		},
		formatDigitDateApi(date){
			const formattedDate = new Date(date);
			return formattedDate.getFullYear() + '-' + (formattedDate.getMonth()+1) + '-' + formattedDate.getDate();
		},
		now(){
			const date = new Date();
			return date.getFullYear() + '-' +  (date.getMonth()<9?'0':'')+(date.getMonth()+1) + '-' + (date.getDate()<10?'0':'')+date.getDate();
		},
		getDateFromYear(year, asString){
			let date = new Date();
			date.setFullYear(date.getFullYear() - year);

			if( asString )
				return date.getFullYear() + '-' +  (date.getMonth()<9?'0':'')+(date.getMonth()+1) + '-' + (date.getDate()<10?'0':'')+date.getDate();
			else
				return date;
		},
		ntobr(string){

			return string?string.replace(/^\s+|\s+$/g, '').replace(/\n\n/g, "\n").replace(/\n/g, "<br />"):''
		},
		ntodash(string){

			return string?string.replace(/^\s+|\s+$/g, '').replace(/\n\n/g, "\n").replace(/\n/g, '<span class="dash"></span>'):''
		},
		formatPrice(num){

			if( num === false || typeof num == 'undefined')
				return ' - ';

			num = parseFloat(num);

			if( num === 0)
				return 'Offert!';

			return num.toFixed(2).replace(/\.00$/, '')+'€';
		},
		youtubeId(url){

			let ID = '';
			url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
			if(url[2] !== undefined) {
				ID = url[2].split(/[^0-9a-z_\-]/i);
				ID = ID[0];
			}
			else {
				ID = url;
			}
			return ID;
		},
		getPage(url){

			return url.replace(/^.*\/\/[^\/]+/, '')
		},
		checkRole(user, item){

			if( item.exclude && item.exclude === 'legal_representative' && user && user.type === 'company')
				item.exclude = 'company';

			return (typeof item.role === 'undefined' || (item.role === true ||(user.roles && user.roles.indexOf(item.role) > -1)) && (!item.exclude || (user.type && user.type !== item.exclude))) || (item.include && user.type && user.type === item.include);
		},
		hasRole(user, role){

			return this.checkRole(user, {role:'ROLE_'+role.toUpperCase()});
		},
		inMaintenance(config, item){

			return config && config.maintenance && config.maintenance.enabled && item in config && !config[item].enabled
		},
		daysFromMultico(){
			let multico = new Date(process.env.VUE_APP_MULTICO);
			let today = new Date();
			return (today.getTime() - multico.getTime()) / (1000 * 3600 * 24)
		},
		formateDateMultico() {
			let multico = new Date(process.env.VUE_APP_MULTICO);
			return this.formatDate(multico)
		},
		formateDateEndMultico() {
			let multico = new Date(process.env.VUE_APP_MULTICO);
			return this.formatDate(multico.setDate(multico.getDate()+ 60))
		}
	}

};