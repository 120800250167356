import Vue from "vue";
import cache from "@/services/cache";
import store from "@/services/store";

export default {

	get(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('document/' + id).then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	list(params) {

		if( 'search' in params && params.search.length )
			return cache.list('document', params, 0);
		else
			return cache.list('document', params);
	},

	download(id, index){

		let popup = false;

		if( document.documentElement.classList.contains('safari') )
			popup = window.open('/wait', '_blank');

		return new Promise((resolve, reject) => {

			Vue.http.get('document/' + id + '/download', {params: {asset: index + 1}}).then(response => {

				if( popup )
					popup.location.href = response.body.response.url;
				else
					window.open(response.body.response.url)

				let data = {state: 'read', entityId: id, type: 'resource'};
				store.commit('metadata', data);

				resolve();

			}, reject);
		});
	}
};
