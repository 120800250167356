<template>
	<div class="m-document" :class="{ 'm-document--unread': unread}">
		<div class="m-document__icon" data-icon="file"></div>
		<div class="m-document__infos document-adaptative">
			<div class="m-document__type" v-if="type && !$route.params.section">{{ type.title }}</div>
			<h2 class="m-document__title">
				<span @click="downloadAsset(0)" v-if="data.assets.length === 1">{{ data.title }}</span>
				<span v-else>{{ data.title }}</span>
				<dropdown v-if="data.assets.length > 1">
					<ul class="m-document__dropdown">
						<li v-for="(file, index) in data.assets" :key="index">
							<a @click="downloadAsset(index)">{{ file.title }}</a>
						</li>
					</ul>
				</dropdown>
			</h2>
		</div>
		<div class="m-document__category document-sw" v-if="mod === 'preview'">
			<span v-for="(category, index) in categories" v-if="categories">{{ index?' - ':'' }} {{ category.title }}</span>
		</div>
		<div class="m-document__kind document-sw" v-if="mod === 'preview'">
			<span v-if="kind">{{ kind.title }}</span>
		</div>
		<time class="m-document__date" :class="{'document-sw':mod === 'preview', 'document-mw':mod !== 'preview'}">
			<span v-if="mod !== 'preview'">publié le </span>{{ formatDigitDate(data.createdAt) }}
		</time>
		<div class="m-document__actions document-actions">
			<a class="m-document__share" data-icon="share" @click="share"></a>
			<a class="favorite" :data-icon="favorite?'favorite-full':'favorite'" @click.prevent="updateFavorite" v-if="user.contact"></a>
			<a class="m-document__see" data-icon="see" @click="preview" :class="{'m-document__see--inactive': !data.thumbnail}"></a>
			<div class="m-document__download" data-icon="download" :class="{'m-document__download--inactive': !data.assets || !data.assets.length, 'm-document__download--downloading': downloading}">
				<loader v-if="downloading"></loader>
				<a @click="downloadAsset(0)" v-if="data.assets.length === 1"></a>
				<dropdown v-else-if="data.assets.length > 1">
					<ul class="m-document__dropdown">
						<li v-for="(file, index) in data.assets" :key="index">
							<a @click="downloadAsset(index)">{{ file.title }}</a>
						</li>
					</ul>
				</dropdown>
			</div>
		</div>
	</div>
</template>

<script>

	import documentRepository from '@/repositories/documentRepository';
	import userRepository from '@/repositories/userRepository';

	export default {
		name: "document",
		props:{
			data:{
				default: false,
			},
			mod:{
				default: false
			}
		},
		data(){
			return {
				downloading: false
			}
		},
		computed:{
			type(){
				return this.data.categories.find(category => category.depth === 0 )
			},
			categories(){
				return this.data.categories.filter(category => category.depth === 1 )
			},
			kind(){
				return this.data.categories.find(category => category.depth === 2 )
			},
			user(){
				return this.$user();
			},
			unread(){
				return this.user && this.user.lastLogin < this.data.createdAt && !userRepository.getMetadata(this.data.entity, 'read', this.data.id);
			},
			favorite(){
				return userRepository.getMetadata(this.data.entity, 'favorite', this.data.id);
			}
		},
		methods:{
			downloadAsset(index){
				this.downloading = true;
				documentRepository.download(this.data.id, index).then(
					response=>{
						this.downloading = false;
					},
					response=>{
						this.downloading = false;
					});
			},
			preview(){
				if(this.mod === 'preview' && window.innerWidth > 1024)
					this.$emit('preview')
				else
					this.$popin('preview', {type:'document', item:this.data});
			},
			updateFavorite(){
				userRepository.updateMetadata(this.data.entity, 'favorite', this.data.id);
			},
			share(){
				this.$popin('share', {type:'document', item: this.data});
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-document{
		$c: &;

		display: flex; align-items: center; padding: $half-space $space;

		.accessible-version &{
			& + #{$c}{ border-top: solid 1px $c-border }
		}

		@media #{$to-tablet}{ flex-wrap: wrap; padding: $half-space $space-s; align-items: start }

		&:nth-child(odd){ background: $c-background-lighter }

		&--unread{
			#{$c}__title{ font-weight: bold }
		}

		&__icon{ font-size: 2rem; margin-right: 0.5rem; color: $c-primary; margin-top: -2px }

		&__infos{
			line-height: 1;

			@media #{$to-tablet}{
				flex: 0 0 90%
			}
		}

		&__type{ font-size: $font-xs }

		&__title{ font-size:$font-s; color: $c-primary; cursor: pointer; position: relative }

		&__date{
			font-size: $font-xs;
			@media #{$from-small}{
				&.document-mw{ text-align: right }
			}
			& + *{ margin-left: $space-l }

			@media #{$to-tablet}{ display: none }
		}
		&__actions{
			text-align: right;
			& > *{ display: inline-block; color: $c-primary; cursor: pointer }
			& > * + *{ margin-left: $space-s }
			[data-icon]:before{ font-size: 1.8rem }

			@media #{$to-tablet}{ margin-left: auto; margin-top: $space-s }
		}

		&__download{
			&--downloading:before{ opacity: 0 }
			position: relative;
			> a{ position: overlay(); z-index: 1 }
			.a-loader{ transform: scale(0.65) }
		}
		.a-dropdown{ padding: 0 }

		&__dropdown{
			li{
				text-align: left; transition: allow(background);
				&:hover{ background: $c-background-lighter }
				a{ padding: $space-s $space-m; display: block }
				& + li{ border-top: solid 1px $c-border }
			}
		}

		&__category, &__kind{
			font-size: $font-xs;

			@media #{$to-tablet}{
				width: auto;
			}
		}

		&__see--inactive, &__download--inactive, &__share--inactive{ pointer-events: none; color: $c-background-darker }

		&__see, &__share{
			@media #{$to-tablet}{ display: none }
		}

		.document-sw{
			@media #{$to-tablet}{
				margin-left: 2.5rem;
				&+.document-sw{ margin-left: $space-s }
			}
		}
	}
</style>