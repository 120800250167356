<template>
  <div class="m-subchapter" :class="{'m-subchapter--inactive': !selected && !ended, 'm-subchapter--selected': selected, 'm-subchapter--ended': ended&&!selected}">
    <div class="title-small m-subchapter__title icon-flex" :data-icon="selected ? 'time-five' : (ended ? 'check-circle' : 'lock')">
      <span v-if="data.title">{{data.title}}</span>
      <span v-else-if="chapter < 0">{{ (subchapter+1) }} - Introduction</span>
      <span v-else>{{ chapter+1 }}.{{ (subchapter+1) }}</span>
    </div>
    <div>
      <span class="m-subchapter__reading icon-flex" :data-icon="getIcon()">{{ data.reading_time }} min</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "subchapter",
  props: {
    data: { required: true },
    chapter: { default: 0 },
    subchapter: { default: 0 },
    progress: { default: false }
  },
  computed:{
    selected(){
      return this.chapter === this.progress.chapter.current && this.subchapter === this.progress.subchapter.current
    },
    ended(){
      return this.chapter < this.progress.chapter.max || (this.chapter === this.progress.chapter.max && this.subchapter <= this.progress.subchapter.max )
    }
  },
  methods:{
    getIcon() {

      if( this.data.layout === 'quiz')
        return 'edit';
      else if( this.data.layout === 'foad-video')
        return 'youtube';

      return 'lines';
    }
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-subchapter{
  $c: &;
  background: white; cursor: pointer; padding: $space-s $space-m; position: relative;

  &--inactive{
    color: $c-main; pointer-events: none;
    #{$c}__title{ color: $c-main }
  }

  &--selected{ background: $c-background }

  &--ended{
    #{$c}__title:before{ color: $c-green }
  }

  &__title{
    & + div{ margin-top: 0.5rem }
  }

  &__reading{ font-size: 1.1rem }
  &__current{ font-size: 1.2rem; font-weight: bold; float: right }
}

</style>