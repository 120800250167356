import Vue from "vue";
import cache from "@/services/cache";

export default {

	find(params) {
		return cache.list('news', params);
	},
	get(id){
		return new Promise((resolve, reject) => {
			Vue.http.get('news/'+id).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	}
};
