<template>
	<div class="o-columns">
		<h2 class="title-big" v-if="data.title">{{ data.title }}</h2>
		<div class="o-columns__grid container">
			<div class="o-columns__col" v-for="(col,index) in data.columns" :key="index">
				<div class="o-columns__image">
					<img :src="col.image" v-if="col.image" />

				</div>
				<div class="o-columns__content">
					<h3 class="title-big">{{col.title}}</h3>
					<div class="wysiwyg" v-html="col.wysiwyg"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "columns",
		props:['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-columns {
		margin: $space-xl 0;

		@media #{$from-small}{
			&__grid{ display: flex }
		}

		&__col{
			border-radius: $border-radius; overflow: hidden; background: white;
			& + &{
				@media #{$from-small}{margin-left: $space }
				@media #{$to-tablet}{margin-top: $space }
			}
		}

		&__image{
			padding-top: 55.8%; position: relative; overflow: initial;
			& > *{ position: overlay(); height: 100%; width: 100%; object-fit: cover }
		}

		&__content{ padding: $space-m; text-align: left }
	}
</style>