<template>
	<div class="o-company-infos">
		<h2 class="o-company-infos__title title" v-if="interface.title">{{ interface.title }}</h2>
		<div v-if="company && user">
			<div class="secondary">
				<checkup mod="details" type="company" :data="checkup" v-if="checkup"></checkup>
			</div>
			<form @submit.prevent="updateProfile" enctype="multipart/form-data" ref="form" id="company_update">
				<div class="o-company-infos__heading">
					<div class="o-company-infos__thumbnail">
						<img v-if="company.logo" :src="company.logo"/>
						<span class="o-company-infos__thumbnail-placeholder" v-else>{{ company.name.substr(0,1) }}</span>
						<div class="o-company-infos__thumbnail-update" @click="changeLogo" v-if="user.isLegalRepresentative">
							<span class="desktop">Mettre à jour le logo</span><span class="mobile" data-icon="pencil"></span>
							<input id="logo" type="file" name="logo" class="input--hidden" @change="updateLogo">
						</div>
					</div>
					<div class="o-company-infos__name">
						<div class="title-big text-uppercase">{{ company.name }}</div>
						<div>{{company.brand}}</div>
					</div>
				</div>

				<h3 class="title-big">Informations professionnelles</h3>
				<div class="o-company-infos__columns primary">
					<div>
						<h4 class="subtitle">Adresse du siège social</h4>
						<div>
							{{company.street}}<br/>
							{{company.zip}} {{company.city}}
						</div>
					</div>
					<div v-if="company.mails && company.mails.length">
						<h4 class="subtitle">Adresse courrier</h4>
						<div>
							{{company.mails[0].street}}<br/>
							{{company.mails[0].zip}} {{company.mails[0].city}}
						</div>
					</div>
				</div>

				<div class="o-company-infos__columns primary">
					<div v-if="company.siren">
						<h4 class="subtitle">Numéro de siren</h4>
						<div>
							{{company.siren}}
						</div>
					</div>
					<div v-if="company.legalForm">
						<h4 class="subtitle">Forme juridique</h4>
						<div>
							{{company.legalForm}}
						</div>
					</div>
				</div>
				<div class="o-company-infos__columns" v-if="user.isLegalRepresentative">
					<div class="secondary">Si les informations de votre agence ne sont pas à jour, envoyez-nous votre Kbis actualisé et nous procéderons aux modifications.</div>
				</div>

				<vue-form-generator tag="div" :schema="form" :model="company" :options="formOptions" class="o-company-infos__form" @model-updated="changesUpdated"></vue-form-generator>

				<div class="bottom-right" v-if="user.isLegalRepresentative">
					<submit text="Enregistrer" text-success="Enregistré" :loading="loading" :success="success"></submit>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "company-infos",
		props:['data','interface'],
		data(){
			let self = this;
			return{
				company: false,
				loading: false,
				success: false,
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
				formLogo:{
					fields: [{
						type: "input",
						inputType: "file",
						inputName: "logo",
						model: "logo",
						styleClasses: "input--hidden"
					}]
				},
				form: false,
				allowEvent: false
			}
		},
		computed:{
			user(){
				return this.$user()
			},
			checkup(){
				return this.$store.getters.checkup()
			}
		},
		methods:{
			changesUpdated(){
				if(this.allowEvent)
					this.$store.commit('changesSaved', false);
			},
			updateProfile(){

				let self = this;

				this.loading = true;
				let formData = new FormData( this.$refs.form );

				companyRepository.update(this.company.id, formData).then(response => {

					this.loading = false;
					this.success = true;
					this.$store.commit('changesSaved', true);

					setTimeout(function () {
						self.success = false;
					}, 1500);

				},response=>{
					this.loading = false;
				})
			},
			changeLogo(){
				document.getElementById('logo').click()
			},
			updateLogo(e){

				let self = this;

				if (e.target.files && e.target.files[0]) {

					let reader = new FileReader();

					reader.onload = function(e) {
						self.company.logo = e.target.result;
					}

					reader.readAsDataURL(e.target.files[0]);
				}
			},
		},
		mounted() {
			companyRepository.get().then(company=>{
				this.company = company;

				this.form = {
					groups:[
						{
							legend: "Coordonnées",
							fields: [
								{
                  type: "tel-custom",
									inputName: "phone",
									model: "phone",
									styleClasses: "input--one-third fields-label",
									label: "Standard téléphonique",
									disabled: !this.user.isLegalRepresentative
								},
								{
									type: "input",
									inputType: "email",
									inputName: "email",
									maxlength: 60,
									model: "email",
									required: true,
									styleClasses: "fields-label",
									label: "Email de réception (flash infos, bulletins d'informations, etc)",
									disabled: !this.user.isLegalRepresentative
								},
								{
									type: "input",
									inputType: "text",
									inputName: "website",
									model: "website",
									styleClasses: "fields-label",
									label: "Site internet",
									disabled: !this.user.isLegalRepresentative
								}
							]
						},
						{
							legend: "Réseaux sociaux",
							fields:[
								{
									type: "input",
									inputType: "text",
									inputName: "facebook",
									model: "facebook",
									styleClasses: "fields-label",
									label: "Compte Facebook",
									disabled: !this.user.isLegalRepresentative
								},
								{
									type: "input",
									inputType: "text",
									inputName: "twitter",
									model: "twitter",
									styleClasses: "fields-label",
									label: "Compte Twitter",
									disabled: !this.user.isLegalRepresentative
								}
							]
						}
					]

				}

				setTimeout(()=>{this.allowEvent = true},1000)
			})
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-company-infos{
		&__heading{
			display: flex; align-items: center;

			&+ *{ margin-top: $space }
		}

		&__name{
			text-transform: uppercase;
			.title-big + div{ margin-top: 0 }
		}

		&__thumbnail{
			width: 20%; padding-top: 20%; border: solid 1px $c-border; border-radius: 50%; overflow: hidden; margin-right: $space;
			position: relative;

			@media #{$to-phone}{
				width: 40%; padding-top: 40%; margin-left: auto; margin-right: auto; font-size: $font-xl;
			}

			img{ position: absolute; top: 0; left: 0; height: 100%; width: 100%; object-fit: cover }

			&-update{
				color: white; display: flex; align-items: center; justify-content: center; text-align: center;
				position:absolute; bottom: 0; top: 50%; left: 0; right: 0; cursor: pointer; padding-left: $space-m; padding-right: $space-m;
				>*{ z-index: 1; position: relative }
				&:after{ background: $c-primary; opacity: 0.5; position: overlay(); content: '' }
			}

			&-placeholder{ position: overlay(); background: $c-background; color: rgba($c-primary,0.15);
				display: flex; align-items: center; justify-content: center; font-size: 8rem; text-transform: uppercase }
		}

		&__columns{
			@media #{$from-small}{
				display: flex;

				.title-big + &{ margin-top: $space }
				& > *{
					flex: 1;
					& + *{ margin-left: $space }
				}
			}

			@media #{$to-tablet}{
				& > * + *{ margin-top: $space }
			}
			& + &{ margin-top: $space }
		}

		&__form{
			& > *{ display: flex; flex-wrap: wrap; margin-top: $space }

			legend{ font-size: $font-xxl; font-weight: bold; color: $c-primary; width: 100% }

			.form-group{
				width: 100%; margin-top: $space;

				input{ width: 100% }
			}

			@media #{$from-small}{
				.input--one-third{
					width: calc(33% - #{$half-space});
					& + .input--two-third{ margin-left: $space }
				}
				.input--two-third{ width: calc(66% - #{$half-space})}
			}

			.readonly input{ color: $c-main; font-style: italic }
		}

		.input--hidden{ display: none }
	}

</style>