<template>
	<div class="o-infos block">
		<h2 class="title">{{interface.title}}</h2>
		<div v-if="config && config.option">
			<div class="o-infos__item" v-if="interface.mod === 'signatures' && config.option.signature_video">
				<p class="subtitle">Comment ça fonctionne ?</p>
				<router-link :to="'/'+config.option.signature_video.type+'/'+config.option.signature_video.value" class="icon-flex question-mark">
					Laissez-vous guider pour vos premières utilisations (tutoriel & vidéo).
				</router-link>
			</div>
			<div class="o-infos__item" v-if="interface.mod === 'signatures' && config.option.signature_principles">
				<p class="subtitle">Principes de validation</p>
				<a :href="config.option.signature_principles" target="_blank" class="icon-flex question-mark">
					Consultez les principes de validation des identités par Universign.
				</a>
			</div>
			<div class="o-infos__item">
				<p class="subtitle">Conditions générales de vente</p>
				<a :href="config.option.cgv.value" data-icon_after="download" class="icon-flex" target="_blank">
					Téléchargez nos conditions générales de vente en PDF
				</a>
			</div>

		</div>
	</div>
</template>

<script>

	export default {
		name: "infos",
		props:['data','interface'],
		data(){
			return{
				cgv: false,
				video: false,
				principles: false
			}
		},
		computed:{
			config(){
				return this.$config()
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-infos{
		.subtitle + *{ margin-top: 0.5rem }

		&__item{
			& + &{ margin-top: $space-m }
		}

		.question-mark{
			&:after{ content: '?'; border: solid 1px $c-primary; font-size: 2.2rem; width: 2.8rem; border-radius: 50%; text-align: center; flex-shrink: 0; color: $c-primary }
		}
	}

</style>