<template>
	<div v-if="platform==='mobile'||platform==='tablet'">
    <field-input :schema="getSchema()" inputType="date" :value="value"></field-input>
  </div>
  <FunctionalCalendar v-else ref="Calendar" :is-layout-expandable="false" :date-format="'dd/mm/yyyy'" v-model="calendar" :new-current-date="schema.default" :is-modal='true' :is-date-picker='true' :isAutoCloseable='true' :limits="limits" :changeMonthFunction='true' :changeYearFunction='true' :disabledDates="disabledDates">
    <template v-slot:datePickerInput="props">
      <input class="vfc-single-input" type="text" v-model="formattedDate" placeholder="jj/mm/aaaa" :required="schema.required" :id="getFieldID(schema)" :name="getFieldName(schema)" :class="schema.fieldClasses" autocomplete="off">
      <span data-icon="calendar" class="vfc-icon"></span>
    </template>
  </FunctionalCalendar>
</template>

<script>
	import { abstractField, fieldComponents } from "vue-form-generator";

	export default {
		name: "field-calendar-custom",
    components: fieldComponents,
		mixins: [ abstractField ],
		watch:{
			'calendar.selectedDate'(newValue){
				this.updateModelValue(this.convertDate(newValue, '/', '-', true), '');
			}
		},
		data(){
			return{
				calendar:{
          selectedDate: false,
          dateRange : {
            start: '',
            end: ''
          }
        },
				disabledDates: this.schema.disabledDates,
				limits: {min: this.convertDate(this.schema.min,'-', '/'), max: this.convertDate(this.schema.max,'-', '/')},
				loaded: false,
				platform: window.platform
			}
		},
    computed:{
      formattedDate: {
        get() {
          if( this.calendar.selectedDate ){
            let date = this.calendar.selectedDate.split('/');
            if( date.length === 3 )
              return this.formatDateInput(date[1]+'/'+date[0]+'/'+date[2]);
          }
          return ''
        },
        set(newValue) {

        	let date = newValue.split('/');

          if( date.length === 3 && date[0].length && date[1].length && date[2].length===4 )
            this.setDate(date[1]+'/'+date[0]+'/'+date[2]);

          return newValue;
        }
      }
    },
    methods:{
      convertDate(date, split, sep, leadingZero){

        if( typeof date != 'string')
          return '';

        date = date.split(split);

        if( date.length === 3 ){

          if( leadingZero )
            return (parseInt(date[2])<10?'0':'')+date[2]+sep+(parseInt(date[1])<10?'0':'')+date[1]+sep+(parseInt(date[0])<10?'0':'')+date[0]
          else
            return date[2]+sep+date[1]+sep+date[0]
        }

        return '';
      },
		  getSchema(){

		    let schema = this.schema;
        schema.type = 'input';
        schema.inputType = 'date';
        schema.format = "YYYY-MM-DD";

		    return schema;
      },
      setDate(value){

        let date = new Date(value);
        if( date.getTime() === date.getTime() ){
          this.calendar.selectedDate = parseInt(date.getDate())+'/'+parseInt(date.getMonth()+1)+'/'+date.getFullYear();
          this.$refs.Calendar.ChooseDate(this.calendar.selectedDate);
        }
      }
    },
		mounted(){

      if(this.value && this.platform==='desktop')
        this.setDate(this.value);

			this.loaded = true;
		}
	};
</script>

