<template>
	<form @submit.prevent="nextSubStep" id="caci-register_next">
		<div v-if="subStep === 1">
			<vue-form-generator tag="div" :schema="form.contact" :model="model"  :options="formOptions" :ref="'form'+subStep"></vue-form-generator>
			<div class="bottom-fixed bottom-right">
				<button type="submit" class="button button--secondary">Suivant</button>
			</div>
		</div>

		<div v-else-if="subStep === 2">
			<vue-form-generator tag="div" :schema="form.activity" :model="model" :options="formOptions" :ref="'form'+subStep"></vue-form-generator>

			<div class="bottom-between">
				<button class="button" @click.prevent="prevSubStep">Retour</button>
				<button type="submit" class="button button--secondary">Suivant</button>
			</div>
		</div>

		<div v-else-if="subStep === 3" class="m-caci-register__summary">
			<h3><b>Récapitulatif</b></h3>
			<p>Attention : merci de vérifier l'exactitude des données ci-dessous.<br>
				Après avoir cliqué sur le bouton <b>&laquo; Valider &raquo;</b>, ne serez plus en mesure de modifier ces informations.</p>

			<div class="m-caci-register__background">
				<h4 class="subtitle">à propos de vous</h4>
				<button @click.prevent="subStep=1" class="button m-caci-register__modify">Modifier</button>
				<div>
					<p>
						Civilité : <b>{{ model.contact.civility }}</b>
					</p>
					<p>
						Nom : <b>{{ model.contact.lastname }}</b>
					</p>
					<p>
						Prénom : <b>{{ model.contact.firstname }}</b>
					</p>
					<p>
            Portable : <b>{{ model.contact.addresses[0].phone }}</b>
					</p>
					<p>
						Adresse postale : <b>{{ model.contact.addresses[0].street + ' - ' + model.contact.addresses[0].zip + ' ' + model.contact.addresses[0].city }}</b>
					</p>
					<p>
						Date de naissance : <b>{{ formatDate(model.contact.birthday) }}</b>
					</p>
					<p>
						Lieu de naissance : <b>{{ model.contact.birthPlace }}</b>
					</p>
				</div>

				<h4 class="subtitle">Votre activité</h4>
				<button @click.prevent="subStep = 2" class="button m-caci-register__modify">Modifier</button>
				<div>
					<p>
						Numéro RSAC : <b class="secondary" v-if="model.contact.rsac === ''">N.C.</b><b v-else>{{ model.contact.rsac }}</b>
					</p>
					<p>
						Nature de l'activité : <b>{{ model.contact.legalForm}}</b>
					</p>
					<p>
						<span v-if="model.contact.hasRcp === 'oui'">Vous déclarez être assuré auprès de <span v-if="model.contact.rcp==='asseris'">Asseris</span><span v-else>{{ model.contact.insurer}}</span> pour une RCP.</span>
						<span v-else>Vous déclarez ne pas être assuré pour une RCP.</span>
					</p>
				</div>
			</div>

			<vue-form-generator tag="div" :schema="form.declaration" :model="model" :options="formOptions" ref="declaration"></vue-form-generator>
			<div class="bottom-right">
				<span class="v-login__sync" v-if="wait">Veuillez patienter...</span>
				<submit :loading="loading" text="Valider" :success="validated" text-success="Validé" :error="error" :text-error="error"></submit>
			</div>
		</div>
	</form>
</template>

<script>
	import contactRepository from '@/repositories/contactRepository';
	import userRepository from "@/repositories/userRepository";

	export default {
		name: "caci-register-infos",
    props:{
			subStep:{
				default: 1
			}
		},
		data(){

			let self = this;

			return{
				canAdd: false,
				validated: false,
				loading: false,
				wait: false,
				model:{
					declaration: false,
					contact:{
						firstname: '',
						lastname: '',
						civility: '',
						legalForm: 'Micro Entrepreneur',
						rcp: '',
						hasRcp: '',
						insurer: '',
						claims: false,
						rsac: '',
						birthday:'',
						birthPlace:'',
						addresses:[
							{
								isHome:true,
								isActive:true,
                isMain:true,
								email:'',
								zip:'',
								street:'',
								city:'',
								phone:'',
								country:'France'
							}
						]
					}
				},
				error: false,
				formOptions: {
					validateAfterLoad: false,
					validateAfterChanged: true
				},
				form:{
					contact:{
						groups: [
							{
								legend: "Vos informations personnelles",
								styleClasses: "form-group-title"
							},
							{
								styleClasses: ["form-group-background","form-group-columns"],
								fields:[
									{
										type: "radios",
										model: "contact.civility",
										styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
										label: "Civilité",
										required: true,
										values: [
											"Monsieur",
											"Madame"
										]
									},
									{
										type: "input",
										inputType: "text",
										model: "contact.lastname",
										required: true,
										minlength: 2,
										maxlength: 60,
										styleClasses: ["fields-label","field--half"],
										label: "Nom",
                    placeholder: "Dupond"
									},
									{
										type: "input",
										inputType: "text",
										model: "contact.firstname",
										required: true,
										minlength: 2,
										maxlength: 60,
										styleClasses: ["fields-label","field--half"],
										label: "Prénom",
                    placeholder: "Jean"
									},
                  {
                    type: "tel-custom",
                    model: "contact.addresses[0].phone",
	                  hint: "<span class='error'>Attention : vous devez renseigner un numéro de téléphone mobile. Les numéros de téléphone fixes ne sont pas acceptés.</span>",
                    styleClasses: ["fields-label","field--half","field--hint"],
                    label: "Tél. portable perso",
	                  onlyCountries: ['FR','GP','MQ','GF','YT','PM','BL','MF','WF','PF','NC'],
                    required: true,
                    placeholder: "0600000000"
                  },
									{
										type: "input",
										inputType: "text",
										required: true,
										model: "contact.addresses[0].street",
										styleClasses: "fields-label",
										label: "Adresse personnelle",
										maxlength: 60,
                    placeholder: "28 rue de Lille"
									},
									{
										type: "input",
										inputType: "text",
										required: true,
										model: "contact.addresses[0].zip",
										styleClasses: ["fields-label","field--half"],
										label: "code postal",
                    placeholder: "75000"
									},
									{
										type: "input",
										inputType: "text",
										required: true,
										maxlength: 50,
										model: "contact.addresses[0].city",
										styleClasses: ["fields-label","field--half"],
										label: "Ville",
                    placeholder: "Paris"
									},
									{
										type: "select",
										required: true,
										maxlength: 50,
										model: "contact.addresses[0].country",
										styleClasses: ["fields-label","field--half"],
                    selectOptions:{
                      noneSelectedText: "---"
                    },
										label: "Pays",
                    values: ["France", "Autre"],
                    onChanged(model, newVal, oldVal, field){

                      if( newVal === 'Autre' ){
                        model.contact.addresses[0].country = oldVal;
                        this.$alert({title:"Vous résidez hors France", text:"Pour continuer votre souscription, veuillez contacter un conseiller : <a href=\"mailto:contact@asseris.fr\">contact@asseris.fr</a>"});
                      }
                    }
									},
									{
										type: "calendar-custom",
										model: "contact.birthday",
										required: true,
										styleClasses: ["fields-label","field--half","field--bottom"],
										label: "Date de naissance",
										min: '1920-01-01',
                    max: this.getDateFromYear(18, true),
                    default : this.getDateFromYear(18, false)
									},
									{
										type: "input",
										inputType: "text",
										required: true,
										model: "contact.birthPlace",
										styleClasses: ["fields-label"],
										label: "Ville de naissance",
                    placeholder: "Toulouse"
									},
								]
							}
						]

					},
					activity:{
						groups: [
							{
								legend: "Votre activité",
								styleClasses: "form-group-title"
							},
							{
								styleClasses: ["form-group-background"],
								fields:[
									{
										type: "input",
										inputType: "text",
										model: "contact.rsac",
										styleClasses: "fields-label",
										label: "Numéro RSAC",
                    placeholder: "123 456 789",
										hint: "<div class='field-hint-text'><p>Notre réglementation professionnelle impose que l'agent commercial en immobilier soit immatriculé au Registre Spécial des Agents Commerciaux (RSAC) auprès du greffe du tribunal de commerce de son domicile (article 4 de la loi n°70-9 du 2 janvier 1970).</p><p><b>Attention : seules les personnes physiques sont habilitées à recevoir l'attestion d'habilitation. Le contrat proposé est conditionné à l'obtention d'un n° de registre spécial agent commercial.</b></p></div>"
									},
									{
										type: "select",
										model: "contact.legalForm",
										styleClasses:"fields-label",
										required: true,
										label: "Nature de l'activité",
										selectOptions:{
											noneSelectedText: "---"
										},
										values: [
											{ name: "Entrepreneur Individuel (EI)", id: 'EI' },
											{ name: "Micro Entrepreneur", id: 'Micro Entrepreneur' },
											{ name: "Entrepreneur Individuel à Responsabilité Limitée (EIRL)", id: 'EIRL' },
											{ name: "Micro Entrepreneur + EIRL", id: 'Micro Entrepreneur + EIRL' }
										]
									},
									{
										type: "radios",
										model: "contact.hasRcp",
										styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
										label: "êtes-vous actuellement assuré en rcp ?",
										required: true,
										values: [
                      { name: "Non", value:"non" },
                      { name: "Oui", value:"oui" }
										]
									},
									{
										type: "radios",
										model: "contact.rcp",
										styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
										label: "Auprès de quel assureur ?",
										required: true,
										values: [
											{ name: "Serenis par l'intermédiaire d'Asseris", value:"asseris" },
											{ name: "Autre", value:"other" }
										],
										visible(){ return self.model.contact.hasRcp === "oui" }
									},
									{
										type: "input",
										inputType: "text",
										model: "contact.insurer",
										required: true,
										styleClasses: "fields-label",
										label: "Nom de votre assureur",
										visible(){ return self.model.contact.hasRcp === "oui" && self.model.contact.rcp === 'other' }
									},
									{
										type: "checkbox-custom",
										model: "contact.claims",
										required: true,
										label: "Je certifie n'avoir jamais eu plus de 3 de sinistres et / ou une charge globale sinistre de plus de 3000 €, au cours des 3 dernières années.",
										visible(){ return self.model.contact.hasRcp === "oui" && self.model.contact.rcp === 'other' }
									}
								]
							}
						]
					},
					declaration:{
						fields:[
							{
								type: "checkbox-custom",
								model: "declaration",
								required: true,
								styleClasses: "field-declaration",
								label: "Je certifie sur l'honneur l'exactitude des informations saisies-ci-dessus.",
								get(model) {
									if (model)
										return model.declaration === true ? 'checked' : '';
								}
							}
						]
					}
				}
			}
		},
		methods: {
			createContact() {

				this.loading = true;

				this.model.contact.addresses[0].email = this.user.login;

				let self = this;

				let waitTimer = setTimeout(()=>{ self.wait = true }, 2000);

				contactRepository.create(this.model.contact).then(response => {
					clearTimeout(waitTimer);

					this.validated = true
					userRepository.get().then( response => {
						this.loading = false;
						this.$emit('nextStep');
					})

				}, response=>{
					clearTimeout(waitTimer);
					this.loading = false;
					this.wait = false;
				})
			},
			nextSubStep(){
				if( this.subStep === 3)
					this.createContact();
				else
					this.$emit('updateSubStep', this.subStep + 1);
			},
			prevSubStep(){
				this.$emit('updateSubStep', this.subStep - 1);
			}
		},
		computed:{
			user(){ return this.$user() }
		},
		watch: {
			subStep(val){
				this.$emit('updateSubStep',val)
				this.$scrollTop();
			}
		}
	}
</script>

<style lang="scss">

@import '../../environment';

.field--hint .hint{
  position: absolute; left: 100%; width: 100%; top: 40%; margin-left: 3rem;
  span{ display: block }
  @media #{$to-tablet}{
    position: static; margin-left: 0; margin-top: 1rem;
  }
}
</style>