<template>
	<div class="o-insurances container" v-if="(user.isLegalRepresentative && membership && membership.vhs.enabled) || (user.isCommercialAgent && membership)">
		<div class="block">
			<h3 class="title">Vos contrats d'assurances</h3>
			<div class="o-insurances__grid no-padding">
				<div class="o-insurances__content">
					<div class="o-insurances__uptitle title title--small">VHS</div>

					<div class="title-big">{{ data.title }}</div>

					<div v-html="data.description" class="o-insurances__text"></div>
					<div class="o-insurances__button" v-if="(user.isLegalRepresentative || user.isCommercialAgent) && (membership.pj.enabled || membership.rcp.enabled)">
						<router-link class="button" to="/documents/documents-administratifs">Consulter mes documents administratifs</router-link>
					</div>
					<div class="o-insurances__button" v-if="user.isCommercialAgent && (!membership.pj.enabled || !membership.rcp.enabled)">
						<a class="button button--secondary" @click="showRegistration">Souscrire à une autre assurance</a>
					</div>

				</div>
				<div v-if="data.image">
					<img :src="data.image+'?w=720&h=400'" alt="Assurances" class="o-insurances__image" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: "insurances",
		props:['data'],
		methods:{
			showRegistration(){
				this.$popin('caci-register', this.user);
			}
		},
		computed:{
			user(){ return this.$user() },
			membership(){ return this.$store.getters.membership() }
		},
		mounted() {
			if( window.location.search === '?continue' )
				this.showRegistration();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';

	.o-insurances{

		&__grid{

			display: flex;

			@media #{$to-tablet}{
				flex-direction: column-reverse;
			}
			@media #{$from-small}{

				& > *{ width: 50% }
			}
		}

		&__uptitle{
			color: $c-secondary;
			&+*{ margin-top: $space-s }
		}

		&__content {
			position: relative; padding: $space-m;
			@media #{$from-small}{
				padding: $space-ll
			}
		}

		&__text{
			color: $c-primary;
			& + *{ margin-top: $space }
		}

		&__image{ width: 100%; max-height: 40rem; height: 100%; object-fit: cover; border-top-right-radius: 4px; border-bottom-right-radius: 4px }

		&__button + &__button{ margin-left: 0; margin-top: 1rem }
	}

</style>