<template>
	<div class="o-video container">
		<h3 class="title-big" v-if="data.title">{{data.title}}</h3>

		<div class="o-video__container" @click="playVideo()">
			<transition name="fade">
				<div class="o-video__poster" v-if="overlay">
					<img :src="data.poster+'?w=1080&h=608'" alt="video" v-if="data.poster" />
					<div class="play"></div>
				</div>
			</transition>

			<transition name="fade">
				<div class="o-video__iframe" v-if="data.video&&!overlay" >
					<iframe allowFullScreen="allowFullScreen" :src="'https://www.youtube.com/embed/'+ youtubeId(data.video) +'?showinfo=0&autoplay=1&iv_load_policy=3&rel=0&modestbranding=1&enablejsapi=1'" allowtransparency="true" frameborder="0"></iframe>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		name: "video-component",
		props: ['data'],
		data: function(){
			return{
				overlay: true
			}
		},
		methods: {
			playVideo:function(){
				this.overlay = false;
			}
		}
	}
</script>

<style lang="scss">

@use "sass:math";
@import '../../environment';
	.o-video{

		padding:$space-xl 0;
		&__container{ @include aspect-ratio(math.div(16,9)); width: 75%; margin: 0 auto }

		iframe{ position: overlay(); width: 100%; height: 100%; background: #000 }

		&__poster{
			width: 100%; height: 100%; border-radius: $border-radius; overflow: hidden; display: flex; position:absolute;
			align-items: center; justify-content: center; cursor: pointer;
			&:after{ content: ''; position:overlay(); background: rgba(0,0,0,0.4); z-index: 1 }
			img{ position:overlay(); object-fit: cover; height: 100%; width: 100% }
			.play{ position:relative; z-index: 2; border-color: transparent transparent transparent white; width: $space-m*2; height: $space-m*2;
				border-width: $space-m 0 $space-m $space-m*2; border-style: solid;
			}
		}
	}
</style>