<template>
	<iframe width="100%" height="600px" :src="interface.url"></iframe>
</template>

<script>
export default {
	name: "iframe-component",
	props:['data', 'interface']
}
</script>

<style scoped>

</style>