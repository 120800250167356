<template>
	<div class="o-gallery container">
		<h3 class="title-big" v-if="data.title">{{data.title}}</h3>
		<p v-if="data.subtitle" class="o-gallery__subtitle">{{data.subtitle}}</p>
		<a :href="data.file" v-if="data.file" target="_blank" class="o-gallery__file">{{data.file_label}}</a>

		<div class="o-gallery__gallery">
			<figure v-for="(image,index) in data.gallery" :key="index"  class="o-gallery__image">
				<img :src="image.url+'?h=300'" :alt="image.alt" />
				<figcaption v-if="image.caption" class="o-gallery__description">{{image.caption}}</figcaption>
			</figure>
		</div>
	</div>
</template>

<script>
	export default {
		name: "gallery",
		props: ['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-gallery{
		text-align: left; margin: $space*2 auto;

		&__gallery{
			margin-top: $space; width: 100%; overflow-x: auto; white-space: nowrap; padding-bottom: 2rem; -webkit-overflow-scrolling: touch;
		}

		&__subtitle{
			color: $c-primary;

			& + *{ margin-top: $space-s }
		}
		&__file{ display: block; text-decoration: underline; color: $c-primary }

		&__image{
			padding: 0 $space-m; display: inline-block; vertical-align: top;
			img{ height: 30rem; width: auto; height: 100% }
			&:first-child{ padding-left: 0 }
			&:last-child{ padding-right: 0 }
		}

		&__description{ text-align: center; padding-top: $space-s; font-family: 'Optima', Helvetica, serif; font-style: italic }
	}
</style>