<template>
	<div class="o-experts container">
		<div class="block">
			<h2 class="title o-experts__title" v-if="data.title">
				{{ data.title }}
			</h2>
		</div>
		<div class="o-experts__filter">
			<vue-form-generator tag="div" :schema="filters" :model="params" :options="formOptions" @model-updated="onModelUpdated"></vue-form-generator>
		</div>
		<div class="o-experts__list" v-infinite-scroll.full="loadMore">
			<expert v-for="(expert, index) in experts" :key="expert.id" :data="expert"></expert>
			<loader v-if="loading"></loader>
		</div>
	</div>
</template>

<script>
	import contactRepository from '@/repositories/contactRepository';

	export default {
		name: "experts",
		props:['data'],
		data(){

			let self = this;

			return{
				experts: false,
				count: -1,
				loading: false,
				params: {
					offset: 0,
					limit: 21,
					filter: '',
					location: '',
					city: ''
				},
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
				filters: {
					groups: [{
						styleClasses: "main-fields fields-label",
						fields: [
							{
								type: "googleAddress",
								inputName: "location",
								model: "city",
								autocomplete: false,
								onPlaceChanged: function(value, place, rawPlace, model, schema){
									model.location = rawPlace.geometry.location.lat()+','+rawPlace.geometry.location.lng();
									self.searchExperts();
								},
								styleClasses:['input--googleAddress']
							}
						]
					}]
				}
			}
		},
		methods:{
			searchExperts(){
				this.query(0);
			},
			onModelUpdated(newVal, schema) {

				if( schema === 'city')
					return;

				this.searchExperts();
			},
			loadMore(){

				if( this.experts.length >= this.count || !this.experts.length )
					return;

				this.query(this.params.offset + this.params.limit)
			},
			query(offset){

				if( this.loading === true )
					return;

				this.params.offset = offset;

				this.loading = true;

				contactRepository.findExperts(this.params).then(response=>{
					this.loading = false;
					this.experts = response.items;
					this.count = response.count;
				}, e => {
					self.loading = false;
				});
			}
		},
		mounted(){
			this.searchExperts();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-experts{
		&__list{
			position:relative; min-height: 40rem;

			@media #{$from-small}{
				display: flex; flex-wrap: wrap; margin-left: -0.5rem; margin-right: -0.5rem;
			}
			.m-expert{ flex-basis: 33.3%; padding: 0.5rem }

		}
		&__filter{
			background: #fff; border-radius: 4px; padding: $space; margin-bottom: $space-m; margin-top: $space-l; box-shadow: $subtle-box-shadow;
		}
	}

</style>