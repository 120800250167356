<template>
	<main class="v-migrate"></main>
</template>

<script>

	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "Register",
		computed:{
			user(){ return this.$user() }
		},
		mounted() {

			let migrate = this.$route.params.id;

			if(migrate) {
				companyRepository.getRepresentatives().then(contacts=>{

					contacts.forEach(contact=>{

						if( contact.id === parseInt(migrate) && !contact.hasAccount ){

							this.$popin('migrate', contact, false)
						}
					})
				})
			} else {
				this.$popin('alert-migrate', this.user, false)
			}


		}
	}
</script>