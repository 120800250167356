<template>
	<div class="a-caci-register-payment">
		<form @submit.prevent="subscribe" id="caci-register_payment">
			<div v-if="subStep === 1">
				<vue-form-generator tag="div" :schema="form.insurance" :model="insurance" :options="formOptions" ref="form"></vue-form-generator>
				<div class="bottom-right">
					<span class="v-login__sync" v-if="wait">Veuillez patienter...</span>
					<span class="v-login__sync" v-if="quoting">Génération du devis en cours...</span>
					<submit class="button--secondary" text="Suivant" :loading="loading" :disabled="(!insurance.pj&&!insurance.rcp) || !dateValid"></submit>
				</div>
			</div>
			<div v-else-if="subStep === 2 || subStep === 3" class="m-caci-register__summary">
				<h3><b>Mon devis</b></h3>

				<div class="m-caci-register__background">
					<div class="m-caci-register__price">
						<div>
							<h4 class="subtitle">Cotisation CACI</h4>
							<p>Pour la période du {{ formatDigitDate(quote.date_begin_contract) }} au {{ formatDigitDate(quote.date_end_contract) }}</p>
						</div>
						<div class="secondary">{{ formatPrice(quote.signin_amount) }}</div>
					</div>

					<div class="m-caci-register__price">
						<div>
							<h4 class="subtitle"><span v-if="insurance.rcp">Responsabilité civile professionnelle</span> <span v-if="insurance.rcp&&insurance.pj">+</span> <span v-if="insurance.pj">protection juridique</span></h4>
							<p>Pour la période du {{ formatDigitDate(quote.date_begin_contract) }} au {{ formatDigitDate(quote.date_end_contract) }}</p>
						</div>
						<div class="secondary">
							<div>{{ formatPrice(quote.insurance_amount) }}</div>
							<div>(dont {{ formatPrice(quote.insurance_fees) }} de frais d'ouverture)</div>
						</div>
					</div>
				</div>

				<div v-if="subStep === 2">
					<vue-form-generator tag="div" :schema="form.declaration" :options="formOptions" ref="form"></vue-form-generator>
					<div class="bottom-right">
						<button class="button button--white" @click.prevent="prevSubStep">Modifier</button>
						<submit class="button--secondary" text="Télécharger" :loading="loading"></submit>
					</div>
				</div>
				<div v-else>
					<p><b>Après avoir cliqué sur le bouton suivant, vous serez automatiquement redirigé vers l'espace sécurisé de paiement en ligne de notre établissement bancaire.</b></p>
					<div class="bottom-right">
						<button class="button button--white" @click.prevent="prevSubStep">Modifier</button>
						<submit class="button--secondary" text="Paiement sécurisé par CB" :loading="loading"></submit>
					</div>
				</div>
			</div>
			<div v-else>
				<h4 class="subtitle">Vérification du paiement</h4>
				<br/>
				<p>Merci de patienter...</p>
				<div class="bottom-right">
					<submit class="button--secondary" text="Continuer" :loading="loading"></submit>
				</div>
			</div>
		</form>
		<div ref="formPayment" class="hidden"></div>
	</div>
</template>

<script>
	import VueFormGenerator from 'vue-form-generator';
	import contactRepository from '@/repositories/contactRepository';

	export default {
		name: "caci-register-payment",
		props:{
			subStep:{
				default: 1
			}
		},
		data(){

			let now = new Date();
			let self = this;

			let minDate = now.getMonth()>=(process.env.VUE_APP_ENV==='dev'?10:11)?(now.getFullYear()+1)+'-01-01':now.toISOString().split("T")[0];

			return{
				validated: false,
				loading: false,
				quote: false,
				dateValid: true,
				wait: false,
				quoting: false,
				insurance:{
					rcp: false,
					pj: false,
					date: minDate
				},
				error: false,
				formOptions: {
					validateAfterLoad: false,
					validateAfterChanged: true
				},
				form:{
					insurance:{
						groups: [
							{
								legend: "Les assurances qui vous intéressent",
								styleClasses: "form-group-title"
							},
							{
								styleClasses: "form-group-background",
								fields:[
									{
										type: "checkbox-custom",
										inputName: "rcp",
										model: "rcp",
										styleClasses:["fields-label","field-secondary"],
										label: "Responsabilité civile profesionnelle (RCP)",
										hint: "<div  class='field-hint-text'><p>La loi ALUR dite loi DUFLOT introduira dans la loi HOGUET, en 2014, de nouvelles dispositions concernant la formation obligatoire des collaborateurs ainsi que l’obligation de souscrire une assurance RCP pour les agents commerciaux.<br/><br/>\n" +
											"Extrait de l’Article 4 de la Loi 70-9 du 2 janvier 1970 : « Les Agents Commerciaux doivent contracter une assurance contre les conséquences pécuniaires de leur responsabilité civile professionnelle, dans les conditions fixées par décret en Conseil d’État. »<br/><br/>\n" +
											"Le cabinet ASSERIS a négocié pour les mandataires indépendants un contrat Responsabilité Civile Professionnelle et Exploitation (RCP-RCE) UNIQUE.</p></div>",
										visible(){ return !self.membership || !self.membership.rcp.enabled }
									},
									{
										type: "checkbox-custom",
										inputName: "pj",
										model: "pj",
										styleClasses:["fields-label","field-secondary","mt"],
										label: "Protection juridique (PJ)",
										hint: "<div  class='field-hint-text'><p>Une garantie complémentaire à la Responsabilité Civile Professionnelle, permettant de bénéficier des avantages d’une assistance juridique pour exercer en toute sécurité votre activité professionnelle.</p></div>",
										visible(){ return !self.membership || !self.membership.pj.enabled }
									},
									{
										type: "calendar-custom",
										model: "date",
										inputName: "date",
										label: "Date d'effet souhaitée",
										styleClasses: ["fields-label","field--bottom"],
										min:minDate,
										default:new Date(minDate),
										validator: [VueFormGenerator.validators.string,
											(value, field, model) => {
												let dateCompate = new Date(minDate);
												dateCompate.setHours(0,0,0,0)
												let dateValue = new Date(value)
												dateValue.setHours(0,0,0,0)
												if(dateValue < dateCompate){
													self.dateValid = false;
													return ["La date selectionée doit être supérieure ou égale au "+minDate];
												} else {
													self.dateValid = true
													return [];
												}

											}
										],
									}
								]
							}
						]

					},
					declaration:{
						fields:[
							{
								type: "checkbox-custom",
								inputName: "declaration",
								required: true,
								styleClasses: "field-declaration",
								label: "Je certifie sur l'honneur télécharger et enregistrer sur un support durable le devis (expression des besoins) et la notice d'information"
							}
						]
					}
				}
			}
		},
		computed:{
			membership(){ return this.$store.getters.membership() }
		},
		methods:{
			subscribe(){
				if( this.subStep === 1 ){

					this.addContract();
				}
				else if( this.subStep === 2 ){

					document.location.href = this.quote.quote;
					this.nextSubStep();
				}
				else if( this.subStep === 3 ){

					this.getPaymentForm()
				}
			},
			getQuote(){

				this.loading = true;

				return new Promise((resolve, reject) => {

					contactRepository.quote().then(quote => {
						this.quote = quote;
						this.loading = false;
						resolve(quote)
					},response => {
						this.loading = false;
            this.$emit('updateSubStep', 1);
						reject();
					});
				});
			},
			getPaymentForm(){

				this.loading = true;

				contactRepository.payment().then(form => {

					this.$refs.formPayment.innerHTML = form;
					document.getElementById('PaymentRequest').submit();

				},response => {
					this.loading = false;
				});
			},
			addContract(){

				this.loading = true;
				let self = this;

				let waitTimer = setTimeout(()=>{ self.wait = true }, 3000);

				contactRepository.addContract(this.insurance).then(response=>{

					clearTimeout(waitTimer);
					self.wait = false
					self.quoting = true

					this.getQuote().then(quote=>{

						self.quoting = false;
						this.nextSubStep();
					})
				}, response => {

					clearTimeout(waitTimer);
					self.wait = false
					this.loading = false;
				});
			},
			getPaymentStatus(){

				this.loading = true;

				contactRepository.getPaymentStatus().then(status => {
					this.nextStep()
				},response => {
					this.loading = false;
					this.getQuote();
					this.$emit('updateSubStep', 3);
				});
			},
			prevSubStep(){
				this.$emit('updateSubStep', this.subStep - 1);
			},
			nextSubStep(){
				this.$emit('updateSubStep', this.subStep + 1);
			},
			nextStep(){
				this.$emit('nextStep')
			}
		},
		mounted(){

			if( this.subStep > 1 && this.subStep <= 3 )
				this.getQuote();
			else if( this.subStep > 3)
				this.getPaymentStatus();
		}
	}
</script>