<template>
	<div class="a-error">
		<div class="secondary"><b>Une erreur est survenue.</b></div>

		<div v-if="data.body && data.body.status_text">{{data.body.status_text}}</div>
		<div v-if="data.status_text">{{data.status_text}}</div>
		<div v-else-if="data.detail">{{data.detail}}</div>
		<div v-else v-html="data"></div>

		<ul class="list" v-if="data.exception && typeof data.exception == 'object'">
			<li v-for="(error, index) in data.exception" :key="index">
				<b class="primary">{{ index }} : </b>
				<span v-if="error instanceof Array">
					{{ error.join(', ') }}
				</span>
				<span v-for="(items, i) in error" :key="i" v-else-if="typeof error === 'object'">
					<span v-for="(item, key) in items" :key="key" v-if="typeof items === 'object'">
						<b>{{ key }}</b>, {{ item instanceof Array ? item.join(', ') : item }}
					</span>
					<span v-else>
						{{ items }}
					</span>
				</span>
				<span v-else>
					{{ error }}
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: "error",
		props:['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.a-error{
		* + *{ margin-top: $space }
		.list{
			text-align: left;
			li:before{ content:'-'; color: $c-primary; margin-right: $space-xs }
			.primary{ margin-right: 5px }
			b{ text-transform: capitalize }
			& > * + *{ margin-top: $space-s }
		}
	}
</style>