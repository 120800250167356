<template>
	<div class="o-tabs" :class="mod ? 'o-tabs--' + mod : ''">
		<div class="o-tabs__shadow">
			<ul class="o-tabs__tabs" :class="width === 'full' ? 'container': ''">
				<li v-for="tab in tabs" class="o-tabs__tab" :class="{ 'o-tabs__tab--active': tab.isActive }" @click.prevent="selectTab(tab)">
					<div class="o-tabs__item" :data-icon="tab.icon">
						{{ tab.name }}
						<span v-if="tab.unread" class="notifications">{{ tab.unread }}</span>
					</div>
				</li>
			</ul>
		</div>


		<div class="o-tabs__details">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "tabs",
		props:['mod','width'],
		data(){
			return {
				tabs: []
			}
		},
		created() {
			this.tabs = this.$children;
		},
		methods: {
			selectTab(selectedTab) {
				this.$emit('changeTab',selectedTab.id)
				this.tabs.forEach(tab => {
					tab.isActive = (tab.id === selectedTab.id);
				});
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-tabs{
		$c: &;

		z-index: 1; position: relative;

		&__tabs{
			color: $c-primary; margin: $space-s 0 $space;

			@media #{$to-tablet}{ margin: 0 0 $space-m }
			@media #{$from-small}{ display: flex }
		}
		&__tab{
			line-height: 1.35; position: relative; cursor: pointer; padding: $space-s $half-space $space-s $space-s; background: $c-background; transition: allow(background color);
			border-radius: $border-radius; display: block;

			.accessible-version &{ border: solid 1px $c-border }

			&--active,&:hover{
				background: $c-primary; color: white;
			}
			@media #{$from-small}{
				& + &{ margin-left: 0.5rem }
			}

			@media #{$to-tablet}{
				& + &{ margin-top: $space-s }
			}

			.notifications{ top: -.4rem; right: -.4rem }
		}

		&__item{
			display: flex; align-items: center;
			&:before{ display: block; margin-right: 0.3rem }
		}

		&--light{
			#{$c}__shadow{ box-shadow: $box-shadow }
			#{$c}__tabs{ background: $c-background-lighter; margin: 0 auto }
			#{$c}__tab{
				padding: $space-m 0; background: none; color: $c-primary;
				&:after{ content: ''; position: absolute; bottom: 0; left: 0; width: 0; height: 3px; background: $c-primary; transition: allow(width) }
				& > *{ opacity: 0.5; transition: allow(opacity) }
				&--active{
					font-weight: bold;
					&:after{ width: 100% }
					& > *{ opacity: 1 }
				}
				& + #{$c}__tab{ margin-left: $space }
			}
		}
	}

</style>