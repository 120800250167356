<template>
	<div class="o-trainings-reporting container">

		<h2 class="title" v-if="interface.title">{{ interface.title }}</h2>

		<div class="o-user-contacts__filter" v-if="hasRole(user,'company')">
			<form @submit.prevent="searchContact" id="contacts_search">
				<button type="submit" class="button--search"></button>
				<input type="text" placeholder="Rechercher par nom/prénom" name="search" v-model="search" @keyup="searchContact" />
			</form>
		</div>

		<div class="o-trainings-reporting__grid">

			<div class="center" v-if="loading"><loader static="1"/></div>

			<div v-for="(report, index) in items" :key="report.contact.id" class="o-trainings-reporting__member" v-else>

				<div class="o-trainings-reporting__heading" v-if="hasRole(user, 'company')">
					<span class="title-big">{{ report.contact.firstname }} {{report.contact.lastname}}</span><br/>
					<span v-if="report.contact.positions">{{ report.contact.positions.join(' - ') }}</span>
				</div>

				<div class="o-trainings-reporting__block">
					<h3 class="title title--small" v-if="hasRole(user, 'company')">Rapport</h3>
					<report-progress :data="report"></report-progress>

					<div class="o-trainings-reporting__heading">
						<h3 class="title title--small"  v-if="report.list.length">Dernières attestations</h3>
						<a class="link" @click="showAll(report.contact.id,report.contact.firstname + '  ' +report.contact.lastname)" v-if="report.list.length">Tout afficher</a>
					</div>

					<div class="o-trainings-reporting__history"  v-if="report.list.length">
						<div v-for="(training, index) in report.list" :key="training.id" class="o-trainings-reporting__item">
							<div class="o-trainings-reporting__infos">
								<div class="primary" v-if="training.duration">
									<b>{{training.duration.hours}}h</b>
								</div>
								<div class="primary" v-else-if="training.formation.duration">
									<b>{{training.formation.duration.hours}}h</b>
								</div>
								<div>
									<div class="o-trainings-reporting__date">
										<span v-html="formatDate(training.startAt)" v-if="training.format !== 'e-learning'"></span>
										<span v-else>En ligne</span>
									</div>
									<b class="primary o-trainings-reporting__title" v-if="training.formation">{{training.formation.title}}</b>
									<b class="primary o-trainings-reporting__title" v-else-if="training.title">{{training.title}}</b>
								</div>
							</div>
							<div class="o-trainings-reporting__appendix" v-if="training.appendix">
								<div class="o-trainings-reporting__delivered">Délivrée le {{formatDigitDate(training.appendix.createdAt)}}</div>
                <form @submit.prevent="downloadAsset(training.appendix)" id="formation-report_download-attestation">
                  <submit class="button--xsmall" text="Télécharger" :loading="download===training.appendix.id"></submit>
                </form>
							</div>
						</div>

					</div>
				</div>

				<div class="o-trainings-reporting__block">
					<p v-if="!report.list.length" class="o-trainings-reporting__none">Aucune formation effectuée</p>
					<div class="bottom-right-inline o-trainings-reporting__actions">
						<button class="button button--small" @click="openPopin(report.contact.id)">Renseigner une formation</button>
						<form @submit.prevent="downloadAll(report.contact.id)" v-if="report.list.length > 1" class="inline" id="formation-report_download-all">
							<submit class="button button--border button--small" text="Télécharger les attestations" :loading="download==='all_'+report.contact.id"></submit>
						</form>
						<router-link to="/formations" class="button button--border button--small" v-else>Trouver une formation</router-link>
					</div>
				</div>
			</div>
			<div class="o-trainings-reporting__member" v-if="error">
				<div class="o-trainings-reporting__block error">
					<p>{{ error }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import formationCourseRepository from '@/repositories/formationCourseRepository';
	import externalFormationRepository from '@/repositories/externalFormationRepository';
	import appendixRepository from "@/repositories/appendixRepository";

	export default {
		name: "trainings-reporting",
		props:['data','interface'],
		data(){
			return{
				count:-1,
				items:[],
				loading: true,
				download: false,
				error: false,
				search: '',
				initial: false
			}
		},
		methods:{
			searchContact(){
				this.loading = true;
				if(this.search === '') {
					this.items = this.initial
				} else {
					let keywords = this.search.toLowerCase().split(' ');
					this.items = this.items.filter(item => {
						let keywordResult = keywords.filter(keyword => {
							return item.contact.firstname.toLowerCase().indexOf(keyword) !== -1 || item.contact.lastname.toLowerCase().indexOf(keyword) !== -1
						})
						return keywordResult.length > 0
					});
				}
				this.count = this.items.length
				this.loading= false
			},
			openPopin(id){
				this.$popin('training-external',id)
			},
			showAll(id,title){
				this.$popin('contact-reports',{id,title})
			},
			downloadAsset(appendix) {

			  this.download = appendix.id;

				if( appendix.entity === 'external-formation' )
          externalFormationRepository.download(appendix.id).then(response=>{ this.download = false }, response=>{ this.download = false });
				else
					appendixRepository.download(appendix.id).then(response=>{ this.download = false }, response=>{ this.download = false });
			},
			downloadAll(contact_id) {
        this.download = 'all_'+contact_id;
        formationCourseRepository.downloadAll(contact_id).then(response=>{ this.download = false }, response=>{ this.download = false });
			},
			getReport() {
        formationCourseRepository.getReport().then(response=>{
					this.loading = false;
					this.count = response.count;
					this.items = response.items;
					this.initial = response.items;

				}, response=>{
					this.loading = false;
					this.error = response.body.status_text;
				})
			}
		},
		computed:{
			user(){ return this.$user() }
		},
		mounted(){

			this.$update(this.getReport)
			this.getReport();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-trainings-reporting{
		text-align: left;

		& > .title{
			margin: $space $space $space-m;

			@media #{$to-tablet}{ margin-left: 0; margin-right: 0 }
		}

		&__title{
			font-size: $font-s;
		}

		&__delivered{ opacity: 0; transition: allow(opacity) }

		&__block{
			> .title--small{ margin-bottom: $space-s }
		}

		&__grid{
			padding: 0!important; background: none!important;
			@media #{$from-small}{ display: flex; flex-wrap: wrap }
		}

		&__member{
			background: white; border-radius: $border-radius; padding: $space; box-shadow: $subtle-box-shadow;

			@media #{$from-small}{
				width: calc(50% - #{$half-space});
				&:nth-of-type(even){ margin-left: $space }
				&:nth-of-type(1n+3){ margin-top: $space }
			}

			@media #{$to-tablet}{
				padding: $space-m;
				& + *{ margin-top: $space-m }
			}

			& > .title + *{ margin-top: $space-s }
			& > * + .title{ margin-top: $space }

			.user--collaborator &, .user--commercial_agent &{ width: 100% }
		}

		&__history{
			margin-top: $space-s;
			@media #{$from-tablet}{ background: $c-background; border-radius: $border-radius; padding: $space-m }
		}

		@media #{$to-tablet}{
			&__item{ background: $c-background; border-radius: $border-radius; padding: $space-m }
		}

		&__item{ display: flex }

		&__item + &__item{ margin-top: $space-m }

		&__infos{
			display: flex; align-items: center;
			& > * + *{ margin-left: $space-m }
		}

		&__appendix{
			margin-top: $space-s; display: flex; align-items: center; justify-content: flex-end; font-size:$font-xs; margin-left: auto; white-space: nowrap;
			form{ margin-left: $space-s }
			.button{ text-transform: none }
			&:hover .o-trainings-reporting__delivered{ opacity: 1 }
			@media #{$to-tablet}{
				flex-direction: column-reverse; align-items: flex-start;
				form{ margin-bottom: $space-s; margin-left: 0 }
			}
		}

		&__date{ font-size: $font-xs }

		&__heading{
			color: $c-primary; margin-bottom: $space-s;
			@media #{$from-tablet}{ display: flex; align-items: center }
			span{ margin-right: $space-s }
			.link{ font-weight: normal; margin-left: 0.5rem; font-size: $font-xs }
		}

		&__actions{
			margin-top:$space-m;

			@media #{$to-phone}{
				form{ margin-left: 0 }
				.button{ margin-top: $space-s; margin-left: 0; display: block; text-align: center; width: 100% }
			}
		}

		&__none{ margin-bottom:$space-l }
	}
</style>