<template>
	<div class="o-cta">
		<div class="o-cta__columns container" :class="'o-cta__columns--'+data.mod">
			<div v-for="(column,index) in data.columns">
				<h3 class="title-big" v-if="column.title">{{column.title}}</h3>
				<div v-html="column.text"></div>
				<div class="o-cta__buttons">
					<div v-for="(button, index) in column.buttons" :key="index">
						<router-link :to="button.page" v-if="button.type === 'edito'"  class="button">{{ button.title}}</router-link>
						<router-link :to="getPage(button.url)" v-else-if="button.type === 'internal'"  class="button">{{ button.title}}</router-link>
						<a class="button" :href="button.url" target="_blank" v-else>{{button.title}}</a>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: "cta",
		props:['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-cta{
		margin: $space-xl 0;
		&__columns{
			display: flex; color: $c-primary;

			&--left{ text-align: left }

			&--center{ justify-content: center}

			& > *{
				flex: 1; max-width: 75%;
				& + *{ margin-left: $space }
			}
		}

		&__buttons{
			margin-top: $space;

			& > *{
				display: inline-block;
				& + *{ margin-left: $space-m }
			}
		}
	}

</style>